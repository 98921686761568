import React, { useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import { useDataView } from "../Providers/DataViewProvider";
import PropertySettings from "./Options";
import { PROPERTY_TYPES } from "./Options/Constants";
import useTableStyles from "./TableStyles";
import {
  GridSettingsIcon,
  GridAddSectionIcon,
} from "../../../common/iconslist";
import { useEditorContext } from "../../../hooks/useMouseMove";
import Icon from "../../../common/Icon";

const SortIcon = (props) => {
  const { sortBy } = props;
  return sortBy ? (
    sortBy === "asc" ? (
      <Box sx={{ '& svg': { '& path': { stroke: "rgba(37, 99, 235, 1) !important" } } }} ><Icon icon={'chervUp'} /></Box>
    ) : (
      <Box sx={{ '& svg': { '& path': { stroke: "rgba(37, 99, 235, 1) !important" } } }} ><Icon icon={'chervDown'} /></Box>
    )
  ) : null;
};

const TableView = (props) => {
  const { theme: appTheme } = useEditorContext();
  const { children, readOnly } = props;
  const theme = useTheme();
  const classes = useTableStyles(theme, appTheme);
  const {
    properties,
    onAddProperty,
    onUpdateProperty,
    onUpdateSort,
    sort,
    onAddRow,
  } = useDataView();
  const [sortBy] = sort || [];
  const [anchorEl, setAnchorEl] = useState(null);
  const [mode, setMode] = useState({
    mode: null,
    edit: null,
  });
  const open = Boolean(anchorEl);
  const shownProperties = properties?.filter((f) => f.visible);

  const onAddClick = (e) => {
    setMode({
      type: "addProperty",
    });
    setAnchorEl(e.currentTarget);
  };

  const onEvent = (type, data, navToEdit = true) => {
    switch (type) {
      case "addProperty":
        const np = onAddProperty({ type: data?.type }, data?.overrides || {});
        if (navToEdit) {
          setMode({
            type: "editProperty",
            edit: { ...np },
          });
        }
        break;
      case "editProperty":
        setMode({
          type: "editProperty",
          edit: { ...data?.edit },
        });
        break;
      case "changeProperty":
        setMode({
          type: "changeProperty",
          edit: { ...(data?.edit || {}) },
        });
        break;
      case "updateProperty":
        const up = onUpdateProperty(data);
        if (navToEdit) {
          setMode({
            type: "editProperty",
            edit: { ...(up || {}) },
          });
        }
        break;
      case "deleteProperty":
        onUpdateProperty(data, true);
        break;
      case "allProperties":
        setMode({
          type: "allProperties",
          edit: null,
        });
        break;
      case "editOption":
        setMode({
          type: "editOption",
          edit: { ...(data?.edit || {}) },
        });
        break;
      case "addSort":
        onUpdateSort(data);
        break;
      case "close":
        onClose();
        break;
      default:
        return;
    }
  };

  const onEditProperty = (data) => (e) => {
    if (!readOnly) {
      setAnchorEl(e?.currentTarget);
      onEvent("editProperty", { edit: { ...data } });
    } else {
      const currentSort = sortBy?.key === data?.key ? sortBy : null;
      onUpdateSort(
        {
          ...(sortBy || data),
          newKey: data.key,
          operator: currentSort?.operator === "asc" ? "desc" : "asc",
        },
        false,
        false
      );
    }
  };

  const onSettings = (e) => {
    setAnchorEl(e?.currentTarget);
    onEvent("allProperties", {});
  };

  const onClose = () => {
    setMode({});
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        component={"div"}
        className={`tv-d-wrapper ${readOnly ? "readOnly" : ""}`}
        sx={classes.root}
        contentEditable={false}
      >
        <Box component={"table"} sx={classes.table}>
          <thead>
            <tr>
              {shownProperties?.map((m, i) => {
                const { Icon: iconType } =
                  PROPERTY_TYPES?.find((f) => f.type === m.type) || {};
                const isSort = sortBy?.key === m.key ? sortBy?.operator : null;
                return (
                  <th key={i} style={{ minWidth: "200px" }}>
                    <Button
                      className="tv-act-btn la"
                      startIcon={<Icon icon={iconType} />}
                      endIcon={<SortIcon sortBy={isSort} />}
                      fullWidth
                      onClick={onEditProperty(m)}
                    >
                      {m.label}
                    </Button>
                  </th>
                );
              })}
              {!readOnly ? (
                <>
                  <th className="tv-act-btn ico">
                    <Button onClick={onAddClick} fullWidth>
                      <GridAddSectionIcon />
                    </Button>
                  </th>
                  <th className="tv-act-btn ico">
                    <Button onClick={onSettings} fullWidth>
                      <GridSettingsIcon />
                    </Button>
                  </th>
                </>
              ) : null}
            </tr>
          </thead>
          {children}
        </Box>
      </Box>
      {!readOnly ? (
        <Button
          contentEditable={false}
          className="tv-act-btn la"
          fullWidth
          onClick={onAddRow}
          sx={{ textTransform: "none", justifyContent: "start", color: appTheme?.palette?.editor?.activeColor, '& svg': { width: '18px', height: '18px', strokeWidth: 1.2, '& path': { stroke: appTheme?.palette?.editor?.activeColor }}, fontFamily:'inter' }}
          startIcon={<Icon icon={'addRounded'} />}
        >
          Add new row
        </Button>
      ) : null}
      {open && !readOnly ? (
        <PropertySettings
          open={open}
          anchorEl={anchorEl}
          mode={mode}
          properties={properties}
          onClose={onClose}
          onEvent={onEvent}
        />
      ) : null}
    </>
  );
};

export default TableView;
