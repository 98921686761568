import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { activeMark, addMarkData } from "../../utils/SlateUtilityFunctions.js";
import usePopupStyle from "../PopupTool/PopupToolStyle.js";
import { useEditorContext } from "../../hooks/useMouseMove.js";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

const FontFamilyAutocomplete = ({ editor, format, options, width, onChange = () => { }, val = "", webFont = false}) => {
    const markValue = activeMark(editor, format);
    const value = !webFont ? markValue : val
    const changeMarkData = (event, newValue, format) => {
        if (!webFont){
            event.preventDefault();
            const value = newValue;
            addMarkData(editor, { format, value });
        } else {
            onChange({ 
                [format]: newValue 
                }
            )
        }
    };
    const { theme } = useEditorContext();
    const classes = usePopupStyle(theme);

    return (
        <Autocomplete
            size="small"
            style={{
                fontFamily: `${value || ""}`,
                width: width || "100%",
            }}
            value={value || options?.[0]}
            className="editor-dd"
            sx={{...classes.autoCompleteaFontFamily}}
            onChange={(e, newValue) => changeMarkData(e, newValue, format)}
            getOptionLabel={(option) => {
                return option}
            }
            options={options}
            defaultValue={options?.[0]}
            renderOption={(props, option) => {
                return(
                <li {...props} style={{ fontFamily: option}}>
                    {option}
                </li>
            )}}
            slotProps={{
                popper: {
                    sx: {
                        ...classes.fontFamilyListOptions
                    },
                },
            }}
            popupIcon={<KeyboardArrowDownRoundedIcon />}
            renderInput={(params) => <TextField sx={{ "& .MuiInputBase-root.MuiOutlinedInput-root":{fontFamily: `${value}`} }} {...params} />}
            // componentsProps={{
            //     clearIndicator: {
            //       style: { display: 'none' },
            //     },
            //   }}
            disableClearable
        />
    );
};

export default FontFamilyAutocomplete;



