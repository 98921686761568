function sortByDate(a, b) {
  const dateA = new Date(a);
  const dateB = new Date(b);

  // Check if both dates are valid
  const isValidA = !isNaN(dateA.getTime());
  const isValidB = !isNaN(dateB.getTime());

  if (isValidA && isValidB) {
    // Compare valid dates
    return dateA - dateB;
  } else if (isValidA) {
    // Invalid date in `b` pushes `a` before `b`
    return -1;
  } else if (isValidB) {
    // Invalid date in `a` pushes `b` before `a`
    return 1;
  } else {
    // Both are invalid, maintain their relative order
    return 0;
  }
}

/**
 * Multi-sort rows based on multiple criteria.
 * @param {Array} rows - Array of row objects.
 * @param {Array} criteria - Array of sort criteria [{key, sortBy}].
 * @param {Object} columnConfig - Column configurations with data types.
 */
const multiSortRows = (rows, criteria, columnConfig) => {
  return rows.slice().sort((a, b) => {
    for (let { key, operator } of criteria) {
      const column = columnConfig?.find((col) => col.key === key);
      if (!column) continue;

      const dataType = column.type;
      const valueA = a[key];
      const valueB = b[key];
      let comparison = 0;

      switch (dataType) {
        case "text":
          comparison = valueA?.localeCompare(valueB, undefined, {
            sensitivity: "base",
          });
          break;

        case "number":
          comparison = parseInt(valueA || 0) - parseInt(valueB || 0);
          break;

        case "select":
        case "multi-select":
        case "person":
          if (Array?.isArray(valueA) && Array?.isArray(valueB)) {
            comparison = Array?.isArray(valueA)
              ? valueA
                  ?.map((m) => m?.value)
                  ?.join(", ")
                  ?.localeCompare(
                    valueB?.map((m) => m?.value).join(", "),
                    undefined,
                    {
                      sensitivity: "base",
                    }
                  )
              : 0;
          }
          break;

        case "date":
          comparison = sortByDate(valueA, valueB);
          break;

        case "check":
          if (operator === "desc"){
            if (valueA) {
              return 1
            } else {
              return -1
            }
          } else {
            if (valueA) {
              return -1
            } else {
              return 1
            }
          }
          // return valueA && operator === "desc" ? 1 : valueB && operator === "asc" ;
        default:
          break;
      }

      if (comparison !== 0) {
        return operator === "asc" ? comparison : -comparison;
      }
    }

    return 0; // Rows are equal based on all criteria
  });
};

export default multiSortRows;
