const useCommonStyle = (theme) => ({
  sideBarDrawer: {
    "& .MuiPaper-root": {
      backgroundColor: theme?.palette?.editor?.textFormatBgColor,
      border: `1px solid ${theme?.palette?.editor?.miniToolBarBorder}`,
      "@media only screen and (min-width: 899px)": {
        borderRadius: "20px",
        margin: "5px",
        height: "calc(100% - 10px)",
        overflow: "hidden",
      },
      "@media only screen and (max-width: 599px)": {
        borderRadius: "20px 20px 0px 0px",
      },
    },
    "& .optionePopupHeader": {
      borderBottom: `1px solid ${theme?.palette?.editor?.deviderBgColor} !important`,
      padding: "0px 0px 10px 0px",
      margin: "0px 10px",
      "& .MuiTypography-root": {
        textTransform: "capitalize",
      },
      "& .close-popupbtn": {
        background: theme?.palette?.editor?.closeButtonBgColor,
        color: `${theme?.palette?.editor?.closeButtonSvgStroke} !important`,
        "& svg": {
          color: `${theme?.palette?.editor?.closeButtonSvgStroke} !important`,
        },
      },
    },
    "& .MuiDialogActions-root": {
      // paddingTop: "10px",
      "& .primaryBtn, .deleteBtn": {
        height: "32px !important",
        fontSize: "14px !important",
        fontWeight: "600 !important",
        padding: "0px 20px !important",
      },
      "& .deleteBtn": {
        background: `${
          theme?.palette?.type === "light" && "#FFF7F9"
        } !important`,
      },
    },
    "& .MuiDialogContent-root": {
      padding: "0px 5px 0px 10px!important",
      height: "100%",
      fontFamily: "Inter, sans-serif",
      "& .MuiTypography-root": {
        fontSize: "14px",
        fontWeight: "500",
        fontFamily: "Inter, sans-serif",
      },
      "& p": {
        marginBottom: "7px",
        marginTop: "4px",
      },
      "& .MuiPaper-root":{
        border: 'unset !important',
        borderRadius: '0px',
        height: 'fit-content',
        padding: '2px'
      },
      "& .muiIconsListParent": {
        "& svg": {
          color: `${theme?.palette?.editor?.svgTextAlignStrokeColor} !important`,
        },
        "&::-webkit-scrollbar-thumb": {
          background: `none !important`,
        },
        "&::-webkit-scrollbar-track": {
          visibility: "hidden",
        },
        "&::-webkit-scrollbar-thumb": {
          background: `${theme?.palette?.editor?.brainPopupScroll} !important`,
        },
        "&::-webkit-scrollbar-track": {
          visibility: "hidden",
        },
      },
      "& .MuiGrid-root>.MuiGrid-item": {
        paddingRight: "0px !important",
        fontFamily: "Inter, sans-serif",
        height: 'fit-content',
      },
      "& .MuiInputBase-root": {
        borderRadius: "10px",
        background: theme?.palette?.editor?.inputFieldBgColor,
        border: `none`,
        height: "36px",
        boxShadow: "0px 4px 16px 0px #0000000D",
        fontFamily: "Inter, sans-serif",
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme?.palette?.editor?.inputFieldBorder}`,
        },
        "& input::placeholder": {
          color: "#94A3B8 !important",
          opacity: "unset",
          fontFamily: "Inter, sans-serif",
        },
        "& .colorPickerButton": {
          border: `2px solid ${theme?.palette?.editor?.buttonBorder2} !important`,
        },
      },
      "& .MuiInputBase-root:has(.colorPickerButton)": {
        "& .MuiInputBase-input": {
          padding: "8.5px 14px 8.5px 0px",
          fontFamily: "Inter, sans-serif",
        },
      },
      "& .MuiInputBase-input": {
        color: theme?.palette?.editor?.deletePopUpButtonTextColor,
        textTransform: "math-auto",
        fontFamily: "Inter, sans-serif",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme?.palette?.editor?.inputFieldBorder}`,
      },
      "& fieldset": {
        border: `1px solid transparent`,
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb, & .MuiPaper-root ::-webkit-scrollbar-thumb":
        {
          background: `none !important`,
        },
      "&::-webkit-scrollbar-track, & .MuiPaper-root ::-webkit-scrollbar-track":
        {
          visibility: "hidden",
        },
      "&:hover": {
        "&::-webkit-scrollbar-thumb, & .MuiPaper-root ::-webkit-scrollbar-thumb":
          {
            background: `${theme?.palette?.editor?.brainPopupScroll} !important`,
          },
        "&::-webkit-scrollbar-track, & .MuiPaper-root ::-webkit-scrollbar-track":
          {
            visibility: "hidden",
          },
      },
      "& .sld-wrpr": {
        paddingLeft: "5px",
        "& .sliderInput": {
          background: theme?.palette?.editor?.inputFieldBgColor,
          border: `1px solid ${theme?.palette?.editor?.inputFieldBorder}`,
          borderRadius: "10px",
          width: "46px !important",
          marginLeft: "10px",
          height: '36px !important'
        },
      },
      '& .MuiFormHelperText-root':{
        margin: '4px 0px 0px 0px',
        color: theme?.palette?.editor?.closeButtonSvgStroke,
        fontFamily: "Inter, sans-serif",
      }
    },

    "& .MuiTypography-root": {
      color: theme?.palette?.editor?.textColor,
    },
    "& .MuiInputBase-input": {
      color: theme?.palette?.editor?.textColor,
    },
    "& .MuiCheckbox-root": {
      color: theme?.palette?.editor?.textColor,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme?.palette?.editor?.borderColor}`,
    },
    "& .close-popupbtn": {
      color: theme?.palette?.editor?.textColor,
    },
    "& .MuiSvgIcon-root": {
      color: theme?.palette?.editor?.textColor,
    },
  },
  sapcingInput: {
    backgroundColor: theme?.palette?.editor?.background,
    color: theme?.palette?.editor?.textColor,
  },
  customCheckBox: {
    padding: "0px",
    "& .MuiCheckbox-root": {
      padding: "8px 8px 8px 10px",
      "&:hover": {
        background: "unset !important",
      },
    },
    "& button": {
      width: "18px !important",
      height: "18px !important",
      borderRadius: "3px",
      border: `1px solid ${theme?.palette?.editor?.buttonBorder3}`,
      "& svg": {
        width: "14px",
        height: "14px",
      },
    },
    "& .checkedIcon": {
      background: "#2563EB",
      borderColor: "#2563EB",
      padding: "0px",
    },
    "& .unCheckedIcon": {
      background: theme?.palette?.editor?.checkedIconBg,
    },
    "& p": {
      margin: "0px !important",
    },
  },
  customPaddingInput: {
    background: theme?.palette?.editor?.inputFieldBgColor,
    border: `1px solid ${theme?.palette?.editor?.inputFieldBorder}`,
    color: theme?.palette?.editor?.textColor,
    borderRadius: "6px",
  },
  colorPickerPopup: {
    "& .MuiPaper-root": {
      overflow: "auto",
      backgroundColor: theme?.palette?.editor?.miniToolBarBackground,
    },
    "& .popup_tabs": {
      backgroundColor: theme?.palette?.editor?.background,
      "@media only screen and (max-width: 899px)": {
        width: "100% !important",
      },
    },
    "& .popup_tabs-header": {
      backgroundColor: theme?.palette?.editor?.background,
      "& .popup_tabs-header-label-active": {
        color: theme?.palette?.editor?.activeColor,
        backgroundColor: theme?.palette?.editor?.background,
      },
    },
    "& .colorpicker": {
      backgroundColor: theme?.palette?.editor?.miniToolBarBackground,
      "& .default-color-panel": {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, 20px)",
        gridGap: "3px",
        justifyContent: "space-between",
        margin: "14px -16px 0px -16px",
        padding: "2px 16px 0px 16px",
        overflow: "auto",
        maxHeight: "105px",
        "& .default-color-panel_item": {
          height: "20px",
          width: "20px",
          border: "1px solid #E2E8F0",
        },
      },
    },
    "& .color-picker-panel": {
      backgroundColor: theme?.palette?.editor?.miniToolBarBackground,
    },
    "& .input_rgba": {
      "& input": {
        backgroundColor: theme?.palette?.editor?.miniToolBarBackground,
        color: theme?.palette?.editor?.textColor,
      },
    },
  },
  colorPickerBtnBorder: {
    border: `2px solid ${theme?.palette?.editor?.buttonBorder2} !important`
  },
  signaturePopup: {
    "& .MuiPaper-root": {
      backgroundColor: theme?.palette?.editor?.miniToolBarBackground,
      "& .signature-canvas": {
        backgroundColor: `${theme?.palette?.editor?.inputFieldBgColor} !important`,
      },
      "& label": {
        color: theme?.palette?.editor?.textColor,
      },
      "& input": {
        color: theme?.palette?.editor?.textColor,
        backgroundColor: theme?.palette?.editor?.background,
      },
      "& .MuiInputBase-root": {
        backgroundColor: theme?.palette?.editor?.background,
      },
      "& .brush-color": {
        color: theme?.palette?.editor?.textColor,
      },
      "& .react-datepicker__input-container": {
        "& svg": {
          fill: "transparent",
        },

        "& input": {
          height: "27px !important",
        },
      },
      "& fieldset": {
        borderColor: theme?.palette?.editor?.textColor,
      },
      "& .upload-wrapper-ui": {
        backgroundColor:
          theme?.palette?.type === "dark" ? "#2563EB0F" : "#F8F9FF",

        "& .MuiTypography-root": {
          color: theme?.palette?.editor?.textColor,
        },
      },
      "& .typesignature-input-wrapper": {
        "& .MuiInputBase-root": {
          background: `${theme?.palette?.editor?.inputFieldBgColor} !important`,
          borderRadius: "7px !important",
          paddingRight: "6px !important",
        },
        "& .MuiInputBase-input": {
          background: `${theme?.palette?.editor?.inputFieldBgColor} !important`,
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme?.palette?.editor?.signaturePopUpInputFieldBorder}`,
        },
        "& .Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            border: `1px solid #2563EB !important`,
          },
        },
        "& button": {
          background: `${theme?.palette?.editor?.closeButtonBgColor} !important`,
          color: `${theme?.palette?.editor?.closeButtonSvgStroke} !important`,
        },
      },
      "& .typesignature-fontfamily": {
        "& button": {
          color: theme?.palette?.editor?.textColor,
          background: `${theme?.palette?.editor?.signatureFontBtnBg} !important`,
          border: `1px solid ${theme?.palette?.editor?.sectionSettingIconHover} !important`,
          "& p": {
            fontSize: "13px !important",
          },
        },
        "& .active ": {
          border: `1px solid #2563EB !important`,
        },
      },
      "& .MuiTab-root": {
        color: `${theme?.palette?.editor?.closeButtonSvgStroke} !important`,
        backgroundColor: `${theme?.palette?.editor?.inputFieldBgColor} !important`,
        opacity: "unset",
        fontSize: "12px !important",
        fontWeight: "500 !important",
        border: `1px solid ${theme?.palette?.editor?.signaturePopUpTabButtonBorder} !important`,
      },
      "& .Mui-selected": {
        background: `${theme?.palette?.editor?.signaturePopUpTabButtonSelectedBg} !important`,
        color: `${theme?.palette?.editor?.textColor} !important`,
        border: `1px solid ${theme?.palette?.editor?.signaturePopUpTabButtonSelectedBorder} !important`,
        "& .MuiGrid-root": {
          "& svg": {
            "& path": {
              fill: `${theme?.palette?.editor?.signaturePopUpTabButtonSelectedSvg} !important`,
            },
          },
        },
      },
      "& .close-popupbtn": {
        color: theme?.palette?.editor?.closeButtonSvgStroke,
        background: `${theme?.palette?.editor?.closeButtonBgColor} !important`,
      },
      "& .deleteBtn": {
        background: "transparent",
        margin: "0px !important",
      },
      "& .MuiGrid-container": {
        "& .workFlowButton": {
          background: `${theme?.palette?.editor?.workFlowButtonBackground} !important`,
          textTransform: "unset !important",
          color: `${theme?.palette?.editor?.textColor} !important`,
          fontSize: "14px !important",
          fontWeight: "500 !important",
          borderRadius: "25px",
          padding: "5px 15px",
        },
      },
      "& .MuiDialogActions-root": {
        "& .deleteButtonSignature": {
          background: `${theme?.palette?.editor?.closeButtonBackground} !important`,
          color: `${theme?.palette?.editor?.deletePopUpButtonTextColor} !important`,
          border: `1px solid ${theme?.palette?.editor?.closeButtonBorder} !important`,
        },
      },
    },
    "& .disabledSaveBtn": {
      background: "#2563EB33 !important",
      color: theme?.palette?.type === "dark" && "#FFFFFF33 !important",
      border: "1px solid #2563EB33 !important",
    },
  },
  popupTitle2: {
    fontWeight: 600,
    color: theme?.palette?.editor?.textColor,
  },
  textOptions: {
    "& .MuiPopover-root": {
      backgroundColor: theme?.palette?.editor?.background,
    },
    "& svg": {
      width: "20px",
      height: "24px",
      color: `${theme?.palette?.editor?.svgTextAlignStrokeColor} !important`,
    },
  },
  textOptionsList: {
    "& .MuiMenuItem-root": {
      padding: "8px 12px",
      fontSize: "12px",
      color: theme?.palette?.editor?.menuOptionTextColor,
      fontWeight: '500',
      fontFamily: "Inter, sans-serif",
      minHeight: '36px',
      "&:hover": {
        backgroundColor: theme?.palette?.editor?.menuOptionHoverBackground,
      },
    },
    '& .Mui-selected':{
      background: `${theme?.palette?.editor?.menuOptionSelectedOption} !important`
    },
    "& .MuiListSubheader-root": {
      color: "gray",
      fontWeight: "bold",
      fontSize: "12px",
    },
    "& .MuiPaper-root":{
      borderRadius: '8px',
      padding: '0px',
      background: `${theme?.palette?.editor?.textWeightPopUpBackground} !important`,
    },
    '& .MuiButtonBase-root':{
      margin: '4px',
      borderRadius: '6px'
    },
    '& .MuiList-root':{
      padding: '0px'
    }
  },
  datePicker: {
    "& .react-datepicker-wrapper": {
      width: "100%",
      "& .react-datepicker__input-container": {
        "& .MuiInputBase-root": {
          background: `${theme?.palette?.editor?.inputFieldBgColor} !important`,
          "& .MuiInputBase-input": {
            background: `${theme?.palette?.editor?.inputFieldBgColor} !important`,
            color: `${theme?.palette?.editor?.textColor} !important`,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${theme?.palette?.editor?.signaturePopUpInputFieldBorder} !important`,
          },
        },
      },
    },
  },
  signaturePopUpNameField: {
    background: "transparent !important",
    "& .MuiInputBase-root": {
      borderRadius: "10px !important",
      background: "transparent !important",
      "& .MuiOutlinedInput-input": {
        background: `${theme?.palette?.editor?.inputFieldBgColor} !important`,
        borderRadius: "10px !important",
        border: `1px solid ${theme?.palette?.editor?.signaturePopUpInputFieldBorder} !important`,
        fontSize: "14px !important",
        fontWeight: "500 !important",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid transparent !important`,
      },
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-input": {
        border: `1px solid #2563EB !important`,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid transparent !important`,
      },
    },
  },
  pageSettingPopUpRoot: {
    padding: "16px 8px 16px 10px!important",
    height: "100%",
  },
  buttonMoreOption: {
    background: `${theme?.palette?.editor?.aiInputBackground} !important`,
    border: `1px solid ${theme?.palette?.editor?.buttonBorder1} !important`,
    padding: '4px !important',
    '& svg':{
      width: '18px !important',
      height: '18px !important',
      '& path':{
        stroke: `${theme?.palette?.editor?.closeButtonSvgStroke} !important`
      }
    }
  },
  buttonMoreOption2: {
    background: `${theme?.palette?.editor?.aiInputBackground} !important`,
    border: `1px solid ${theme?.palette?.editor?.buttonBorder1} !important`,
    '& svg':{
      '& path':{
        fill: `${theme?.palette?.editor?.closeButtonSvgStroke} !important`
      }
    }
  },
  buttonMoreOption3: {
    background: `${theme?.palette?.editor?.aiInputBackground} !important`,
    border: `1px solid ${theme?.palette?.editor?.buttonBorder1} !important`,
    padding: '5px !important',
    '& svg':{
      width: '16px !important',
      height: '16px !important',
      '& path':{
        stroke: `${theme?.palette?.editor?.closeButtonSvgStroke} !important`
      }
    }
  },
  resizeButton: {
    background: theme?.palette?.editor?.aiInputBackground,
    border: `1px solid ${theme?.palette?.editor?.buttonBorder1}`,
    padding: '5px !important',
    borderRadius: '50% !important',
    '& svg':{
      width: '18px',
      height: '18px',
      '& path':{
        fill: `${theme?.palette?.editor?.closeButtonSvgStroke}`
      }
    },
    '&:hover':{
      background: theme?.palette?.editor?.aiInputBackground,
    }
  },
  gradientFillBtn: {
    background: `linear-gradient(112.61deg, #2C63ED 19.3%, #8360FD 88.14%) !important`,
    textTransform: 'math-auto !important',
    color: '#FFFFFF !important',
    padding: '0px 12px !important',
    height: '32px',
    borderRadius: '8px',
    fontWeight: '500',
    fontSize: '14px'
  },
  emptyThumbBtn: {
    background: `${theme?.palette?.editor?.sectionSettingIconHover} !important`,
    color: `${theme?.palette?.editor?.textColor} !important`,
    fontSize: '14px !important',
    '& svg':{
      width: '20px !important',
      height: '20px !important',
      '& path':{
        stroke: `${theme?.palette?.editor?.closeButtonSvgStroke} !important`
      }
    }
  }
});

export default useCommonStyle;
