import React from "react";
import { Box, useTheme } from "@mui/material";

const useShadowElementStyles = ({ theme, style }) => ({
  root: {
    ...style,
    position: "relative",
    backgroundColor: "rgba(0,0,0,0)",
    marginLeft: `calc((100% - 980px) * 0.5)`,
    [theme?.breakpoints?.between("xs", "md")]: {
      marginLeft: `calc((100% - 320px) * 0.5)`,
    },
  },
});

const ShadowElement = (props) => {
  const theme = useTheme();
  const { type, enable, style } = props;
  const classes = useShadowElementStyles({ theme, style });

  return type === "child" && enable === 1 ? (
    <Box component={"div"} sx={classes.root} />
  ) : null;
};

export default ShadowElement;
