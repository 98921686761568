import React from "react";
import { useDataView } from "../../Providers/DataViewProvider";
import Select from "./Components/Select";

const PersonType = (props) => {
  const { rowIndex, property, value, readOnly } = props;
  const { onChange, users } = useDataView();

  const handleChange = (data) => {
    onChange(rowIndex, {
      [property]: data?.filter((f) => f?.value),
    });
  };

  return (
    <Select
      value={value || []}
      onChange={handleChange}
      options={users || []}
      multiple={false}
      disabled={readOnly}
      optionAvatar={true}
    />
  );
};

export default PersonType;
