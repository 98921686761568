import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import { updateTopBanner, removeTopBanner } from "../../utils/topBanner";
import ImageSelector from "../../common/ImageSelector/ImageSelector";
import TopBannerStyles from "./Styles";
import Icon from "../../common/Icon";
import { useEditorContext } from "../../hooks/useMouseMove";

export const TopBannerToolbar = (props) => {
  const { classes, customProps, editor } = props;
  const [open, setOpen] = useState(false);

  const handleClick = (type) => () => {
    switch (type) {
      case "change":
        setOpen(true);
        break;
      case "remove":
        removeTopBanner(editor);
        break;
      default:
        return;
    }
  };

  const onSelectImage = (img) => {
    updateTopBanner(editor, { url: img });
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box component={"div"} className="tb-toolbar" sx={classes.toolbar}>
        <IconButton onClick={handleClick("change")}><Icon icon={"editIcon"} /></IconButton>
        <IconButton onClick={handleClick("remove")}><Icon icon={"deleteIcon"} /></IconButton>
      </Box>
      <ImageSelector
        open={open}
        onClose={handleClose}
        customProps={customProps}
        onSelectImage={onSelectImage}
      />
    </>
  );
};

const TopBanner = (props) => {
  const { attributes, element, children, customProps } = props;
  const { readOnly } = customProps;
  const { url } = element;
  const { theme } = useEditorContext();
  const classes = TopBannerStyles(theme);

  const renderHeaderAboveBanner = customProps?.metadata?.renderHeader
    ? customProps.metadata.renderHeader()
    : null;
    
  return (
    <Box
      component={"div"}
      {...attributes}
      style={{ position: "relative", height: "296px", textAlign: "center" }}
      contentEditable={false}
      sx={classes.root}
    >
      {renderHeaderAboveBanner}
      <img
        src={url}
        alt={"Top Banner"}
        className="top-banner-wrpr-ele"
        style={{
          width: "100%",
          height: "280px",
          objectFit: "cover",
          borderRadius: "0px",
          margin: "0px 0px",
        }}
      />
      {!readOnly ? <TopBannerToolbar {...props} classes={classes} /> : null}
      <span
        className="hidden-children"
        style={{ display: "none", height: "0px" }}
      >
        {children}
      </span>
    </Box>
  );
};

TopBanner.defaultProps = {
  attributes: {},
  children: [],
};

export default TopBanner;
