const globalSearch = (array, searchTerm) => {
  if (!searchTerm) {
    return array;
  }
  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  return array.filter((item) =>
    Object.values(item).some((value) => {
      if (typeof value === "string" && value) {
        return value?.toLowerCase().includes(lowerCaseSearchTerm);
      } else if (Array.isArray(value)) {
        return value
          ?.map((m) => m?.value)
          .some((d) => d.toLowerCase().includes(lowerCaseSearchTerm));
      }
      return false;
    })
  );
};

export default globalSearch;
