// import FilterListIcon from "@mui/icons-material/FilterList";

export const TYPE_LABELS = {
  text: "Text",
  number: "Number",
  select: "Select",
  "multi-select": "Multi Select",
  date: "Date",
  person: "Person",
  check: "Checkbox",
};

export const TYPE_ICONS = {
  text: 'text',
  number: 'hashtagIcon',
  select: 'selectRounded',
  "multi-select": 'check-list-item',
  date: 'calenderNewIcon',
  person: 'userIcon',
  check: 'tickOutline',
}

export const PROPERTY_DEFAULTS = {
  text: {
    label: TYPE_LABELS?.text,
    visible: true,
  },
  number: {
    label: TYPE_LABELS.number,
    visible: true,
  },
  select: {
    label: TYPE_LABELS.select,
    visible: true,
  },
  "multi-select": {
    label: TYPE_LABELS["multi-select"],
    visible: true,
  },
  date: {
    label: TYPE_LABELS.date,
    visible: true,
  },
  check: {
    label: TYPE_LABELS.check,
    visible: true,
  },
  person: {
    label: TYPE_LABELS.person,
    visible: true,
  },
};

export const PROPERTY_TYPES = [
  {
    type: "text",
    label: TYPE_LABELS.text,
    Icon: 'text',
  },
  {
    type: "number",
    label: TYPE_LABELS.number,
    Icon: 'hashtagIcon',
  },
  {
    type: "select",
    label: TYPE_LABELS.select,
    Icon: 'selectRounded',
  },
  {
    type: "multi-select",
    label: TYPE_LABELS["multi-select"],
    Icon: 'check-list-item',
  },
  {
    type: "date",
    label: TYPE_LABELS.date,
    Icon: 'calenderNewIcon'
  },
  {
    type: "person",
    label: TYPE_LABELS.person,
    Icon: 'userIcon',
  },
  {
    type: "check",
    label: TYPE_LABELS.check,
    Icon: 'tickOutline',
  }
];

export const FILTER_TYPES = [
  {
    type: "sort",
    operator: "asc",
    label: "Sort Ascending",
    value: "",
    Icon: 'arrowUp',
  },
  {
    type: "sort",
    operator: "desc",
    label: "Sort Descending",
    Icon: 'arrowDown',
  },
  // {
  //   type: "filter",
  //   operator: "contains",
  //   label: "Filter",
  //   value: "",
  //   Icon: FilterListIcon,
  // },
];

export const DEFAULT_OPTION_COLORS = [
  "rgba(131, 96, 253, 0.5)",
  "rgba(180, 180, 180, 1)",
  "rgba(72, 159, 248, 0.5)",
  "rgba(254, 122, 0, 0.5)",
  "rgba(214, 105, 190, 0.5)",
  "rgba(255, 229, 0, 0.5)",
];
