import React from "react";
import { Box } from "@mui/material";
import SimpleSelect from "../../DataTypes/Components/SimpleSelect";

const SORT_LABELS = {
  asc: "Ascending",
  desc: "Descending",
};

const SORT_OPTIONS = [
  {
    key: "asc",
    label: SORT_LABELS.asc,
  },
  {
    key: "desc",
    label: SORT_LABELS.desc,
  },
];

const ChooseSort = (props) => {
  const { sort, onChange } = props;

  const handleChange = (e) => {
    onChange(sort, { operator: e?.target?.value });
  };

  return (
    <Box sx={{ width: "120px" }} className="tv-cf">
      <SimpleSelect
        value={sort?.operator}
        options={SORT_OPTIONS}
        handleChange={handleChange}
      />
    </Box>
  );
};

export default ChooseSort;
