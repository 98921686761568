import React, { useEffect, useRef, useState } from "react";
import { useSlate } from "slate-react";
import { Box, Dialog, IconButton, Paper, Popper, Tooltip } from "@mui/material";
import MENU_OPTIONS from "./Options/Options";
import TextFormat from "../PopupTool/TextFormat";
import AddElements from "../PopupTool/AddElements";
import AddTemplates from "../PopupTool/AddTemplates";
import miniToolbarStyles from "./Styles";
import usePopupStyle from "../PopupTool/PopupToolStyle";
import PopperHeader from "../PopupTool/PopperHeader";
import { useEditorContext } from "../../hooks/useMouseMove";
import PageSettingsButton from "../../Elements/PageSettings/PageSettingsButton";

const POPUP_TYPES = {
  textFormat: TextFormat,
  addElement: AddElements,
  addTemplate: AddTemplates,
};

const POPUP_OFFSETS = {
  textFormat: [75, 5],
  addElement: [40, 5],
  addTemplate: [10, 5],
};

const FULLSCREEN_POPUP = {
  addTemplate: true,
};

const POPUP_TITLE = {
  textFormat: "Text Settings",
  addElement: "Add Elements",
  addTemplate: "Templates",
};

const MiniToolbar = (props) => {
  const { customProps, theme } = props;
  const classes = miniToolbarStyles(theme);
  const popupStyles = usePopupStyle(theme);
  const editor = useSlate();
  const [popper, setPopper] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fullScreen, setFullScreen] = useState(false);
  const [undoCooldown, setUndoCooldown] = useState(false);
  const [redoCooldown, setRedoCooldown] = useState(false);
  const [search, setSearch] = useState("");
  const PopupComponent = POPUP_TYPES[popper] || null;
  const open = Boolean(PopupComponent);
  const DialogComp = !fullScreen ? Popper : Dialog;
  const { hideTools } = customProps;
  const boxRef = useRef(null); // Add ref to get the Box element
  const UPDATED_MENU_OPTIONS = MENU_OPTIONS.filter(
    (f) => (hideTools || [])?.indexOf(f.type) === -1
  );
  const { popupType, selectedElement, setSelectedElement } = useEditorContext();
  const cooldownTime = 200;

  const canUndo = editor.history.undos.length > 0 && !undoCooldown;
  const canRedo = editor.history.redos.length > 0 && !redoCooldown;

  const [toolTip, setToolTip] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (data) {
      setToolTip(false);
      setData(null);
    }
  }, [data]);

  useEffect(() => {
    if (popper) {
      setPopper(null);
    }
  }, [editor.selection]);

  const handleClick = (type) => (e) => {
    if (type === "undo" && canUndo && !undoCooldown) {
      editor.undo();
      setUndoCooldown(true);
      setTimeout(() => setUndoCooldown(false), cooldownTime);
      return;
    }

    if (type === "redo" && canRedo && !redoCooldown) {
      editor.redo();
      setRedoCooldown(true);
      setTimeout(() => setRedoCooldown(false), cooldownTime);
      return;
    }

    if (type === "page-settings") {
      setToolTip(true);
    }
    setPopper(type);
    setAnchorEl(e.currentTarget);
    setSelectedElement({
      ...selectedElement,
      enable: 1,
      selectedAction: null,
    });
  };

  const onClose = () => {
    setPopper(null);
    setFullScreen(false);
  };

  const toggleFullscreen = () => {
    setFullScreen(!fullScreen);
  };

  const onSearch = (e) => {
    setSearch(e?.target?.value || "");
  };

  const closePopper = (data) => {
    setData(data);
    setToolTip(false);
  };

  // Calculate center of Box for Popper/Modal positioning
  const getBoxCenter = () => {
    if (boxRef.current) {
      const rect = boxRef.current.getBoundingClientRect();
      return {
        top: rect.top + rect.height / 2,
        left: rect.left + rect.width / 2,
      };
    }
    return { top: 0, left: 0 };
  };

  return (
    <>
      <Box
        component={"div"}
        className="mini-tool-wrpr-ei"
        sx={classes.root}
        ref={boxRef}
      >
        {UPDATED_MENU_OPTIONS.map(({ type, label, icon: Icon }) => {
          const isDisabled =
            popupType === type || type === "undo"
              ? !canUndo
              : type === "redo"
              ? !canRedo
              : false;

          const isFocussed = editor?.selection?.anchor?.path;
          const disableAddElement = type === "addElement" && !isFocussed;

          return (
            <Tooltip
              arrow
              key={`mini_tool_btn_${type}`}
              title={label}
              disableHoverListener={toolTip}
            >
              <IconButton
                className={`${type === popper ? "active" : ""} ${
                  (type === "undo" && !canUndo) || (type === "redo" && !canRedo)
                    ? "disabled"
                    : ""
                } ${
                  type === "undo"
                    ? canUndo
                      ? "activeUndo"
                      : "disabledUndo"
                    : type === "redo"
                    ? canRedo
                      ? "activeRedo"
                      : "disabledRedo"
                    : ""
                } ${disableAddElement ? "disableAddElement" : ""}
                `}
                onClick={handleClick(type)}
                disabled={isDisabled || disableAddElement}
              >
                {type === "page-settings" ? (
                  <PageSettingsButton
                    from="miniToolBar"
                    icoBtnType={"mini"}
                    customProps={customProps}
                    editor={editor}
                    closePopper={closePopper}
                    setToolTip={setToolTip}
                  />
                ) : (
                  <Icon
                    from="miniToolBar"
                    icoBtnType={"mini"}
                    customProps={customProps}
                    editor={editor}
                  />
                )}
              </IconButton>
            </Tooltip>
          );
        })}
      </Box>

      <DialogComp
        open={open}
        onClose={onClose}
        anchorEl={anchorEl || getBoxCenter()} // Positioning at center of Box
        placement="top"
        sx={popupStyles.popupWrapper}
        className={`tools-drawer ${fullScreen ? "fullscreen" : ""}`}
        fullScreen={fullScreen}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: POPUP_OFFSETS[popper] || [0, 10], // Add some space between anchor and popper
            },
          },
        ]}
      >
        {PopupComponent ? (
          <Paper className="papper-wrpr">
            <PopperHeader
              title={POPUP_TITLE[popper]}
              classes={popupStyles}
              onClose={onClose}
              needFullscreen={FULLSCREEN_POPUP[popper]}
              fullScreen={fullScreen}
              toggleFullscreen={toggleFullscreen}
              search={search}
              onSearch={onSearch}
            />
            <PopupComponent
              classes={popupStyles}
              editor={editor}
              customProps={customProps}
              fullScreen={fullScreen}
              setPopper={setPopper}
              onClose={onClose}
              search={search}
              onSearch={onSearch}
            />
          </Paper>
        ) : null}
      </DialogComp>
    </>
  );
};

export default MiniToolbar;
