const HashtagIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 1.5L4 10.5" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 1.5L7 10.5" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.75 4.5H10.75" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.25 7.5H10.25" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default HashtagIcon