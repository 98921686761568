const SelectStyles = (theme = {}) => {
  const { textColor, background } = theme?.palette?.editor || {};
  return {
    MenuProps: {
      PaperProps: {
        sx: {
          color: textColor,
          background,
        },
      },
    },
  };
};

export default SelectStyles;
