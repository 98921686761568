import React from "react";
import {
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

import { FILTER_TYPES } from "./Constants";
import { useDataView } from "../../Providers/DataViewProvider";
import Icon from "../../../../common/Icon";

const FilterProperty = (props) => {
  const { sort } = useDataView();
  const { edit, onEvent } = props;

  const handleChangeOption = (m) => () => {
    onEvent("addSort", {
      key: edit?.key,
      ...(sort[0] || {}),
      newKey: edit?.key,
      operator: m?.operator,
    });
  };

  return (
    <List className="fe-dv-opt-list mt">
      {FILTER_TYPES?.map(({ ...m }, i) => {
        return (
          <ListItemButton key={i} onClick={handleChangeOption(m)}>
            <ListItemIcon>
              <Icon icon={m?.Icon}/>
            </ListItemIcon>
            <ListItemText primary={m.label} />
          </ListItemButton>
        );
      })}
    </List>
  );
};

FilterProperty.defaultProps = {
  onEvent: () => {},
};

export default FilterProperty;
