import React from "react";
import {
  Box,
  Popover,
  SwipeableDrawer,
  IconButton,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useFilterSortStyles from "./styles";
import SortOptions from "./SortOptions";
import { useEditorContext } from "../../../../hooks/useMouseMove";

const FilterSort = (props) => {
  const { theme: appTheme } = useEditorContext();
  const theme = useTheme();
  const { open, mode, anchorEl, onClose } = props;
  const classes = useFilterSortStyles(theme, appTheme);
  const isMobile = window.matchMedia("(max-width: 899px)")?.matches || false;
  const PoperComponent = isMobile ? SwipeableDrawer : Popover;

  const renderMode = () => {
    if (mode?.type === "filter") {
      return "Filter Mode will be availbele soon";
    } else if (mode?.type === "sort") {
      return <SortOptions onClose={onClose} />;
    } else {
      return "Unknown Filter / Sort Mode";
    }
  };

  return (
    <PoperComponent
      open={open}
      sx={classes.root}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={onClose}
      anchor="bottom"
    >
      <Box sx={classes.contentWrapper}>
        <Box className={"fe-dv-ap-title"}>
          <span>Sort By</span>
          <IconButton
            className="tv-act-ico bg br1"
            size="small"
            onClick={onClose}
            sx={{ '& svg': { '& path': { strokeWidth: 0 } } }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {renderMode()}
      </Box>
    </PoperComponent>
  );
};

export default FilterSort;
