import React from "react";
import DatePicker from "react-datepicker";
import { useDataView } from "../../Providers/DataViewProvider";

function isValidDate(dateString) {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
}

const DateType = (props) => {
  const { rowIndex, property, value, readOnly } = props;
  const { onChange } = useDataView();

  const handleChange = (date) => {
    onChange(rowIndex, {
      [property]: date,
    });
  };

  return (
    <DatePicker
      disabled={readOnly}
      selected={isValidDate(value) ? new Date(value) : ""}
      onChange={handleChange}
      onKeyDown={(e) => {
        e.preventDefault();
        console.log(e?.target.value);
      }}
      placeholderText="MM/DD/YYYY"
    />
  );
};

export default DateType;
