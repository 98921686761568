import { useState } from "react";

const useTableResize = ({
  parentDOM,
  size: defaultSize,
  onDone,
  minMaxProps = {},
}) => {
  const { width } = parentDOM?.getBoundingClientRect() || {
    ...defaultSize,
  };
  const [size, setSize] = useState({
    height: 300,
    widthInPercent: 100,
    ...defaultSize,
    width,
  });
  const [resizing, setResizing] = useState(false);
  const [isDone, setIsDone] = useState(0);

  const onLoad = (defaultSize) => {
    setSize({ widthInPercent: 100, height: 300, ...defaultSize });
  };

  const onMouseDown = (e) => {
    e.preventDefault();
    document.addEventListener("pointermove", onMouseMove);
    document.addEventListener("pointerup", onMouseUp);
    setResizing(true);
    setIsDone(0);
  };
  const onMouseUp = () => {
    document.removeEventListener("pointermove", onMouseMove);
    document.removeEventListener("pointerup", onMouseUp);
    setResizing(false);
    setIsDone(1);
  };
  const onMouseMove = (e) => {
    const { minWidth } = minMaxProps || {};

    setSize((currentSize) => {
      const calcWidth = currentSize?.width + e.movementX;
      return {
        width: minWidth && calcWidth < minWidth ? minWidth : calcWidth,
        height: currentSize.height + e.movementY,
        widthInPercent: (calcWidth / width) * 100,
      };
    });
  };

  return [size, onMouseDown, resizing, onLoad, isDone];
};

export default useTableResize;
