import React from "react";
import { useDataView } from "../../Providers/DataViewProvider";
import Checkbox from "@mui/material/Checkbox";
import Icon from "../../../../common/Icon";

const CheckType = (props) => {
  const { rowIndex, property, value, readOnly } = props;
  const { onChange } = useDataView();

  const handleChange = (e) => {
    onChange(rowIndex, {
      [property]: e?.target?.checked,
    });
  };

  return (
    <Checkbox
      key={rowIndex}
      checked={value || false}
      icon={<Icon icon="checkListButton" />}
      checkedIcon={<Icon icon="checkListButtonActive" />}
      onChange={handleChange}
      disabled={readOnly}
    />
  );
};

export default CheckType;
