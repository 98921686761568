import { Editor, Node, Transforms, Element, Path, Range, Text } from "slate";
import { ReactEditor } from "slate-react";
import insertNewLine from "./insertNewLine";
import { getDevice } from "../helper/theme";

export const windowVar = {};
let ST_TIMEOUT = null;
const BLOCKS = ["grid", "dataView"];

export const formatDate = (date, format = "MM/DD/YYYY") => {
  if (!date) return "";
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  switch (format) {
    case "MM/DD/YYYY":
      return [month, day, year].join("/");
    case "YYYY-MM-DD":
      return [year, month, day].join("-");
    default:
      return [year, month, day].join("-");
  }
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    if (file) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    } else {
      resolve("");
    }
  });
};

export const gradientBorder = (color) => {
  if (color?.indexOf("linear") > -1) {
    return {
      borderImage: `${color} 30`,
    };
  } else {
    return {
      borderColor: color || "transparent",
    };
  }
};

export const getBorderColor = (color, borderWidth = 3) => {
  if (color?.indexOf("linear") > -1) {
    return {
      borderImage: `${color} ${borderWidth}`,
      borderWidth: `0px 0px 0px ${borderWidth}px`,
      borderStyle: "solid",
    };
  } else {
    return {
      borderColor: color || "transparent",
    };
  }
};

export const absoluteLink = (url) => {
  try {
    if (url?.indexOf("://") === -1) {
      return `/${url}`;
    }
    return url;
  } catch (err) {
    console.log(err);
    return url;
  }
};

export const isTextSelected = (selection) => {
  try {
    const { anchor, focus } = selection || {};
    if (anchor && focus) {
      if (JSON.stringify(anchor?.path) !== JSON.stringify(focus?.path)) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getSelectedText = (editor) => {
  try {
    return Editor.string(editor, editor?.selection);
  } catch (err) {
    console.log(err);
    return "";
  }
};

export const isEmptyNode = (editor, children, path) => {
  try {
    const isEmptyText = Node.string(Node.get(editor, path))?.length === 0;
    const emptyNode =
      children?.length === 1 &&
      children &&
      children[0]?.children[0]?.type === undefined &&
      children[0]?.type === "paragraph";
    return isEmptyText && emptyNode;
  } catch (err) {
    // console.log(err);
    return "";
  }
};

export const outsideEditorClickLabel = "handle-outside-editor-click";

function isLastChildParagraphWithText(node) {
  try {
    if (!node || !Array.isArray(node.children)) {
      return false;
    }

    // Get the last child node
    const lastChild = node.children[node.children.length - 1];

    // Check if the last child exists and is of type 'paragraph'
    if (lastChild && lastChild.type === "paragraph") {
      // Ensure all children of the paragraph node are text nodes
      const hasOnlyTextChildren = lastChild.children.every((child) =>
        Text.isText(child)
      );
      console.log(hasOnlyTextChildren);
      return hasOnlyTextChildren;
    }

    return false;
  } catch (err) {
    console.log(err);
    return false;
  }
}

const insertNewLineOnColumn = (editor, pathStr) => {
  try {
    const path = pathStr.split(",").map((m) => parseInt(m));
    const colNode = Node.get(editor, path);
    const isValid = isLastChildParagraphWithText(colNode);
    if (colNode?.type === "grid-item" && !isValid) {
      const newPath = [...path, colNode?.children.length];
      Transforms.insertNodes(
        editor,
        [{ type: "paragraph", children: [{ text: "" }] }],
        { at: newPath, select: true }
      );
    }
  } catch (err) {
    console.log(err);
  }
};

export const handleInsertLastElement = (event, editor) => {
  if (event.target.dataset.info !== outsideEditorClickLabel) {
    return;
  }
  const hasPath = event.target.dataset.path;

  const lastElement = editor.children[editor.children?.length - 1];
  const isFreeGrid =
    lastElement?.type === "freegrid" ||
    lastElement?.children[0]?.type === "freegrid";

  if (isFreeGrid) {
    return;
  }

  const isLastElementEmpty =
    lastElement.type === "paragraph" &&
    !lastElement.children[0]?.text &&
    !lastElement.children?.some((c) => BLOCKS.includes(c.type));
  if (!ReactEditor.isFocused(editor)) {
    if (isLastElementEmpty) {
      if (hasPath) {
        insertNewLineOnColumn(editor, hasPath);
        return;
      }
      // just focus on the last empty element
      const path = [editor.children.length - 1, 0];
      const move = {
        path: path,
        offset: 0,
      };

      Transforms.insertNodes(
        editor,
        {
          text: "",
        },
        {
          at: path,
        }
      );

      Transforms.move(editor, move);

      Transforms.select(editor, move);
    } else {
      // if dataset has path insert new line on the path (for columns / grid)
      if (hasPath) {
        insertNewLineOnColumn(editor, hasPath);
        return;
      }
      // insert an new empty element and focus
      Transforms.insertNodes(
        editor,
        [
          {
            type: "paragraph",
            children: [{ text: "" }],
          },
        ],
        { at: [editor.children.length], select: true }
      );
    }

    ReactEditor.focus(editor);
  } else if (hasPath) {
    insertNewLineOnColumn(editor, hasPath);
  }
};

export const isListItem = (editor) => {
  const format = [
    "list-item",
    "check-list-item",
    "accordion-summary",
    "headingOne",
    "headingTwo",
    "headingThree",
  ];

  const [node] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      Element.isElement(n) &&
      format.indexOf(n.type) > -1,
  });

  return node;
};

export const getNode = (editor, path) => {
  try {
    return Node.get(editor, path);
  } catch (err) {
    return;
  }
};

export const customInsertNode = (
  editor,
  insertNode,
  defaultInsertOptions = {}
) => {
  const [parent, parentPath] = Editor.parent(
    editor,
    editor.selection.focus.path
  );

  const isListItem =
    parent?.type === "list-item" || parent?.type === "check-list-item";

  let newParentPath;

  if (isListItem) {
    const lastPathIndex = parentPath.length - 1;
    const otherPaths = parentPath.slice(0, lastPathIndex);
    const nextChildrenPath = parentPath[lastPathIndex] + 1;
    newParentPath = [...otherPaths, nextChildrenPath];

    const haveElem = getNode(editor, newParentPath);

    if (haveElem) {
      Transforms.splitNodes(editor, { at: newParentPath });
    }

    const { anchor, focus } = editor.selection;

    // if editor has selection, e.g /table, /grid is selected, delete that selection
    if (focus.offset > anchor.offset) {
      Transforms.delete(editor, { at: editor.selection });
    }
  }

  const insertOptions = { ...defaultInsertOptions };

  if (isListItem) {
    insertOptions.at = editor.selection.focus;
  }

  Transforms.insertNodes(editor, insertNode, insertOptions);
  insertNewLine(editor);
};

export const decodeAndParseBase64 = (encodedString) => {
  // Decode the Base64-encoded string
  const decodedString = atob(encodedString);

  // URL-decode the decoded string
  const decodedURLString = decodeURIComponent(decodedString);

  const jsonData = JSON.parse(decodedURLString);

  return jsonData;
};

export const encodeToBase64 = (data) => {
  // Convert the data to a JSON string
  const jsonString = JSON.stringify(data);

  // URL-encode the JSON string
  const encodedURLString = encodeURIComponent(jsonString);

  // Base64-encode the URL-encoded string
  const base64EncodedString = btoa(encodedURLString);

  return base64EncodedString;
};

export const hasVerticalScrollbar = (element = {}) => {
  return element.scrollHeight > element.clientHeight;
};

const isHomePage = (page) => {
  return (
    page === "home" ||
    page === "iframe.html" ||
    page === "_currentPage" ||
    !page
  );
};

const getScrollElement = () => {
  const slateWrapper = document.getElementById(
    "slate-wrapper-scroll-container"
  );

  const isSlateWrapperScroll = hasVerticalScrollbar(slateWrapper);
  const scrollFrom = isSlateWrapperScroll ? slateWrapper : window;

  return scrollFrom;
};

const handleLinkBtnClick = (e, props, isMobilePreview) => {
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }

  if (props.target) {
    window.open(props.href);
  } else {
    window.location.href = props.href;

    if (isMobilePreview) {
      // on iframe - mobile preview, window.location.href is not scrolling to the element correctly
      const [, elementId] = props.href?.split("#");

      if (elementId) {
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    }
  }
};

export const handleLinkType = (
  url,
  linkType,
  readOnly,
  openInNewTab,
  onClick = () => {}
) => {
  const props = {};

  if (!readOnly) {
    return {
      component: "button",
    };
  }

  linkType = getLinkType(linkType, url);

  switch (linkType) {
    case "webAddress":
      const refUrl = url ? (url.includes("http") ? url : `//${url}`) : "Link";
      props.component = "a";
      if (refUrl !== "Link") {
        props.href = refUrl;
      }

      if (openInNewTab) {
        props.target = "_blank";
      }

      break;
    case "nextTrigger":
    case "prevTrigger":
      if (!readOnly) {
        props.component = "button";
        props.onClick = () => {};
      } else {
        props.component = "button";
        props.onClick = onClick;
      }
      break;
    case "page":
      const [page = "", section] = url?.split("#") || [];
      const sec = section ? `#${section}` : "";

      if (page === "_currentPage") {
        props.component = "button";

        props.onClick = () => {
          const scrollFrom = getScrollElement();

          if (sec) {
            const element = document.getElementById(section);

            if (element) {
              const topPosition =
                element.getBoundingClientRect().top + scrollFrom.scrollTop;

              scrollFrom.scrollTo({
                top: topPosition,
                behavior: "smooth",
              });
            }
          }
        };
      } else {
        props.component = "a";

        const currentUserPage = getCurrentUserPage();

        props.href = isCurrentPage(page)
          ? `./${currentUserPage}${sec}`
          : `./${url}`;

        if (openInNewTab) {
          if (isCurrentPage(page)) {
            // temp fix, if user is presented in current page, open in new tab option is restricted, we will scroll to the element in current page
          } else {
            props.target = "_blank";
          }
        }
      }
      break;
    case "email":
      props.component = "a";
      props.href = `mailto:${url}`;
      break;
    case "phone":
      props.component = "a";
      props.href = `tel:${url}`;
      break;
    case "scrollTopOrBottom":
      props.component = "button";
      props.onClick = () => {
        const slateWrapper = document.getElementById(
          "slate-wrapper-scroll-container"
        );

        const isSlateWrapperScroll = hasVerticalScrollbar(slateWrapper);
        const scrollFrom = isSlateWrapperScroll ? slateWrapper : window;

        if (url === "top") {
          // top of the page
          scrollFrom.scrollTo(0, 0);
        } else if (url === "bottom") {
          const pageHeight = isSlateWrapperScroll
            ? slateWrapper.scrollHeight
            : document.body.scrollHeight;

          // bottom of the page
          scrollFrom.scrollTo(0, pageHeight);
        }
      };
      break;
    default:
      return {};
  }

  // for iphone fix
  if (props.component === "a" && props.href) {
    const isMobile = getDevice(window.innerWidth) === "xs";
    if (isMobile) {
      props.component = "button"; // iphone is opening two tabs, on open in new tab because of a tag.
    }

    let touchEndClicked = false;
    props.onTouchEnd = (e) => {
      touchEndClicked = true;
      handleLinkBtnClick(e, props);
    };

    props.onClick = (e) => {
      // This condition is used for mobile preview in the page editor.
      // 'touchEnd' will not work in the mobile page preview.
      if (!touchEndClicked && isMobile) {
        handleLinkBtnClick(e, props, true);
      }

      return false;
    };
  }

  return props;
};

const getCurrentUserPage = () => {
  const paths = window.location.pathname.split("/");
  let currentUserPage = paths[paths?.length - 1];

  return currentUserPage;
};

const isCurrentPage = (page) => {
  let currentUserPage = getCurrentUserPage();
  currentUserPage = isHomePage(currentUserPage) ? "home" : currentUserPage;

  const buttonPage = isHomePage(page) ? "home" : page;

  return currentUserPage === buttonPage;
};

export const getLinkType = (linkType, url) => {
  if (!linkType && url) {
    if (url?.includes("http")) {
      linkType = "webAddress";
    } else {
      linkType = "page";
    }
  }

  return linkType;
};

export const allowedDomains = [
  "youtube.com",
  "lemcal.com",
  "facebook.com",
  "calendly.com",
];

export const encodeString = (str) => {
  try {
    if (str) {
      return btoa(str);
    }
  } catch (err) {
    console.log(err);
  }
};

export const decodeString = (str) => {
  try {
    if (str) {
      return atob(str);
    }
  } catch (err) {
    console.log(err);
  }
};

export const onDeleteKey = (event, { editor }) => {
  try {
    const { selection } = editor;
    if (selection) {
      // If text is selected, delete the selection
      Transforms.delete(editor);
    } else {
      // If no text is selected, handle deleting the next character/element
      Transforms.delete(editor, { unit: "character", reverse: false });
    }
  } catch (err) {
    console.log(err);
  }
};

export const selectText = (editor, { path, cursorOnly }, timeout = 10) => {
  try {
    clearTimeout(ST_TIMEOUT);
    ST_TIMEOUT = setTimeout(() => {
      ReactEditor.focus(editor);
      const range = Editor.range(editor, path);
      const textNode = Node.get(editor, path);
      if (!cursorOnly) {
        Transforms.select(editor, range);
      }
      if (cursorOnly && textNode) {
        const textLength = Node.string(textNode).length;
        editor.selection = {
          anchor: { path: path, offset: textLength },
          focus: { path: path, offset: textLength },
        };
      }
      ReactEditor.focus(editor);
    }, timeout);
  } catch (err) {
    console.log(err);
  }
};

export const isFreeGridFragment = (fragments) => {
  const types = ["freegrid", "freegridItem", "freegridBox"];
  const node = fragments[0] || fragments;
  const fType =
    types.includes(node.children[0]?.type) || types.includes(node.type);
  if (fType) {
    return true;
  } else {
    return false;
  }
};

export const editorThemeStyle = {
  light: {
    editor: {
      textColor: "#000000",
      background: "#FFFFFF",
      svgStroke: "#64748B",
      borderColor: "#D8DDE1",
      activeColor: "#2563EB",
      svgStrokeDisabled: "#64748B4D",
    },
  },
  dark: {
    editor: {
      textColor: "#FFFFFF",
      background: "#141720",
      svgStroke: "#64748B",
      borderColor: "#64748B",
      activeColor: "#2563EB",
    },
  },
};

export const getEditorTheme = (themeType = "light") => {
  return editorThemeStyle[themeType] || {};
};

export const isFreeGrid = (
  nodes,
  types = ["freegrid", "freegridItem", "freegridBox"]
) => {
  try {
    for (const node of nodes) {
      if (types.includes(node.type)) {
        return true;
      }
      if (node.children && isFreeGrid(node.children, types)) {
        return true;
      }
    }
    return false;
  } catch (err) {
    console.log("isFreeGrid error:", err);
    return false;
  }
};

export const getNextNode = (editor) => {
  try {
    const parentPath = [editor?.selection?.focus?.path[0]];

    const nextPath = Path.next(parentPath);

    const nextNode = Node.get(editor, nextPath);

    return { nextPath, nextNode };
  } catch (err) {
    return;
  }
};

export const getPreviousNode = (editor) => {
  try {
    const parentPath = Path.parent(editor?.selection?.anchor?.path);
    const previousPath = Path.previous(parentPath);
    const previousNode = Node.get(editor, previousPath);
    return { previousNode, previousPath };
  } catch (err) {
    return;
  }
};

const isRestricted = (node) =>
  node?.type === "page-settings" ||
  node?.type === "site-settings" ||
  node?.children?.some((child) => child.type === "dataView");

export const isRestrictedNode = (event, editor) => {
  let isNodeRestricted = false;

  try {
    const { selection } = editor;

    const cursorAtStartingPosition = !selection?.focus?.offset;
    const textSelection = selection && !Range.isCollapsed(selection);

    if (cursorAtStartingPosition && !textSelection) {
      // on backspace, the node gonna delete will be the previous node from current position, that why getting the previous node
      const { previousNode, previousPath } = getPreviousNode(editor) || {};

      if (isRestricted(previousNode)) {
        event.preventDefault(); // stops deleting backward

        // move the cursor to node which is before page-settings node
        const pathBeforePageSettings = Path.previous(previousPath);
        const endPath = Editor.end(editor, pathBeforePageSettings);
        Transforms.select(editor, endPath);

        isNodeRestricted = true;
      }
    }

    return isNodeRestricted;
  } catch (err) {
    // if there is no previous node error throws, user reached the starting node and startting position
    isNodeRestricted = true;
    return isNodeRestricted;
  }
};

export const insertLineBreakAtEndOfPath = (editor, path) => {
  try {
    const [node, nodePath] = Editor.node(editor, path); // Get the node at the specified path
    if (node) {
      // Insert the line break
      Transforms.insertNodes(
        editor,
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
        { at: nodePath }
      );
    }
  } catch (err) {
    console.log(err);
  }
};
const omitNodes = ["site-settings", "page-settings"];

export function getInitialValue(value = [], readOnly) {
  if (readOnly === "readonly" && value?.length) {
    // remove last empty nodes on readonly mode, to remove empty spaces

    let lastNonEmptyElementIndex;

    const omittedNodes = [];

    for (let i = value?.length; i > 0; i--) {
      const elementIndex = i - 1;
      const node = value[elementIndex];

      if (lastNonEmptyElementIndex) {
        break;
      }

      if (node?.type === "paragraph") {
        // Ensure all children of the paragraph node are text nodes
        const hasOnlyTextChildren = node.children.every((child) =>
          Text.isText(child)
        );

        const text = node.children[node.children.length - 1]?.text;

        lastNonEmptyElementIndex = hasOnlyTextChildren
          ? text
            ? elementIndex
            : null
          : elementIndex;
      } else if (omitNodes.includes(node?.type)) {
        omittedNodes.push(node);
        continue;
      } else {
        lastNonEmptyElementIndex = elementIndex;
      }
    }

    const newValue = [...value].slice(0, lastNonEmptyElementIndex + 1);

    return [...newValue, ...omittedNodes];
  }

  return value;
}
export function capitalizeFirstLetter(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isHavingSelection(editor) {
  try {
    return editor?.selection && !Range.isCollapsed(editor.selection);
  } catch (err) {
    console.log(err);
  }
}

export function getSelectedCls(
  defaultCls = "",
  selected,
  selectedClsName = "selected"
) {
  return `${defaultCls} ${selected ? selectedClsName : ""}`;
}

export const getNodeWithType = (editor, nodeType = "", otherOptions) => {
  try {
    const options = {
      match: (n) =>
        !Editor.isEditor(n) && Element.isElement(n) && n.type === nodeType,
      ...(otherOptions || {}),
    };

    const [node, nodePath] = Editor.nodes(editor, options);

    return node ? [node, nodePath] : [];
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const containsSurrogatePair = (text) => {
  // Match surrogate pairs (high and low surrogate)
  const surrogatePairRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/;
  return surrogatePairRegex.test(text);
};

export const getSlateDom = (editor, range) => {
  try {
    const slateDom = ReactEditor.toDOMRange(editor, range);
    return slateDom;
  } catch (err) {
    console.log(err);
  }
};

export function handleNegativeInteger(val) {
  return val < 0 ? 0 : val;
}
