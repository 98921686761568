import React from "react";
import {
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { PROPERTY_TYPES, TYPE_ICONS } from "./Constants";
import { useDataView } from "../../Providers/DataViewProvider";
import Icon from "../../../../common/Icon";

const AllProperties = (props) => {
  const { classes, onClose, onEvent } = props;
  const { properties, onAddProperty } = useDataView();
  const shownProperties = properties?.filter((f) => f.visible === true);
  const hiddenProperties = properties?.filter((f) => f.visible === false);

  const onEditProperty = (data) => () => {
    onEvent("editProperty", { edit: { ...data } });
  };

  const onAdd = () => {
    const np = onAddProperty({ type: "text" });
    onEvent("editProperty", { edit: { ...np } });
  };

  return (
    <Box sx={classes.addProperty}>
      <Box className={"fe-dv-ap-title"}>
        Properties
        <IconButton
          className="tv-act-ico bg br1"
          size="small"
          onClick={onClose}
          sx={{ '& svg': { '& path': { strokeWidth: 0 } } }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className={"fe-dv-ap-desc"}>Displayed Properties</Box>
      <Box className={"fe-dv-ap-opt-content"}>
        <List className="fe-dv-opt-list">
          {shownProperties?.map((m, i) => {
            const { Icon: iconType } =
              PROPERTY_TYPES?.find((f) => f.type === m.type) || {};
            return (
              <ListItemButton key={i} onClick={onEditProperty(m)}>
                <ListItemIcon className="needBg" sx={{ '& svg': { width: '14px !important', height: '14px !important' } }}>
                  <Icon icon={iconType} />
                </ListItemIcon>
                <ListItemText>{m?.label}</ListItemText>
                <ListItemIcon sx={{ justifyContent: "end" }}>
                  <Icon icon={'rightArrow'} />
                </ListItemIcon>
              </ListItemButton>
            );
          })}
        </List>

        {hiddenProperties?.length > 0 ? (
          <>
            <Box className={"fe-dv-ap-desc"}>Hidden Properties</Box>
            <List className="fe-dv-opt-list">
              {hiddenProperties?.map((m, i) => {
                return (
                  <ListItemButton key={i} onClick={onEditProperty(m)}>
                    <ListItemIcon className="needBg" sx={{ alignItems: "center", '& svg': { width: '14px !important', height: '14px !important' } }}>
                      <Icon icon={TYPE_ICONS[m?.type]} />
                    </ListItemIcon>
                    <ListItemText>{m?.label}</ListItemText>
                    <ListItemIcon sx={{ alignItems: "center", justifyContent: 'flex-end' }}>
                      <Icon icon={'rightArrow'} />
                    </ListItemIcon>
                  </ListItemButton>
                );
              })}
            </List>
          </>
        ) : null}
        <Divider className="divider_sty" />
        <List className="fe-dv-opt-list">
          <ListItemButton onClick={onAdd}>
            <ListItemIcon>
              <Icon icon={'plusIcon'} />
            </ListItemIcon>
            <ListItemText>Add New Property</ListItemText>
          </ListItemButton>
        </List>
      </Box>
    </Box>
  );
};

AllProperties.defaultProps = {};

export default AllProperties;
