import React from "react";
import { Box } from "@mui/material";
import { useDataView } from "../Providers/DataViewProvider";
import ColumnView from "./ColumnView";

const RenderRow = (props) => {
  const { rowIndex, row, properties, onSelect, selected, readOnly } = props;
  const showProperties = properties?.filter((f) => f.visible);
  return showProperties?.map((property, i) => {
    return (
      <ColumnView
        needAnchor={i === 0}
        key={`${property.key}_${i}`}
        row={row}
        rowIndex={rowIndex}
        property={property}
        onSelect={onSelect}
        selected={selected}
        readOnly={readOnly}
      />
    );
  });
};

const ViewData = (props) => {
  const { attributes, children, customProps } = props;
  const { readOnly } = customProps || {};
  const { properties, rows, selectedRows, setSelectedRows } = useDataView() || {};

  const onSelect = (id, checked) => {
    if (checked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows([...selectedRows?.filter((f) => f !== id)]);
    }
  };

  return (
    <Box
      component={"tbody"}
      {...attributes}
      contentEditable={false}
      sx={{ "& .MuiInputBase-root": { background: "transparent !important" } }}
    >
      {rows?.map((row, i) => {
        return (
          <Box component={"tr"} className="tv-act-row" key={i}>
            <RenderRow
              rowIndex={row?.id}
              row={row}
              properties={properties}
              onSelect={onSelect}
              selected={selectedRows?.includes(row?.id)}
              readOnly={readOnly}
            />
          </Box>
        );
      })}
      <tr style={{ visibility: "hidden", display: "none" }} aria-hidden="true">
        <td>{children}</td>
      </tr>
    </Box>
  );
};

export default ViewData;
