import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import ToolbarIcon from "../../common/ToolbarIcon";
import Icon from "../../common/Icon";
import { Paper, Popover } from "@mui/material";
import { ReactEditor, useSlateStatic } from "slate-react";
import SwipeableDrawerComponent from "../../common/SwipeableDrawer";
import SearchAndDocList from "./SearchList";
import { insertBrain } from "../../utils/brains";
import { useDebounce } from "use-debounce";
import { clearBrainText } from "../../helper";

const SearchButton = forwardRef((props, ref) => {
    const { element, icoBtnType, selectionTarget, theme = 'light', otherProps } = props;
    const editor = useSlateStatic();
    const [anchorEl, setAnchorEl] = useState(null);
    const [target, setTarget] = useState(selectionTarget);
    const open = Boolean(anchorEl);
    const isMobile = window.matchMedia("(max-width: 899px)")?.matches || false;

    const [mapData, setMapData] = useState([]);
    const [skip, setSkip] = useState(0);
    const [search, setSearch] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [debouncedSearch] = useDebounce(search, 300);
    const limit = 20;

    const observer = useRef();
    const lastDocRef = useCallback(
        (node) => {
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    setSkip((prevSkip) => prevSkip + limit);
                }
            });
            if (node) observer.current.observe(node);
        },
        []
    );

    useImperativeHandle(ref, () => ({
        triggerClick: (target) => {
            setTarget(target);
            onClick();
        },
    }));

    const onClick = () => {
        try {
            if (editor.selection) {
                const domRange = ReactEditor.toDOMRange(editor, editor.selection);
                const rect = domRange.getBoundingClientRect();
                setAnchorEl({
                    getBoundingClientRect: () => rect,
                    nodeType: 1,
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getDocs({ debouncedSearch, skip, limit });
    }, [skip, debouncedSearch])

    const getDocs = async () => {
        setIsLoading(true);
        try {
            if (otherProps?.services) {
                const result = await otherProps?.services("getDocs", { skip, limit, search });
                setMapData((prev) => (skip === 0 ? result.data : [...prev, ...result.data]));
            }
        } catch (error) {
            console.error("Error fetching documents:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const onSearchChange = (value) => {
        setSearch(value);
        setSkip(0);
    };

    const handleClose = () => { 
        clearBrainText(editor)
        setAnchorEl(null);
        setSearch("");
    };

    const handleClick = (data) => {
        insertBrain(editor, data, target || editor.selection);
        setAnchorEl(null);
    };

    return (
        <>
            <ToolbarIcon
                title={element?.name}
                onClick={onClick}
                icon={<Icon icon={element?.name?.toLowerCase()} />}
                icoBtnType={icoBtnType}
            />
            {isMobile ? (
                <SwipeableDrawerComponent
                    open={open}
                    onClose={handleClose}
                    customClass="emojiDrawer"
                    swipeableDrawer={false}
                >
                    <Paper sx={{ 
                            width: '100%', 
                            background: theme?.palette?.editor?.miniToolBarBackground, 
                            boxShadow: 'none'
                        }}>
                        <SearchAndDocList
                            mapData={mapData}
                            isLoading={isLoading}
                            lastDocRef={lastDocRef}
                            onSearchChange={onSearchChange}
                            handleClick={handleClick}
                            theme={theme}
                            handleClose={handleClose}
                        />
                    </Paper>
                </SwipeableDrawerComponent>
            ) : (
                <Popover
                    style={{ zIndex: 10001 }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    onClose={handleClose}
                    sx={{
                        '& .MuiPaper-root': {
                            borderRadius: '12px !important',
                        }
                    }}
                >
                    <Paper sx={{ 
                            padding: 0, 
                            width: '330px', 
                            background: theme?.palette?.editor?.miniToolBarBackground, 
                            border: '1px solid', 
                            borderColor: theme?.palette?.type === 'dark' ? theme?.palette?.editor?.popUpBorderColor : 'transparent', 
                            borderRadius: '12px', 
                            boxShadow: theme?.palette?.type === 'dark' ? 'transparent' : '0px 4px 10px 0px #00000029',
                            '&.MuiPaper-root-MuiPopover-paper': { borderRadius: '12px !important', boxShadow: theme?.palette?.type === 'dark' ? 'transparent' : '0px 4px 10px 0px #00000029' },
                            '@media only screen and (min-width: 350px) and (max-width: 599px)': {
                                width: '100%',
                            }
                        }}>
                        <SearchAndDocList
                            mapData={mapData}
                            isLoading={isLoading}
                            lastDocRef={lastDocRef}
                            onSearchChange={onSearchChange}
                            handleClick={handleClick}
                            theme={theme}
                            handleClose={handleClose}
                        />
                    </Paper>
                </Popover>
            )}
        </>
    );
});

SearchButton.displayName = "SearchButton";

export default SearchButton;
