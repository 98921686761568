import React, { useState } from "react";
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  TextField,
  Chip,
} from "@mui/material";
import { colors } from "../../../Color Picker/defaultColors";
import Icon from "../../../../common/Icon";
const DEFAULT_COLORS = colors?.filter((f) => !(f.includes("linear") || f === "#000000"));

const AddOptions = (props) => {
  const { edit, onUpdate, onEvent } = props;
  const [addBox, setAddBox] = useState(false);
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const { options } = edit;

  const onAddBox = () => {
    setAddBox(!addBox);
  };

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * DEFAULT_COLORS.length);
    return DEFAULT_COLORS[randomIndex];
  };

  const handleEnter = (e) => {
    const isExists = options?.find(
      (f) => f.value.toLowerCase() === value.toLowerCase()
    );
    if (e.keyCode === 13 && value?.trim()) {
      if (isExists) {
        setError("Option Already Exists!");
        return;
      }
      onUpdate({
        ...edit,
        options: [
          {
            value: value,
            color: getRandomColor(),
          },
          ...(options || []),
        ],
      });
      setValue("");
    }
  };

  const handleChange = (e) => {
    setValue(e?.target?.value);
    setError("");
  };

  const handleChangeOption = (optIndex) => () => {
    onEvent("editOption", {
      edit: {
        ...edit,
        optionIndex: optIndex,
      },
    });
  };

  const onBlur = () => {
    setValue("");
    setAddBox(false);
    setError("");
  };

  return edit?.type === "select" || edit?.type === "multi-select" ? (
    <Box className="fe-tv-addopt sb mb-0">
      <List className="fe-dv-opt-list pl-0">
        <ListItemButton onClick={onAddBox}>
          <ListItemIcon>
            <Icon icon={ 'plusIcon' }/>
          </ListItemIcon>
          <ListItemText primary={"Add Options"}></ListItemText>
        </ListItemButton>
      </List>
      {addBox ? (
        <Box>
          <TextField
            className="mt"
            size="small"
            fullWidth
            value={value}
            onKeyUp={handleEnter}
            onChange={handleChange}
            onBlur={onBlur}
            helperText={error}
          />
        </Box>
      ) : null}
      <List>
        {options?.map((m, i) => {
          return (
            <ListItemButton key={i} onClick={handleChangeOption(i)}>
              <ListItemText
                primary={<Chip label={m.value} sx={{ background: m.color }} />}
              />
              <ListItemIcon sx={{ justifyContent: "flex-end" }}>
                <Icon icon={'rightArrow'} />
              </ListItemIcon>
            </ListItemButton>
          );
        })}
      </List>
    </Box>
  ) : null;
};

AddOptions.defaultProps = {
  onEvent: () => {},
};

export default AddOptions;
