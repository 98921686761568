const useTableStyles = (theme, appTheme) => ({
  root: {
    border: `1px solid ${appTheme?.palette?.editor?.tv_border}`,
    borderRadius: "7px 7px 0px 0px",
    overflowX: "auto",
    [theme?.breakpoints?.between("xs", "md")]: {},
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: 0,
    borderRadius: "7px 7px 0px 0px",
    overflow: "hidden",
    border: `1px solid ${appTheme?.palette?.editor?.tv_border}`,
    "& thead": {
      background: appTheme?.palette?.editor?.tv_header,
      height: "40px",
    },
    "& th": {
      "& svg": {
        "& .fillStroke": {
          stroke: appTheme?.palette?.editor?.tv_stroke,
        },
        "& path": {
          stroke: appTheme?.palette?.editor?.tv_text,
        },
      },
    },
    "& td": {
      height: "40px",
      "& input": {
        color: appTheme?.palette?.editor?.tv_text_primary,
        background: "transparent",
        fontSize: "14px",
      },

      "& .Mui-disabled": {
        "-webkit-text-fill-color": `${appTheme?.palette?.editor?.tv_text_primary} !important`,
      },
    },
    "& th, tr, td": {
      border: `1px solid ${appTheme?.palette?.editor?.tv_border}`,
    },
    "& .tv-act-btn": {
      color: appTheme?.palette?.editor?.tv_text,
      textTransform: "none",
      "& .MuiButton-startIcon": {
        background: appTheme?.palette?.editor?.tv_ico_bg,
        borderRadius: "4px",
        padding: "4px",
        "& svg": {
          width: "14px",
          height: "14px",
        },
      },
      "&.ico": {
        "& svg": {
          color: appTheme?.palette?.editor?.tv_text,
          "& path": {
            stroke: appTheme?.palette?.editor?.tv_text,
          },
        },
      },
      "&:hover": {
        color: appTheme?.palette?.editor?.tv_hover_text,
        "& svg": {
          color: appTheme?.palette?.editor?.tv_hover_text,
          "& path": {
            stroke: appTheme?.palette?.editor?.tv_hover_text,
          },
        },
      },
    },
    "& .la": {
      justifyContent: "start",
    },
    "& .react-datepicker__input-container": {
      "& input": {
        border: "0px solid transparent",
      },
    },
    "& .tv-ac-field": {
      "&.MuiAutocomplete-root": {
        "& .MuiAutocomplete-inputRoot": {
          display: "flex",
          flexWrap: "nowrap",
          paddingTop: "3px",
          paddingBottom: "3px",
          paddingLeft: "3px",
          maxWidth: "250px",
          overflow: "hidden",
          position: "relative",
          "& .tv-ms-tag-wrpr": {
            display: "flex",
            flexWrap: "nowrap",
            overflow: "auto",
          },
          "&.Mui-disabled": {
            "& input": {
              display: "none",
            },
            "& .tv-ms-tag-wrpr": {
              "& .MuiSvgIcon-root": {
                display: "none",
              },
            },
          },
        },
        "& .MuiAutocomplete-clearIndicator": {
          "& svg": {
            color: appTheme?.palette?.editor?.tv_text,
          },
        },
        "& .MuiAvatar-root": {
          width: "20px",
          height: "20px",
          marginLeft: "8px",
          fontSize: "16px",
          padding: "2px",
          textTransform: "capitalize",
        },
        "& .MuiChip-label": {
          paddingLeft: "6px",
          paddingRight: "8px",
        },
      },
    },
    "& .Mui-disabled": {
      WebkitTextFillColor: "#000 !important",
      "& .MuiChip-root": {
        opacity: 1,
      },
    },
  },
});

export default useTableStyles;
