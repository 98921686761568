const FormStyles = (theme) => ({
  addButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  infoText: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#94A3B8",
  },
  bodyTextArea: {
    '& .mini-editor-cls':{
      padding: '12px',
        '&:focus-visible': {
        outline: 'none',
        border: 'none',
      }
    },
    "& .editorWorkflow":{
      minHeight: '130px',
      padding: '12px',
      paddingBottom: 0,
      '&:focus-visible': {
        outline: 'none',
        border: 'none',
      }
    },
  },
  formHeadings: {
    fontSize: "14px",
    fontWeight: 500,
    paddingBottom: "10px",
    paddingTop: "10px",
  },
  flowListCard: {
    border: "1px solid #6F6F6F33",
    borderRadius: "8px",
    padding: "10px",
  },
  listHeading: {
    fontSize: "14px",
    fontWeight: 700,
  },
  listSubHeading: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#64748B",
    maxWidth: "380px",
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  addBtnTypo: {
    color: "#94A3B8",
    fontWeight: 500,
    fontSize: "12px",
  },
  emptyList: {
    justifyContent: "center",
    display: "flex",
    border: "1px solid #6F6F6F33",
    borderRadius: "8px",
    padding: "10px",
    color: "#94A3B8",
  },
  popupTitle: {
    fontWeight: 600,
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
  },
  subHeadings: {
    fontWeight: 500,
    fontSize: "14px",
  },
  radioBtn: {
    "& .MuiFormControlLabel-label": {
      fontSize: "14px",
      color: "#64748B",
    },
  },
  dialogTitle: {
    "& MuiDialogTitle-root": {
      padding: "16px 12px",
    },
  },
  closeBtn: {
    background: "#F4F6F9",
    color: "#64748B",
    fontSize: "14px",
    fontWeight: 500,
    padding: "4px 22px",
    textTransform: "none",
    border: "1px solid #D8DDE1",
    "&:hover": {
      border: "1px solid #64748B",
    },
  },
  variableBtn: {
    background: "#F4F6F9",
    color: "#64748B",
    fontSize: "14px",
    fontWeight: 500,
    padding: "4px 22px",
    textTransform: "none",
    border: "1px solid #D8DDE1",
    minWidth: '126px',
    "& svg": {
      "& path": {
        stroke: "#64748B",
      },
    },
    "&:hover": {
      border: "1px solid #64748B",
    },
  },
  saveBtn: {
    background: "#2563EB",
    fontSize: "14px",
    fontWeight: 500,
    padding: "4px 24px",
    textTransform: "none",
    "& .MuiButton-containedSizeSmall": {
      borderRadius: "5px",
    },
    "& .MuiButton-containedPrimary": {
      background: "#2563EB",
      borderRadius: "5px",
    },
  },
  select: {
    minWidth: "110px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      border: "1px solid #6F6F6F33",
      boxShadow: "0px 4px 16px 0px #0000000D",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      border: "1px solid #6F6F6F33",
      boxShadow: "0px 4px 16px 0px #0000000D",
    },
    "& .MuiSelect-select": {
      color: "#94A3B8",
      fontSize: "14px",
    },
  },
  selectList: {
    color: "#94A3B8",
    fontSize: "14px",
  },
  toolbarContainer: {
    border: "1px solid #6F6F6F33",
    borderRadius: "10px",
  },
  toolBar: {
    padding: "8px 10px 5px 5px",
  },
  colorButtons: {
    "& .buttonsWrpr": {
      display: "flex",
      justifyContent: "center",
    },
    "& button": {
      width: "16px",
      height: "16px",
      marginRight: "8px",
    },
  },
  colorButtonSingle: {
    "&.active": {
      "&:before": {
        content: '" "',
        position: "absolute",
        top: "-5px",
        left: "-5px",
        width: "calc(100% + 4px)",
        height: "calc(100% + 4px)",
        border: "3px solid blue",
        borderRadius: "50%",
      },
    },
  },
  colorButtonsInner: {
    width: "100%",
    padding: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& .buttonsWrpr": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    ".more-btn-cbs": {
      display: "none",
    },
    "& button": {
      width: "16px",
      height: "16px",
      margin: "0px 4px",
      border: "1px solid #ccc",
    },
  },
  moreOption: {
    background: "#F4F4F4",
    borderRadius: "8px",
  },
  dialogFooter: {
    "&.MuiDialogActions-root": {
      padding: "12px 24px",
    },
    "& .secondaryBtn": {
      marginRight: "0px !important",
    },
    "& .primaryBtn": {
      marginLeft: "15px !important",
    },
  },
  toolButtons: {
    width: "36px",
    height: "36px",
  },
  emailIndexCard: {
    borderRadius: "5px 5px 0 0",
    background: "#2563EB",
    color: "#fff",
    padding: "8px",
    marginLeft: "8px",
  },
  formWorkflow: {
    "& .MuiPaper-root": {
      backgroundColor: theme?.palette?.editor?.background,
    },
    "& .MuiTypography-root": {
      color: theme?.palette?.editor?.textColor,
    },
    "& svg": {
      color: theme?.palette?.editor?.activeColor,
    },
    "& input": {
      color: theme?.palette?.editor?.textColor,
    },
    "& textarea": {
      backgroundColor: theme?.palette?.editor?.background,
      color: theme?.palette?.editor?.textColor,
    },
  },
  root: {
    padding: '10px'
  }
  
});

export default FormStyles;
