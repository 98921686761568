import React, { useMemo, useState } from "react";
import { Grid, Button, Popover } from "@mui/material";
import ColorPickerTool from "react-gcolor-picker";
import { colors } from "../Elements/Color Picker/defaultColors";
import SwipeableDrawerComponent from "./SwipeableDrawer";

const ColorPickerToolComponent = ({ value, defaultColors = [], onSave = () => { }, recentColors = [], hideGradient, handleClose }) => {
  const [color, setColor] = useState(value);

  const handleSave = () => {
    onSave(color);
    handleClose();
  };

  const handleColorChange = (color) => {
    setColor(color);
  };

  const initialColors = useMemo(() => {
    let colors = [...recentColors, ...defaultColors];

    if (hideGradient) {
      colors = colors.filter((c) => c && !c.includes("gradient"));
    }

    return colors;
  }, [recentColors, defaultColors, hideGradient]);

  return (
    <Grid item xs={12}>
      <div>
        <ColorPickerTool
          gradient={hideGradient ? false : true}
          value={color}
          onChange={handleColorChange}
          defaultColors={initialColors}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          margin: "8px",
        }}
      >
        <Button onClick={handleClose} className="secondaryBtn">
          Cancel
        </Button>
        <Button onClick={handleSave} className="primaryBtn">
          Save
        </Button>
      </div>
    </Grid>
  )
}

const ColorPickerButton = (props) => {
  const {
    value,
    onSave,
    defaultColors = [],
    classes = {},
    recentColors = [],
    hideGradient,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const isMobile = window.matchMedia("(max-width: 899px)")?.matches || false;

  const handleColorPicker = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        style={{
          background: value,
          height: "22px",
          minWidth: "22px",
          borderRadius: "26px",
        }}
        onClick={handleColorPicker}
        className="colorPickerButton"
        sx={{...classes.colorPickerBtnBorder}}
      />
      {
        isMobile ?
          <SwipeableDrawerComponent
            open={open}
            onClose={handleClose}
            swipeableDrawer={false}
          >
            <Grid container padding={0} className="ed-cp-wrpr" sx={classes.colorPickerPopup}>
              <ColorPickerToolComponent
                value={value}
                defaultColors={defaultColors}
                onSave={onSave}
                recentColors={recentColors}
                hideGradient={hideGradient}
                handleClose={handleClose}
              />
            </Grid>
          </SwipeableDrawerComponent>
          :
          <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            onClose={handleClose}
            sx={classes.colorPickerPopup}
          >
            <Grid container padding={0} className="ed-cp-wrpr">
              <ColorPickerToolComponent
                value={value}
                defaultColors={defaultColors}
                onSave={onSave}
                recentColors={recentColors}
                hideGradient={hideGradient}
                handleClose={handleClose}
              />
            </Grid>
          </Popover>
      }
    </>
  );
};

ColorPickerButton.defaultProps = {
  defaultColors: [...colors],
};

export default ColorPickerButton;
