import React from "react";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropertyList from "./PropertyList";
import Icon from "../../../../common/Icon";

const ChangeProperty = (props) => {
  const { classes, onClose, onEvent, mode } = props;

  const onSelect = (property) => () => {
    onEvent("updateProperty", {
      ...(mode?.edit || {}),
      type: property?.type,
    });
  };

  const onBack = () => {
    onEvent("editProperty", {
      edit: { ...(mode?.edit || {}) },
    });
  };

  return (
    <Box sx={classes.addProperty}>
      <Box className={"fe-dv-ap-title"}>
        <span>
          <IconButton className="tv-act-ico" size="small" onClick={onBack} sx={{ paddingLeft: '0px', '&:hover': { background: 'transparent !important' }, '& svg': { width: '14px !important', height: '14px !important' } }}>
            <Icon icon={'leftArrow'} />
          </IconButton>
          Change Property
        </span>
        <IconButton
          className="tv-act-ico bg br1"
          size="small"
          onClick={onClose}
          sx={{ '& svg': { '& path': { strokeWidth: 0 } } }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className={"fe-dv-ap-desc"}>Select Property Type</Box>
      <Box className={"fe-dv-ap-opt-content mt"}>
        <PropertyList onSelect={onSelect} selected={mode?.edit} />
      </Box>
    </Box>
  );
};

ChangeProperty.defaultProps = {
  onEvent: () => {},
};

export default ChangeProperty;
