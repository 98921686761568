import React from "react";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import { PROPERTY_TYPES } from "./Constants";
import Icon from "../../../../common/Icon";

const ColumnsList = (props) => {
  const { properties } = props;
  const { onSelect, selected } = props;

  return (
    <Box className="opt-wrpr">
      <List className="tv-opt-list">
        {properties?.map((m, i) => {
          const { Icon: iconType } =
            PROPERTY_TYPES?.find((f) => f.type === m.type) || {};
          return (
            <ListItemButton
              className={selected?.type === m?.type ? "active" : ""}
              key={i}
              onClick={onSelect(m)}
            >
              <ListItemIcon sx={{ background: 'none'}}>
                <Box className='bgic' sx={{ marginRight: '5px', borderRadius: '4px', display: 'flex', alignItems: 'center', padding: '2px', '& svg': { width: '16px', height: '16px', padding: '0px !important' } }}>
                  <Icon icon={iconType} />
                </Box>
              </ListItemIcon>
              <ListItemText primary={m?.label} />
            </ListItemButton>
          );
        })}
      </List>
    </Box>
  );
};

ColumnsList.defaultProps = {};

export default ColumnsList;
