import React from "react";
import { useDataView } from "../../Providers/DataViewProvider";
import Select from "./Components/Select";

const SelectType = (props) => {
  const { rowIndex, property, value, options, readOnly } = props;
  const { onChange } = useDataView();
  const coloredValues = [...(value || [])]?.map((m) => {
    return {
      ...m,
      color: options?.find((f) => f.value === m.value)?.color,
    };
  });
  const handleChange = (data) => {
    onChange(rowIndex, {
      [property]: data?.filter((f) => f?.value),
    });
  };

  return (
    <Select
      value={coloredValues}
      onChange={handleChange}
      options={options}
      multiple={false}
      disabled={readOnly}
    />
  );
};

export default SelectType;
