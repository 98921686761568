const usePopupStyles = (theme) => ({
  root: {
    position: "absolute",
    zIndex: 1300,
    borderRadius: "10px",
    boxShadow: "0px 4px 10px 0px #00000029",
    overflow: "hidden",
    // padding: "8px 0px !important",
    background: `${theme?.palette?.editor?.miniToolBarBackground} !important`,
    "& .MuiPaper-root": {
      background: `${theme?.palette?.editor?.miniToolBarBackground} !important`,
      "& .MuiBox-root": {
        "& .renderComp": {
          padding: "2px 0px 2px 1px",
          background: "transparent !important",
          "&:hover": {
            borderRadius: "10px",
            background: `${theme?.palette?.editor?.menuOptionHoverBackground} !important`,
          },
        },
        "& button": {
          padding: "0px 0px 0px 4px !important",
          "& svg": {
            width: "21px !important",
            margin: "5px 12px 6px 4px !important",
          },
          "&:hover": {
            borderRadius: "10px",
            background: `${theme?.palette?.editor?.menuOptionHoverBackground} !important`,
          },
        },
        "&:hover": {
          "& .MuiPaper-root": {
            background: `${theme?.palette?.editor?.menuOptionHoverBackground} !important`,
            borderRadius: "7px",
          },
        },
      },
    },
  },
  papper: {
    boxShadow: "none",
    width: "300px",
    maxHeight: "300px",
    overflow: "auto",
    padding: "8px",
    margin: "0px",
    borderRadius: "10px",
    backgroundColor: theme?.palette?.editor?.background,
    "& .MuiTypography-root": {
      color: theme?.palette?.editor?.textColor,
    },
    "& button": {
      color: theme?.palette?.editor?.textColor,
    },
    "@media only screen and (min-width: 899px)": {
      maxHeight: "400px",
    },
    "&  .orderedListIcon, .bulletedListTextIcon, .checkedListTextIcon, .accordianIconSvgTextFormat":
      {
        width: "20px !important",
      },
    "&::-webkit-scrollbar-thumb": {
      background: `none !important`,
    },
    "&::-webkit-scrollbar-track": {
      visibility: "hidden",
    },
    "&::-webkit-scrollbar-thumb": {
      background: `${theme?.palette?.editor?.brainPopupScroll} !important`,
    },
    "&::-webkit-scrollbar-track": {
      visibility: "hidden",
    },
    "& svg": {
      width: "20px",
      height: "20px",
    },
    "& .signatureElementIcon": {
      "& path": {
        fill: `${theme?.palette?.editor?.closeButtonSvgStroke}`,
      },
    },
    "& .commonSvgStyle": {
      "& path": {
        stroke: `${theme?.palette?.editor?.closeButtonSvgStroke}`,
      },
    },
    "& .commonSvgStyle2": {
      "& path": {
        stroke: `${theme?.palette?.editor?.closeButtonSvgStroke}`,
      },
    },
    "& .colorBoxElementIcon": {
      "& path": {
        stroke: `${theme?.palette?.editor?.closeButtonSvgStroke}`,
        fill: "none",
      },
    },
    "& .gridElementIcon": {
      "& path": {
        stroke: `${theme?.palette?.editor?.closeButtonSvgStroke}`,
      },
    },
    "& .newLineElementIcon": {
      "& path": {
        fill: `${theme?.palette?.editor?.closeButtonSvgStroke}`,
      },
    },
    "& .orderedListIcon": {
      "& .strokePathList": {
        stroke: theme?.palette?.editor?.closeButtonSvgStroke,
      },
      "& .fillPathList": {
        fill: theme?.palette?.editor?.closeButtonSvgStroke,
      },
    },
    "& .bulletedListTextIcon": {
      "& path": {
        fill: theme?.palette?.editor?.closeButtonSvgStroke,
        stroke: theme?.palette?.editor?.closeButtonSvgStroke,
      },
      "& circle": {
        fill: theme?.palette?.editor?.closeButtonSvgStroke,
      },
    },
    "& .checkedListTextIcon": {
      "& path": {
        stroke: theme?.palette?.editor?.closeButtonSvgStroke,
      },
    },
    "& .accordianIconSvgTextFormat": {
      "& path": {
        stroke: theme?.palette?.editor?.closeButtonSvgStroke,
      },
    },
    "& .blockQuoteSvg": {
      fill: theme?.palette?.editor?.closeButtonSvgStroke,
      stroke: theme?.palette?.editor?.closeButtonSvgStroke,
    },
    "& .BrainIcon": {
      "& .bainIconPathStroke": {
        fill: theme?.palette?.editor?.closeButtonSvgStroke,
        stroke: "unset !important",
      },
      "& .bainIconPathStroke2": {
        stroke: theme?.palette?.editor?.closeButtonSvgStroke,
        fill: theme?.palette?.editor?.closeButtonSvgStroke,
      },
    },
    "& .calederIconSvg":{
      '& rect':{
        stroke: theme?.palette?.editor?.closeButtonSvgStroke,
      },
      '& .strokePath':{
        stroke: theme?.palette?.editor?.closeButtonSvgStroke,
      },
      '& .fillPath':{
        fill: theme?.palette?.editor?.closeButtonSvgStroke,
      }
    }
  },
  groupHeader: {
    padding: "10px 8px",
    marginBottom: "10px",
    fontSize: "14px",
    fontWeight: "bold",
    borderBottom: `1px solid ${theme?.palette?.editor?.deviderBgColor}`,
  },
  listItem: {
    cursor: "pointer",
    background: "transparent",
    borderRadius: "10px",
    border: `1px solid transparent`,
    "& .MuiTypography-root": {
      color: theme?.palette?.editor?.textColor,
    },
    "&.active": {
      background: `#2563EB2B`,

      "& .MuiTypography-root": {
        color: `${theme?.palette?.editor?.activeColor} !important`,
      },
    },
    "&:hover": {
      background: theme?.palette?.action?.hover || `#F4F4F4`,
    },
    "&.renderComp": {
      padding: "0px",
      "& button.cmd": {
        // to invalidate click event of toolbar icon
        // will trigger by forard ref
        pointerEvents: "none",
        width: "100%",
        padding: "4px",
        borderRadius: "0px",
        justifyContent: "start",
        "& svg": {
          margin: "5.5px",
          width: "24px",
          height: "24px",
        },
        span: {
          padding: "8px",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
    },
  },
  cardMedia: {
    width: 24,
    height: 24,
    margin: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#64748B",
  },
});

export default usePopupStyles;
