const useDataViewStyles = (theme, appTheme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    marginTop: "8px",
    "& table": {
      width: "100%",
      "& th.fe-tv-ap-ico": {
        width: "50px",
      },
    },
    "& table th": {
      cursor: "pointer",
      width: "200px",
    },
    "& table, th, td": {
      border: "1px solid black",
      borderCollapse: "collapse",
    },
    "& .react-datepicker-wrapper": {
      width: "100%",
      "& input": {
        padding: "6px 12px",
      },
    },
    "& .MuiInputBase-root": {
      "& fieldset": {
        border: "none",
      },
    },
    // filter view
    "& .fe-tv-fv": {
      marginBottom: "8px",
      "& .mr": {
        marginRight: "4px",
      },
      [theme?.breakpoints?.between("xs", "md")]: {
        display: "flex",
        flexDirection: "column",
      },
    },
    "& .tv-act-row": {
      "& .tv-tr-pop": {
        opacity: 0,
        "&.active": {
          opacity: 1,
        },
        "& .tv-ck-box": {
          "& svg": {
            color: appTheme?.palette?.editor?.tv_border,
            '& rect': {
              fill: appTheme?.palette?.editor?.tv_chk_box_fill,
              stroke: appTheme?.palette?.editor?.tv_chk_box_stroke,
            }
          },
          "&.Mui-checked": {
            "& svg": {
              color: "rgba(37, 99, 235, 1)",
              '& rect': {
                fill: appTheme?.palette?.editor?.activeColor,
                stroke: 'none',
              }
            },
          },
        },
      },
      "&:hover": {
        "& .tv-tr-pop": {
          opacity: 1,
        },
      },
    },
  },
  filterView: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiButtonBase-root": {
      padding: "6px",
      marginLeft: "4px",
      width: "29px",
      height: "29px",
      "&.active": {
        color: "rgba(37, 99, 235, 1)",
      },
    },
    "& .tv-sb": {
      display: "flex",
      border: "1px solid transparent",
      width: "24px",
      transition: "width 1s",
      "&.open": {
        width: "fit-content",
        border: `1px solid ${appTheme?.palette?.editor?.tv_border}`,
        boxShadow: "0px 4px 18px 0px #0000000D",
        borderRadius: "8px",
        minWidth: '148px'
      },
      "& input": {
        paddingBottom: "0px",
        fontSize: "14px",
      },
    },
    "& .tv-title-wrpr, .tv-fi-wrpr": {
      display: "flex",
      "& button": {
        color: appTheme?.palette?.editor?.tv_text,
      },
      "& input": {
        color: appTheme?.palette?.editor?.tv_text,
      },
    },
    "& .tv-title-wrpr": {
      width: "80%",
      marginRight: "8px",
      "& .MuiInputBase-root": {
        width: "100%",
      },
      "& .MuiInputBase-input": {
        paddingBottom: "0px",
        fontWeight: "bold",
        fontSize: "16px",
        color: appTheme?.palette?.editor?.textFormatTextColor,
      },
      [theme?.breakpoints?.between("xs", "md")]: {
        width: "100%",
        marginRight: "0px",
      },
    },
    "& .tv-fi-wrpr": {
      [theme?.breakpoints?.between("xs", "md")]: {
        width: "100%",
        justifyContent: "end",
      },
    },
  },
  basicMenu: {
    "& .MuiPaper-root": {
      padding: "0px 6px",
      width: "150px",
      border: `1px solid ${appTheme?.palette?.editor?.tv_border}`,
      borderRadius: "8px",
      fontFamily: 'Inter !important',
      background: appTheme?.palette?.editor?.tv_pop_bg,
      color: appTheme?.palette?.editor?.tv_text_primary,
      "& .MuiButtonBase-root": {
        fontSize: "14px",
        padding: '6px 8px',
        "& svg": {
          width: "16px",
          height: "16px",
          color: `${appTheme?.palette?.editor?.tv_text}`,
        },
        "&:hover": {
          background: appTheme?.palette?.editor?.tv_hover_bg,
          borderRadius: "8px",
          color: `${appTheme?.palette?.editor?.tv_hover_text} !important`,
          "& svg": {
            color: `${appTheme?.palette?.editor?.tv_hover_text} !important`,
            '& path': {
              stroke: `${appTheme?.palette?.editor?.tv_hover_text} !important`,
            }
          },
        },
      },
    },
  },
});

export default useDataViewStyles;
