import React from "react";
import ToolbarIcon from "../../common/ToolbarIcon";
import Icon from "../../common/Icon";
import { insertDataView } from "../../utils/dataView";

const DataViewButton = (props) => {
  const { editor, icoBtnType } = props;
  const onClick = () => {
    insertDataView(editor);
  };
  return (
    <ToolbarIcon
      title="Dynamic Table"
      onClick={onClick}
      icon={<Icon icon="dataTable" />}
      icoBtnType={icoBtnType}
    />
  );
};

export default DataViewButton;
