const useCompStyles = (theme, appTheme) => ({
  simpleselect: {
    border: `1px solid ${appTheme?.palette?.editor?.tv_border}`,
    background: appTheme?.palette?.editor?.tv_pop_bg,
    color: appTheme?.palette?.editor?.tv_text,
    fontSize: '14px',
    borderRadius: "8px",
    [theme?.breakpoints?.between("xs", "md")]: {},
    "& ul": {
      padding: "4px",
      "& li": {
        padding: "4px 6px",
        borderRadius: "8px",
        color: appTheme?.palette?.editor?.tv_text_primary,
        fontSize: "14px",
        marginTop: "4px",
        "&.Mui-selected": {
          background: appTheme?.palette?.editor?.tv_hover_bg,
          color: appTheme?.palette?.editor?.tv_hover_text,
          "&:hover": {
            background: appTheme?.palette?.editor?.tv_hover_bg,
            color: appTheme?.palette?.editor?.tv_hover_text,
          },
        },
        "&:hover": {
          background: appTheme?.palette?.editor?.tv_hover_bg,
          color: appTheme?.palette?.editor?.tv_hover_text,
        },
      },
    },
  },
  autocomplete: {
    border: `1px solid ${appTheme?.palette?.editor?.tv_border}`,
    background: appTheme?.palette?.editor?.tv_pop_bg,
    color: appTheme?.palette?.editor?.tv_text_primary,
    borderRadius: "8px",
    "& ul": {
      padding: "4px",
      "& .MuiAutocomplete-option": {
        padding: "4px 6px",
        borderRadius: "8px",
        color: appTheme?.palette?.editor?.tv_text_primary,
        fontSize: "14px",
        "& .MuiAvatar-root": {
          width: "20px",
          height: "20px",
          marginLeft: "8px",
          fontSize: "16px",
          padding: "2px",
          textTransform: "capitalize",
        },
        "&.Mui-selected": {
          background: appTheme?.palette?.editor?.tv_hover_bg,
          color: appTheme?.palette?.editor?.tv_hover_text,
          "&:hover": {
            background: appTheme?.palette?.editor?.tv_hover_bg,
            color: appTheme?.palette?.editor?.tv_hover_text,
          },
        },
        "&:hover": {
          background: appTheme?.palette?.editor?.tv_hover_bg,
          color: appTheme?.palette?.editor?.tv_hover_text,
        },
      },
    },
  },
});

export default useCompStyles;
