import React from "react";
import { Box } from "@mui/material";
import SimpleSelect from "../../DataTypes/Components/SimpleSelect";

const ChooseField = (props) => {
  const { sort, properties, onChange } = props;

  const handleChange = (e) => {
    onChange(sort, { newKey: e?.target?.value });
  };

  return (
    <Box sx={{ width: "120px", mr: 1 }} className="tv-cf">
      <SimpleSelect
        value={sort?.key}
        options={properties}
        handleChange={handleChange}
      />
    </Box>
  );
};

export default ChooseField;
