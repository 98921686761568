import { Transforms } from "slate";
import insertNewLine from "./insertNewLine";

const getDefaultDatView = () => ({
  type: "dataView",
  title: "",
  layouts: [
    {
      key: "view1",
      type: "table",
      label: "Table 1 View",
      value: "table1",
      filter: [],
      sort: [],
    },
  ],
  properties: [
    {
      key: "column1",
      label: "Task",
      type: "text",
      visible: true,
      default: true,
    },
  ],
  rows: [
    {
      id: new Date().getTime(),
      column1: "",
    },
  ],
  children: [{ type: "viewData", children: [{ text: "" }] }],
});

export const insertDataView = (editor) => {
  try {
    Transforms.insertNodes(
      editor,
      { ...getDefaultDatView() },
      { at: editor?.selection.focus.path }
    );
    insertNewLine(editor);
  } catch (err) {
    console.log(err);
  }
};
