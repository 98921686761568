import React from "react";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropertyList from "./PropertyList";

const AddProperty = (props) => {
  const { classes, onClose, onEvent } = props;

  const onSelect = (type) => () => {
    onEvent("addProperty", type);
  };

  return (
    <Box sx={classes.addProperty}>
      <Box className={"fe-dv-ap-title"}>
        Add Property
        <IconButton
          className="tv-act-ico bg br1"
          size="small"
          onClick={onClose}
          sx={{ '& svg': { '& path': { strokeWidth: 0 } } }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {/* <Box className={"fe-dv-ap-desc"}>Select Property Type</Box> */}
      <Box className={"fe-dv-ap-opt-content mt"}>
        <PropertyList onSelect={onSelect} />
      </Box>
    </Box>
  );
};

AddProperty.defaultProps = {
  onEvent: () => {},
};

export default AddProperty;
