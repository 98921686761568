export const radiusStyle = {
  topLeft: {
    position: "absolute",
    left: "-25px",
    top: "-28px",
    width: "30px",
    height: "30px",
    margin: "auto",
    borderTop: `2px solid #2563eb`,
    borderLeft: `2px solid #2563eb`,
    borderRight: `none`,
    borderBottom: `none`,
    fontSize: "14px",
    textAlign: "center",
  },
  topRight: {
    position: "absolute",
    top: "-28px",
    right: "-25px",
    width: "30px",
    height: "30px",
    borderTop: `2px solid #2563eb`,
    borderRight: `2px solid #2563eb`,
    borderBottom: `none`,
    borderLeft: `none`,
    fontSize: "14px",
  },
  bottomLeft: {
    position: "absolute",
    left: "-25px",
    bottom: "-28px",
    width: "30px",
    height: "30px",
    borderBottom: `2px solid #2563eb`,
    borderLeft: `2px solid #2563eb`,
    borderRight: `none`,
    borderTop: `none`,
    fontSize: "14px",
  },
  bottomRight: {
    position: "absolute",
    bottom: "-28px",
    right: "-25px",
    width: "30px",
    height: "30px",
    borderBottom: `2px solid #2563eb`,
    borderRight: `2px solid #2563eb`,
    borderTop: "none",
    borderLeft: "none",
    fontSize: "14px",
  },
};

export const squreStyle = {
  topLeft: {
    position: "absolute",
    left: "-37px",
    top: "50%",
    transform: "translateY(-50%)",
    width: "30px",
    height: "30px",
    margin: "auto",
    // borderTop: `2px solid #2563eb`,
    // borderLeft: `2px solid #2563eb`,
    // border: `1px solid transparent`,
    // borderBottom: `none`,
    fontSize: "14px",
    textAlign: "center",
  },
  topRight: {
    position: "absolute",
    // top: "-28px",
    left: "50%",
    transform: "translateX(-50%)",
    top: "-37px",
    width: "30px",
    height: "30px",
    // borderTop: `2px solid #2563eb`,
    // borderRight: `2px solid #2563eb`,
    // border: `none`,
    // borderLeft: `none`,
    fontSize: "14px",
  },
  bottomLeft: {
    position: "absolute",
    // left: "-25px",
    top: "50%",
    transform: "translateY(-50%)",
    right: "-37px",
    width: "30px",
    height: "30px",
    // borderBottom: `2px solid #2563eb`,
    // borderLeft: `2px solid #2563eb`,
    // border: `none`,
    // borderTop: `none`,
    fontSize: "14px",
  },
  bottomRight: {
    position: "absolute",
    bottom: "-37px",
    // right: "-25px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "30px",
    height: "30px",
    // borderBottom: `2px solid #2563eb`,
    // borderRight: `2px solid #2563eb`,
    // border: "none",
    // borderLeft: "none",
    fontSize: "14px",
  },
};
