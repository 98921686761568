import React, { useState } from "react";
import { Grid, IconButton, TextField, Tooltip } from "@mui/material";
import MUIIcon from "./loadIcon";
import MUIFilledIcons from "./mui_filled_icons";

const Icons = (props) => {
  const { value, data, onChange, customProps } = props;
  const { key } = data;
  const [val, setVal] = useState("");
  const [list, setList] = useState(MUIFilledIcons.slice(0, 90));

  const onSelectIcon = (ico) => () => {
    onChange({
      [key]: ico,
    });
  };

  const handleChange = (e) => {
    const keyVal = e.target.value?.toLowerCase();
    setVal(keyVal);
    if (keyVal) {
      setList(
        MUIFilledIcons.filter((f) => {
          return f.toLowerCase().indexOf(keyVal) > -1;
        }).slice(0, 90)
      );
    } else {
      setList(MUIFilledIcons.slice(0, 90));
    }
  };

  const onRemoveIcon = () => {
    onChange({
      [key]: null,
    });
  };

  const SelectedIcon = value ? value : null;

  return (
    <>
      <Grid item xs={12}>
        <Grid container style={{ display: "flex", alignItems: "center" }}>
          <Grid
            item
            xs={SelectedIcon ? 10 : 12}
            style={{ paddingRight: "5px" }}
          >
            <TextField
              fullWidth
              size="small"
              placeholder="Search Icons..."
              value={val}
              onChange={handleChange}
            />
          </Grid>
          {SelectedIcon && (
            <Grid
              item
              xs={2}
              className="selctedBtnIcon"
              style={{ padding: "4px" }}
            >
              {SelectedIcon ? (
                <Tooltip title="Click to Remove" arrow>
                  <IconButton onClick={onRemoveIcon}>
                    <MUIIcon iconName={value} props={customProps}/>
                  </IconButton>
                </Tooltip>
              ) : (
                ""
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        textAlign={"center"}
        style={{ maxHeight: "200px", overflowY: "auto", paddingTop: "5px" }}
        className="muiIconsListParent"
      >
        {list.map((m) => {
          return (
            <Tooltip key={`mui_ico_${m}`} title={m} arrow>
              <IconButton onClick={onSelectIcon(m)} className="muiIconsList">
                <MUIIcon iconName={m} props={customProps}/>
              </IconButton>
            </Tooltip>
          );
        })}
      </Grid>
    </>
  );
};

export default Icons;
