const SortByIcon = () => {
    return(
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.96781 4.47998L4.48779 2L2.00781 4.47998" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.48828 14V2" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.03516 11.52L11.5152 14L13.9952 11.52" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.5156 2V14" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default SortByIcon