import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  IconButton,
  TextField,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  FormControlLabel,
  Divider,
} from "@mui/material";
import { TYPE_ICONS, TYPE_LABELS } from "./Constants";
import AddOptions from "./AddOptions";
import FilterProperty from "./FilterProperty";
import Icon from "../../../../common/Icon";
import CloseIcon from "@mui/icons-material/Close";

const EditProperty = (props) => {
  const { classes, onClose, mode, onEvent } = props;
  const [edit, setEdit] = useState({ ...(mode?.edit || {}) });
  const editData = useRef({ ...edit });

  useEffect(() => {
    return () => {
      // on un-mount update the label
      if (edit?.label !== editData?.current?.label) {
        onEvent("updateProperty", { ...editData?.current }, false);
      }
    };
  }, []);

  const onChange = (key) => (e) => {
    const latest = {
      ...edit,
      [key]: e?.target?.value,
    };
    setEdit({
      ...edit,
      [key]: e?.target?.value,
    });
    editData.current = { ...latest };
  };

  const onChangeProperty = () => {
    onEvent("changeProperty", { edit: { ...edit } });
  };

  const onBack = () => {
    onEvent("allProperties", { edit: null });
  };

  const onUpdate = (data) => {
    const latest = {
      ...edit,
      ...data,
    };
    setEdit({
      ...edit,
      ...data,
    });
    editData.current = { ...latest };
    onEvent("updateProperty", { ...editData?.current });
  };

  const onAction = (action) => () => {
    switch (action) {
      case "Visibilty":
        onUpdate({ visible: !edit?.visible });
        onEvent("close");
        break;
      case "Duplicate":
        onEvent(
          "addProperty",
          {
            type: editData?.current?.type,
            overrides: {
              ...editData?.current,
            },
          },
          false
        );
        onEvent("close");
        break;
      case "Delete":
        onEvent("deleteProperty", {
          ...editData?.current,
        });
        onEvent("close");
        break;
      default:
        return;
    }
  };

  return (
    <Box sx={classes.addProperty}>
      <Box className={"fe-dv-ap-title"}>
        <span>
          <IconButton className="tv-act-ico" size="small" onClick={onBack} sx={{ paddingLeft: '0px', '&:hover': { background: 'transparent !important' }, '& svg': { width: '14px !important', height: '14px !important' } }}>
            <Icon icon={'leftArrow'} />
          </IconButton>
          Edit Property
        </span>
        <IconButton
          className="tv-act-ico bg br1"
          size="small"
          onClick={onClose}
          sx={{ '& svg': { '& path': { strokeWidth: 0 } } }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className={"fe-dv-ap-opt-content"}>
        <FormControlLabel
          label="Field Name"
          labelPlacement="top"
          sx={{
            '& .MuiFormControl-root': {
              marginBottom: '4px'
            }
          }}
          control={
            <TextField
              size="small"
              value={edit?.label}
              onChange={onChange("label")}
              fullWidth
              placeholder="Field Name"
            />
          }
        />
        <List className="fe-dv-opt-list st">
          <ListItemButton sx={{ paddingBottom: '12px' }} onClick={onChangeProperty}>
            <ListItemText>Type</ListItemText>
            <ListItemIcon sx={{ display: 'flex', alignItems: "center", gap: 1 }}>
              <Box sx={{ background: "#64748B1F", borderRadius: '4px', display: 'flex', alignItems: 'center', padding: '2px', '& svg': { width: '16px', height: '16px' } }}> 
                <Icon icon={`${TYPE_ICONS[edit?.type]}`} />
              </Box>
              <span className="label-tp">{TYPE_LABELS[edit?.type]}</span>
              <Icon icon={'rightArrow'} />
            </ListItemIcon>
          </ListItemButton>
        </List>
        <Divider className="divider_sty" />
        <AddOptions edit={edit} onUpdate={onUpdate} onEvent={onEvent} />
        <FilterProperty edit={edit} onUpdate={onUpdate} onEvent={onEvent} />
        <List className="fe-dv-opt-list">
          <ListItemButton onClick={onAction("Visibilty")}>
            <ListItemIcon>
              {edit?.visible ? <Icon icon={'eyeSlash'} /> : <Icon icon={'eyeIcon'} />}
            </ListItemIcon>
            <ListItemText>
              {edit?.visible ? "Hide" : "Show"} in View
            </ListItemText>
          </ListItemButton>
          <ListItemButton onClick={onAction("Duplicate")}>
            <ListItemIcon>
              <Icon icon={'duplicateIcon'} />
            </ListItemIcon>
            <ListItemText>Duplicate Property</ListItemText>
          </ListItemButton>
          <ListItemButton onClick={onAction("Delete")}>
            <ListItemIcon>
              <Icon icon={'trashIcon'} />
            </ListItemIcon>
            <ListItemText>Delete Property</ListItemText>
          </ListItemButton>
        </List>
      </Box>
    </Box>
  );
};

EditProperty.defaultProps = {
  onEvent: () => {},
};

export default EditProperty;
