import React from "react";
import TableView from "./TableView";
import { useDataView } from "../Providers/DataViewProvider";

const LAYOUT_COMPONENT = {
  table: TableView,
};

const LayoutView = (props) => {
  const { readOnly, children } = props;
  const { layoutType } = useDataView();
  const Layout = LAYOUT_COMPONENT[layoutType] || LAYOUT_COMPONENT["table"];

  return (
    <>
      <Layout readOnly={readOnly}>{children}</Layout>
    </>
  );
};

export default LayoutView;
