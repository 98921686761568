import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Grid,
  TextField,
  Typography,
  Tabs,
  Tab,
  InputAdornment,
  Box,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SignatureOptions from "./SignatureOptions";
import "./Signature.css";
import {
  DrawSignature,
  PencilIcon,
  TypeSignature,
  UploadSignature,
} from "../../common/EditorIcons";
import useCommonStyle from "../../commonStyle";
import { useEditorContext } from "../../hooks/useMouseMove";
import { validationMethods } from "../Form/FormElements/validations";
import {
  CalenderDownIconSignature,
  CalenderIconSignature,
} from "../../common/iconListV2";
import ColorPickerButton from "../../common/ColorPickerButton";
import { SignaturePlaceholderIcon } from "../../common/iconslist";

const BrushSizes = [1, 5, 7];

const SignaturePopup = (props) => {
  const { theme } = useEditorContext();
  const classes = useCommonStyle(theme);
  const { onSave, onClear, customProps, className, onlyPopup } = props;
  const { readOnly, metadata, isSignerDetailsRequired = false } = customProps;
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(0);
  const SeletectedTab = SignatureOptions[tab];
  const defaultName = metadata?.signatureDetails?.name;
  const defaultEmail = metadata?.signatureDetails?.email;
  const [signedData, setSignedData] = useState({
    signedOn: new Date(),
    signature: "",
    signedText: "",
    signedBy: defaultName || "",
    signedByEmail: defaultEmail || "",
  });
  const [brush, setBrush] = useState({
    size: 1,
    color: "#000000",
  });
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    if (props.handleClose) {
      props.handleClose();
    }
  };

  const handleSave = async () => {
    await customProps?.services("workFlowAction", {
      resource_id: customProps?.page_id,
    });
    let isValidEmail = "";
    if (isSignerDetailsRequired && signedData?.signedByEmail !== "") {
      isValidEmail = validationMethods?.isEmail(signedData?.signedByEmail);
    }

    if (isValidEmail === "Enter valid email address") {
      alert(isValidEmail);
    } else {
      onSave(signedData);
      // manual delay
      setTimeout(() => {
        if (metadata && metadata?.actionHandler) {
          metadata?.actionHandler("signed", signedData);
        }
        handleClose();
      }, 0);
    }
  };

  const handleClear = () => {
    onClear();
    handleClose();
  };

  const onDataChange = (data) => {
    setSignedData({
      ...signedData,
      ...data,
    });
  };

  const onChange = (e) => {
    e?.stopPropagation();
    setSignedData({
      ...signedData,
      [e.target.name]: e.target.value,
    });
  };

  const onBrushSize = (val) => () => {
    setBrush({
      ...brush,
      size: val,
    });
  };

  const onBrushColor = (color) => {
    setBrush({
      ...brush,
      color,
    });
  };

  const onUploadDone = (data) => {
    setSignedData({
      ...signedData,
      ...data,
    });
  };

  const onTabChange = (e, newValue) => {
    e?.stopPropagation();
    setTab(newValue);
    setSignedData({
      signedOn: new Date(),
      signature: "",
      signedText: "",
      signedBy: "",
      signedByEmail: "",
    });
  };
  let isEmpty = signedData?.signature === "" && signedData?.signedText === "";
  if (isSignerDetailsRequired) {
    isEmpty =
      (signedData?.signature === "" && signedData?.signedText === "") ||
      signedData?.signedBy === "" ||
      signedData?.signedByEmail === "";
  }

  return (
    <>
      {onlyPopup ? null : (
        <div className={`signature-btn-container`} contentEditable={false}>
          <Box className="sign-placeholder">
            <SignaturePlaceholderIcon />

            <Typography variant="body2" style={{ color: "#A2B0B9" }}>
              Your sign will be placed here
            </Typography>
          </Box>

          <Button onClick={handleOpen} style={{ width: "fit-content" }}>
            Sign here
          </Button>
        </div>
      )}

      <Dialog
        className={`${className} dialogComp`}
        open={onlyPopup || open}
        onClose={handleClose}
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "8px",
            boxShadow: "0px 4px 10px 0px #00000029",
            maxWidth: "530px",
          },
        }}
        sx={classes.signaturePopup}
      >
        <Grid container>
          <Grid item xs={12} sx={{ p: 3 }}>
            <DialogTitle sx={{ p: 0, pb: 2 }}>
              <Grid container justifyContent={"space-between"}>
                <Grid item>
                  <Typography
                    variant="h6"
                    color={"primary"}
                    sx={classes.popupTitle2}
                  >
                    Signature
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container>
                    {customProps?.customComponents?.signature?.header?.map(
                      (elem, index) => (
                        <Grid key={index} sx={{ pr: 1 }}>
                          {elem}
                        </Grid>
                      )
                    )}
                    <Grid item>
                      <IconButton
                        onClick={handleClose}
                        className="close-popupbtn"
                      >
                        <CloseRoundedIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent sx={{ p: 0 }} className="af-signature-popup">
              <div className="signature-btn-grps">
                <Tabs
                  scrollButtons="auto"
                  variant="scrollable"
                  value={tab}
                  onChange={(e, newValue) => {
                    onTabChange(e, newValue);
                  }}
                  aria-label="Element Tabs"
                >
                  <Tab
                    label={
                      <Grid className="dflex alignCenter">
                        <DrawSignature
                          fill={tab === 0 ? "#2563EB" : "#A2B0B9"}
                        />
                        <span
                          className="ml-1"
                          style={{ textTransform: "none" }}
                        >
                          Draw Signature
                        </span>
                      </Grid>
                    }
                  />
                  <Tab
                    label={
                      <Grid className="dflex alignCenter">
                        <TypeSignature
                          fill={tab === 1 ? "#2563EB" : "#A2B0B9"}
                        />
                        <span
                          className="ml-1"
                          style={{ textTransform: "none" }}
                        >
                          Type Signature
                        </span>
                      </Grid>
                    }
                  />
                  <Tab
                    label={
                      <Grid className="dflex alignCenter">
                        <UploadSignature
                          fill={tab === 2 ? "#2563EB" : "#A2B0B9"}
                        />
                        <span
                          className="ml-1"
                          style={{ textTransform: "none" }}
                        >
                          Upload Signature
                        </span>
                      </Grid>
                    }
                  />
                </Tabs>
              </div>
              <div className="signature-tab-content">
                {SeletectedTab ? (
                  <SeletectedTab
                    onDataChange={onDataChange}
                    customProps={{ ...(customProps || {}), brush: brush }}
                    onUploadDone={onUploadDone}
                  />
                ) : null}
              </div>
              <Grid
                container
                sx={{ pt: 2 }}
                spacing={2}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                    fontFamily: '"Inter", sans-serif"',
                  }}
                >
                  <Grid sx={{ minWidth: "49px" }}>
                    <label
                      htmlFor="signedBy"
                      style={{
                        fontFamily: '"Inter", sans-serif',
                        fontWeight: 500,
                        fontSize: "14px",
                      }}
                    >
                      Name:
                    </label>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="signedBy"
                      name="signedBy"
                      placeholder="Enter Name"
                      value={signedData.signedBy || ""}
                      // defaultValue={defaultName || ""}
                      size="small"
                      onChange={onChange}
                      sx={classes.signaturePopUpNameField}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Grid sx={{ minWidth: "46px" }}>
                    <label
                      htmlFor="signedOn"
                      style={{
                        fontFamily: '"Inter", sans-serif',
                        fontWeight: 500,
                        fontSize: "14px",
                      }}
                    >
                      Date:
                    </label>
                  </Grid>
                  <Grid
                    item
                    onClick={(e) => {
                      e?.stopPropagation();
                    }}
                    xs={12}
                    sx={classes.datePicker}
                  >
                    <DatePicker
                      selected={signedData.signedOn}
                      onChange={(date) => {
                        setSignedData({ ...signedData, signedOn: date });
                      }}
                      dateFormat="yyyy/MM/dd"
                      customInput={
                        <TextField
                          fullWidth
                          name="signedOn"
                          placeholder="Select Date"
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{
                                  padding: 0, // Remove padding around the InputAdornment
                                  margin: 0, // Remove any margins
                                }}
                              >
                                <IconButton>
                                  <CalenderIconSignature />
                                </IconButton>
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  <CalenderDownIconSignature />
                                </IconButton>
                              </InputAdornment>
                            ),
                            sx: {
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "transparent", // Remove the internal border
                              },
                              "& .MuiInputBase-input": {
                                border: "none", // Ensure no border inside the input field
                                padding: "4px 0", // Adjust padding for consistency
                                boxShadow: "none !important",
                                color: "#778599 !important",
                              },
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                              backgroundColor: "#fff",
                              padding: "0px !important",
                              "&:hover fieldset": {
                                borderColor: "#D8DDE1", // Keeps the border on hover only around the outer field
                              },
                              "& fieldset": {
                                borderColor: "#D8DDE1", // Keeps the outer border light
                              },
                            },
                          }}
                        />
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  style={{ display: "flex", alignItems: "center" }}
                  xs={12}
                >
                  <Grid sx={{ minWidth: "49px" }}>
                    <label
                      htmlFor="signedByEmail"
                      style={{
                        fontFamily: '"Inter", sans-serif',
                        fontWeight: 500,
                        fontSize: "14px",
                      }}
                    >
                      Email:
                    </label>
                  </Grid>
                  <Grid container item>
                    <TextField
                      fullWidth
                      id="signedByEmail"
                      name="signedByEmail"
                      placeholder="Enter Email"
                      size="small"
                      onChange={onChange}
                      sx={classes.signaturePopUpNameField}
                      value={signedData.signedByEmail || ""}
                      // defaultValue={defaultEmail || ""}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className="dialog-actions-si" sx={{ p: 0, pt: 2 }}>
              {tab === 0 ? (
                <Grid container alignItems={"center"}>
                  <Grid item sx={{ mr: 3 }}>
                    <Grid
                      container
                      alignItems="center"
                      sx={{ display: "flex" }}
                    >
                      {/* Color Picker Input */}
                      <ColorPickerButton
                        classes={classes}
                        value={brush.color}
                        onSave={onBrushColor}
                        recentColors={[]}
                        hideGradient={true}
                      />
                      {/* Display the selected hex code */}
                      <span
                        style={{
                          marginLeft: "10px",
                          fontFamily: '"Inter", sans-serif',
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                        className="brush-color"
                      >
                        {brush.color}
                      </span>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid className="dflex alignCenter sizeIcons">
                      {BrushSizes.map((m) => {
                        return (
                          <IconButton
                            onClick={onBrushSize(m)}
                            key={`sit-bs-${m}`}
                            disableRipple
                            className={`${
                              brush.size === m ? "activeBrush" : ""
                            } brushIcon`}
                          >
                            <span style={{ width: 17 + m, height: 17 + m }}>
                              <PencilIcon
                                style={{ width: 17 + m, height: 17 + m }}
                              />
                            </span>
                          </IconButton>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              <Box component={"div"} className="signatureActionBtnContainer">
                {!readOnly ? (
                  <Button
                    onClick={handleClear}
                    className={`secondaryBtn actionBtn deleteBtn deleteButtonSignature`}
                  >
                    Delete
                  </Button>
                ) : null}
                <Button
                  onClick={handleSave}
                  className={`primaryBtn actionBtn ${
                    isEmpty ? "disabled disabledSaveBtn" : ""
                  }`}
                  disabled={isEmpty}
                >
                  Save
                </Button>
              </Box>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

// export default SignaturePopup;
export default SignaturePopup;
