import React from "react";
import { Tooltip, IconButton, Typography } from "@mui/material";

const ToolbarIcon = (props) => {
  const { title, className, onClick, icon, style, icoBtnType } = props;
  const renderIconText = () => {
    if (icoBtnType === "mini") {
      return <span><Typography sx={{ fontSize: '14px', fontWeight: 400 }}>{title}</Typography></span>;
    } else if (icoBtnType === "cmd") {
      return <span><Typography sx={{ fontSize: '14px', fontWeight: 400}}>{title}</Typography></span>;
    }
    return null;
  };

  return (
    <Tooltip title={title} arrow>
      <IconButton
        className={`${className} ${icoBtnType} accordionIcon`}
        onClick={onClick}
        style={style}
      >
        {icon}
        {renderIconText()}
      </IconButton>
    </Tooltip>
  );
};

ToolbarIcon.defaultProps = {
  title: "",
  style: {},
  className: "",
  onClick: () => {},
};

export default ToolbarIcon;
