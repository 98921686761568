import MarkdownIt from "markdown-it";
const md = new MarkdownIt();

function markdownItCheckbox(md) {
  md.core.ruler.push("checkbox_lists", function (state) {
    const tokens = state.tokens;

    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i];

      if (token.type === "inline" && token.content) {
        const taskListRegex = /^\s*(-\s*)?\[\s*([xX\s]?)\s*\]\s+(.*)/;
        const match = taskListRegex.exec(token.content);

        if (match) {
          token.attrJoin("class", "check-list-item");
          const isChecked = match[2].toLowerCase() === "x"; // Check if checked
          const content = match[3].trim(); // Extract text after checkbox

          // Create new tokens for the task list item
          const listItemOpenToken = new state.Token("html_inline", "", 0);
          listItemOpenToken.content = `<li class="check-list-item" data-checked="${isChecked}">`;

          const checkboxToken = new state.Token("html_inline", "", 0);
          checkboxToken.content = `<span class='check-list-item' data-checked="${isChecked}" />`;

          const textToken = new state.Token("text", "", 0);
          textToken.content = content;

          const listItemCloseToken = new state.Token("html_inline", "", 0);
          listItemCloseToken.content = `</li>`;

          // Replace the original token with the new tokens
          tokens.splice(i, 1, checkboxToken, textToken);
        }
      }
    }
  });
}

// init plugin
md.use(markdownItCheckbox);

function convertMDToHTML(data) {
  try {
    const html = md.render(data);
    return html;
  } catch (err) {
    console.log(err);
    return data;
  }
}

export default convertMDToHTML;
