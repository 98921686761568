const Styles = (theme) => ({
  drawerContainer: {
    "&.MuiDrawer-root": {
      zIndex: 1301,
    },
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      backgroundColor: theme?.palette?.editor?.miniToolBarBackground,

      "& .MuiInputBase-root": {
        border: `1px solid ${theme?.palette?.editor?.textColor}`,
        color: theme?.palette?.editor?.textColor,
      },
      "& .MuiSvgIcon-root": {
        color: theme?.palette?.editor?.textColor,
      },
      "& .MuiFormLabel-root": {
        color: theme?.palette?.editor?.textColor,
        backgroundColor: theme?.palette?.editor?.background,
      },
    },
  },
  childContainer: {
    padding: "20px",
    "&.emojiDrawer": {
      padding: "unset",
      paddingTop: "10px",
    },
  },
});

export default Styles;
