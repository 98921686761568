const useColumnStyles = (theme) => ({
  root: {
    zIndex: 1000,
    marginTop: "20px !important",
    [theme?.breakpoints?.between("xs", "md")]: {
      left: "-32px !important",
    },
  },
});

export default useColumnStyles;
