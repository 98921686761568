import { toolbarGroups } from "../../Toolbar/toolbarGroups";
import { fontOptions } from "../../utils/font";
import { capitalizeFirstLetter } from "../../utils/helper";

const allTools = toolbarGroups.flat();
const fontWeight = allTools.find((f) => f.format === "fontWeight");

function getKey(prefix, key) {
  const settingKey =
    prefix === "col" ? `entire${capitalizeFirstLetter(key)}` : key;

  return `${prefix}.${settingKey}`;
}

function getCommonSettings(prefix) {
  let settings = [
    {
      label: "Font Family",
      key: "fontFamily",
      type: "textOptions",
      webFont: true,
      options: fontOptions,
      renderOption: (option) => {
        return <span style={{ fontFamily: option.value }}>{option.text}</span>;
      },
    },
    {
      label: "Font Weight",
      key: "fontWeight",
      type: "textOptions",
      options: fontWeight?.options,
      width: 7,
    },
    {
      label: "Font Size",
      key: "textSize",
      type: "fontSize",
      width: 5,
      placeholder: "16px or 1em",
    },
    {
      label: "Text Color",
      key: "textColor",
      type: "color",
    },
    {
      label: "Background",
      key: "bgColor",
      type: "color",
    },
    {
      label: "Border",
      key: "borderColor",
      type: "color",
    },
  ];

  // modify keys based on setting type (prefix)
  settings = settings.map((s) => ({ ...s, key: getKey(prefix, s.key) }));

  return settings;
}

const tableStyle = [
  {
    tab: "Table",
    value: "tableSettings",
    fields: getCommonSettings("table"),
  },
  {
    tab: "Selected cell",
    value: "columnSettings",
    fields: [
      {
        label: "Selected Cell Background",
        key: "col.bgColor",
        type: "color",
      },
      {
        label: "Selected Cell Border Color",
        key: "col.borderColor",
        type: "color",
      },
    ],
  },
  {
    tab: "Visibility",
    value: "visibility",
    fields: [
      {
        label: "Hide on Mobile",
        key: "table.xsHidden",
        type: "selectBox",
        placeholder: "Hide on Mobile",
      },
    ],
  },
];

export default tableStyle;

export const tableRowStyle = [
  {
    tab: "Row",
    value: "rowSettings",
    fields: getCommonSettings("row"),
  },
];

export const tableColumnStyle = [
  {
    tab: "Column",
    value: "columnSettings",
    fields: getCommonSettings("col"),
  },
];
