import React, { useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";

const Text = (props) => {
  const { value: pro_value, data, onChange } = props;
  const { key, placeholder, width, required = false } = data;
  const [value, setValue] = useState(pro_value);
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (required && !newValue.trim()) {
      setError(true);
    } else {
      setError(false);
    }
    onChange({ [key]: newValue });
  };

  return (
    <Grid item xs={width || 12}>
      <Typography
        variant="body1"
        color={"primary"}
        sx={{ fontSize: "14px", fontWeight: "500", marginBottom: "8px" }}
      >
        {data?.label}
      </Typography>
      <TextField
        name={key}
        placeholder={placeholder || ""}
        type="text"
        value={value}
        onChange={handleChange}
        size="small"
        fullWidth
        required={required}
        error={error}
        helperText={error ? 'This field is required' : ''}
        sx={{
          '& .MuiFormHelperText-root': {
            color: '#d32f2f !important',
          },
        }}
      />
    </Grid>
  );
};

export default Text;
