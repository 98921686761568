import React, { useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import { convertBase64 } from "../utils/helper";
import { uploadFile } from "../service/fileupload";
import Icon from "./Icon";
import UploadStyles from "../common/ImageSelector/UploadStyles";

const Uploader = (props) => {
  const { value, onUploaded, customProps, title } = props;
  const classes = UploadStyles();
  const [base64, setBase64] = useState(value?.url);
  const [fileName, setFileName] = useState("");
  const [uploading, setUploading] = useState(false);

  const handleChange = async (e) => {
    const uFile = e.target.files[0];
    const strImage = await convertBase64(uFile);
    setBase64(strImage);
    setFileName(uFile?.name);
    doUpload(strImage, e.target.files[0]);
  };

  const doUpload = async (strImage, file) => {
    setUploading(true);
    const formData = new FormData();
    formData.set("file", file);
    formData.set("resource_type", "pages");
    formData.set("resource_id", customProps?.page_id);
    const result = await uploadFile(formData, customProps);
    setUploading(false);
    if (result && result?.imageURL) {
      onUploaded(result?.imageURL[0]);
    }
  };

  const onRemoveBG = () => {
    setBase64(null);
    onUploaded("none");
  };

  const getBackground = () => {
    switch (title) {
      case "Document":
        return { backgroundImage: `url()` };
      default:
        return { backgroundImage: base64 ? `url(${base64})` : "none" };
    }
  };

  const renderThumb = () => {
    switch (title) {
      case "Document":
        return (
          <>
            <Typography
              style={{
                padding: "8px",
                fontSize: "12px",
                color: "#2563eb",
                textAlign: "center",
              }}
            >
              {fileName}
            </Typography>
            <Grid className="removeImageText" onClick={onRemoveBG}>
              REMOVE
            </Grid>
          </>
        );
      default:
        return (
          <Grid className="removeImageText" onClick={onRemoveBG}>
            REMOVE
          </Grid>
        );
    }
  };

  return (
    <>
    <Grid item xs={12}>
        {uploading ? "Uploading..." : ""}
      </Grid>
    <Grid container sx={classes.uploadContainer}>
      <Grid
        item
        xs={12}
        className="btn--wrpr"
        style={{ ...getBackground() }}
        sx={classes.uploadField}
      >
        {!uploading ? (
          <Grid className="uploadImageSection">
            {base64 ? (
              renderThumb()
            ) : (
              <Grid className="uploadImageText">
                <Button
                  component="label"
                  variant="text"
                  style={{ background: "none", width: "100%" }}
                  sx={classes.uploadIcon}
                >
                  <input
                    type="file"
                    style={{ opacity: 0, width: "0px" }}
                    onChange={handleChange}
                  />
                  <Icon icon={"fileUpload"} />
                  <span style={{ paddingLeft: "8px" }}>
                    upload {title || "image"}
                  </span>
                </Button>
              </Grid>
            )}
          </Grid>
        ) : null}
      </Grid>
    </Grid>
    </>
  );
};

export default Uploader;
