const CalenderIconTick = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.66797 1.1665V2.9165" stroke="#64748B" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.33203 1.1665V2.9165" stroke="#64748B" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2.04297 5.30273H11.9596" stroke="#64748B" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.8346 11.0833C12.8346 11.5208 12.7121 11.935 12.4963 12.285C12.0938 12.9617 11.353 13.4167 10.5013 13.4167C9.91214 13.4167 9.37547 13.2008 8.96714 12.8333C8.7863 12.6817 8.6288 12.495 8.5063 12.285C8.29047 11.935 8.16797 11.5208 8.16797 11.0833C8.16797 9.79417 9.21214 8.75 10.5013 8.75C11.2013 8.75 11.8255 9.05916 12.2513 9.54332C12.613 9.95749 12.8346 10.4942 12.8346 11.0833Z" stroke="#64748B" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.58984 11.0834L10.1673 11.6609L11.4098 10.5117" stroke="#64748B" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.25 4.95817V9.54316C11.8242 9.059 11.2 8.74984 10.5 8.74984C9.21083 8.74984 8.16667 9.794 8.16667 11.0832C8.16667 11.5207 8.28917 11.9348 8.505 12.2848C8.6275 12.4948 8.785 12.6815 8.96583 12.8332H4.66667C2.625 12.8332 1.75 11.6665 1.75 9.9165V4.95817C1.75 3.20817 2.625 2.0415 4.66667 2.0415H9.33333C11.375 2.0415 12.25 3.20817 12.25 4.95817Z" stroke="#64748B" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.99549 7.99186H7.00073" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.83924 7.99186H4.84448" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.83924 9.74186H4.84448" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
export default CalenderIconTick