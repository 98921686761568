import { Button } from "@mui/material";
import { useMemo, useState } from "react";
import useTable from "../../hooks/useTable";

const showAddButton = (addType, tableNode, hoverPath) => {
  const [row, col] = hoverPath && hoverPath?.length ? hoverPath.slice(-2) : [];

  if (addType === "row") {
    return tableNode?.rows - 1 === row;
  } else {
    return tableNode?.columns - 1 === col;
  }
};

const ADD_COL_BTN_WIDTH = 20;

function AddRowCol(props) {
  const { tableRef, containerRef, addType, tableNode, onAdd, readOnly } = props;
  const { hoverPath } = useTable();
  const [hoveringAddBtn, setHoveringAddBtn] = useState(false);
  const { tableResizing } = useTable();
  const { clientHeight, scrollWidth } = tableRef?.current || {};
  const { clientWidth: containerWidth } = containerRef?.current || {};

  const buttonStyle = useMemo(() => {
    if (addType === "row") {
      const isScrollEnabled = scrollWidth > containerWidth;
      const width = isScrollEnabled
        ? containerWidth - ADD_COL_BTN_WIDTH
        : scrollWidth;

      return { width };
    } else {
      return { height: "100%", marginLeft: "3px" };
    }
  }, [scrollWidth, clientHeight, containerWidth, tableResizing]);

  const showAdd = showAddButton(addType, tableNode, hoverPath);

  const onMouseEnter = () => setHoveringAddBtn(true);
  const onMouseLeave = () => setHoveringAddBtn(false);

  const showBtn = (showAdd || hoveringAddBtn) && !tableResizing && !readOnly;

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      contentEditable={false}
    >
      <Button
        sx={{
          fontSize: "14px",
          border: "1px dashed #8DA8E3",
          color: "#2563EB !important",
          padding: "0px 4px",
          minWidth: "unset",
          lineHeight: "18px",
          fontWeight: "normal !important",
          ...buttonStyle,
          opacity: showBtn ? 1 : 0,
        }}
        disabled={!showBtn}
        onClick={onAdd}
      >
        +
      </Button>
    </div>
  );
}

export default AddRowCol;
