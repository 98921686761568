import TextType from "./TextType";
import DateType from "./DateType";
import SelectType from "./SelectType";
import MultiSelectType from "./MultiSelectType";
import NumberType from "./NumberType";
import CheckType from "./CheckType";
import PersonType from "./PersonType";

const DataTypes = {
  text: TextType,
  date: DateType,
  select: SelectType,
  "multi-select": MultiSelectType,
  number: NumberType,
  check: CheckType,
  person: PersonType,
};

export default DataTypes;
