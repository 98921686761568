import { Editor, Node, Path, Point, Range, Transforms } from "slate";
import { containsSurrogatePair, getNextNode, } from "../utils/helper";

const BACKWARD_BLOCKS = ["list-item", "signature"];

const isNodeTextEmpty = (node) => {
  const nodeText = Node.string(node);
  return nodeText.trim() === "";
};

const withCustomDeleteBackward = (editor) => {
  try {
    const { deleteBackward, deleteForward } = editor;

    // Override deleteBackward
    editor.deleteBackward = (...args) => {
      const { selection } = editor;

      if (selection) {
        // get the current node
        const [freeGridItemNode] = Editor.nodes(editor, {
          match: (n) => n.type === "freegridItem", // Adjust based on your list item type
        });

        // if it is freegrid
        if (freeGridItemNode && freeGridItemNode[0]) {
          const hasText = Node.string(freeGridItemNode[0]);
          if (!hasText) {
            return;
          }
        }

        if (selection && Range.isCollapsed(selection)) {
          const [currentNode, path] = Editor.node(editor, selection);
          if (currentNode && typeof currentNode.text === 'string') {
            const text = currentNode.text;
            if (containsSurrogatePair(text)) {
              // Check if the cursor is at the end of a surrogate pair
              const { offset } = selection.anchor;
              const beforeCursor = text.slice(0, offset);
              const afterCursor = text.slice(offset);
              if (containsSurrogatePair(beforeCursor) || containsSurrogatePair(afterCursor)) {
                // Delete the entire surrogate pair
                Transforms.delete(editor, {
                  at: {
                    anchor: { path, offset: offset - 2 }, // Move back 2 for surrogate pair
                    focus: { path, offset },
                  },
                });
                return;
              }
            }
          }
        }

        // Check if current node is a list item and is the last one, signature node
        const [node] = Editor.nodes(editor, {
          match: (n) => BACKWARD_BLOCKS.includes(n.type), // Adjust based on your list item type
        });

        if (node) {
          // Check if it is the last list item is empty text
          const parentNodePath = Path.parent(node[1]);
          const isLastChildren = Node.get(editor, parentNodePath);
          const isEmpty =
            isNodeTextEmpty(node[0]) && isLastChildren?.children?.length === 1;
          if (isEmpty) {
            Transforms.setNodes(
              editor,
              { type: "paragraph" },
              { at: parentNodePath }
            );
            Transforms.removeNodes(editor, { at: node[1] });
            return;
          }
        }
      }

      // Fall back to default delete behavior if conditions are not met
      deleteBackward(...args);
    };

    editor.deleteForward = (...args) => {
      const { selection } = editor;
      if (selection) {
        const { nextPath, nextNode } = getNextNode(editor) || {};

        // Restrict deletion of page-settings
        if (nextNode?.type === "page-settings") {
          const isEndLine = Point.equals(
            selection.focus,
            Editor.end(editor, selection.focus.path)
          );

          if (isEndLine) {
            const lastEditorChild = editor?.children?.length;
            const isLastNode = lastEditorChild - 1 === nextPath[0];

            if (isLastNode) {
              return;
            }

            // move the page-settings node to last
            Transforms.moveNodes(editor, {
              at: nextPath,
              to: [lastEditorChild],
            });

            // Node present after page-settings, will now merged to current node
            Transforms.mergeNodes(editor, { at: nextPath });
            return;
          }
        }
      }

      deleteForward(...args);
    };

    return editor;
  } catch (err) {
    console.log(err);
  }
};

export default withCustomDeleteBackward;
