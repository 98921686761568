import React from "react";
import { MenuItem, Select, useTheme } from "@mui/material";
import { useEditorContext } from "../../../../../hooks/useMouseMove";
import useCompStyles from "./styles";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';


const SimpleSelect = (props) => {
  const theme = useTheme();
  const { theme: appTheme } = useEditorContext();
  const classes = useCompStyles(theme, appTheme);
  const { value, options, handleChange, disabled = false } = props;

  return (
    <Select
      disabled={disabled}
      value={value}
      onChange={handleChange}
      IconComponent={KeyboardArrowDownRoundedIcon}
      fullWidth
      size="small"
      sx={{
        color: appTheme?.palette?.editor?.tv_text,
        fontSize: '14px !important',
        marginTop: '4px !important',
        padding: '3px 0px !important',
        background: 'transparent !important',
        '& .MuiOutlinedInput-root':{
          height: '34px !important',
          background: 'transparent !important'
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${appTheme?.palette?.editor?.inputFieldBorder} !important`,
          boxShadow: '0px 4px 18px 0px #0000000D !important',
          background: 'transparent !important',
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${appTheme?.palette?.editor?.inputFieldBorder} !important`,
          boxShadow: '0px 4px 18px 0px #0000000D !important',
          background: 'transparent !important',
        },
      }}
      MenuProps={{
        PaperProps: {
          sx: classes.simpleselect,
          className: "tv-cf-opt-wrpr",
        },
      }}
    >
      {options?.map((m, i) => {
        return (
          <MenuItem key={i} value={m.key} className="tv-cf-opt-wrpr">
            {m.label}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default SimpleSelect;
