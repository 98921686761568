const useFilterSortStyles = (theme, appTheme) => ({
  root: {
    "& .MuiPaper-root": {
      boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.16)",
      border: `1px solid ${appTheme?.palette?.editor?.tv_border}`,
      background: appTheme?.palette?.editor?.tv_pop_bg,
      color: appTheme?.palette?.editor?.tv_text_primary,
      borderRadius: "20px !important",
      fontFamily: 'Inter !important',
      [theme?.breakpoints?.between("xs", "md")]: {
        borderRadius: "16px 16px 0px 0px",
        maxHeight: "50%",
      },
      "& .MuiSelect-select": {
        padding: "4px 8px",
      },
      "& .ml": {
        marginLeft: "8px",
      },
      "& .tv-act-ico": {
        color: appTheme?.palette?.editor?.tv_text,
        "&.br1": {
          borderRadius: "6px",
        },
        "&.bg": {
          background: appTheme?.palette?.editor?.tv_ico_bg,
          "&:hover": {
            background: "rgba(100, 116, 139, 0.12)",
          },
        },
        "& svg": {
          width: "16px",
          height: "16px",
        },
      },
      "& .MuiList-root": {
        padding: "4px 2px",
        "& .MuiListItemButton-root": {
          color: appTheme?.palette?.editor?.tv_text_primary,
          padding: "2px 4px",
          borderRadius: "8px",
          "& .MuiListItemIcon-root": {
            minWidth: "38px",
            "&.needBg": {
              minWidth: "20px",
              width: "20px",
              height: "20px",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "4px",
              marginRight: "12px",
              background: appTheme?.palette?.editor?.tv_ico_bg,
            },
            color: `${appTheme?.palette?.editor?.tv_text} !important`,
            // "& svg": {
            //   width: "16px",
            // },
            '& svg': {
              width: '14px',
              height: '14px',
              '& path': {
                stroke: appTheme?.palette?.editor?.closeButtonSvgStroke
              }
            }
          },
          "& .MuiTypography-root": {
            fontSize: "14px",
            fontFamily: 'Inter',
          },
          "&:hover": {
            background: appTheme?.palette?.editor?.tv_hover_bg,
            color: `${appTheme?.palette?.editor?.tv_hover_text} !important`,
            "& .MuiListItemIcon-root": {
              color: `${appTheme?.palette?.editor?.tv_hover_text} !important`,
              "& svg": {
                color: `${appTheme?.palette?.editor?.tv_hover_text} !important`,
                '& path': {
                  stroke: `${appTheme?.palette?.editor?.tv_hover_text} !important`,
                }
              },
            },
          },
        },
      },
      "& .tv-cf": {
        "& .MuiInputBase-root": {
          color: appTheme?.palette?.editor?.tv_text_primary,
          // background: appTheme?.palette?.editor?.tv_input_bg,
          borderRadius: "8px",
          maxHeight: '34px !important',
          "& svg": {
            color: appTheme?.palette?.editor?.tv_text_primary,
          },
        },
      },
    },
  },
  contentWrapper: {
    padding: '13px 16px 16px 20px',
    "& .tv-opt-list": {
      minWidth: "230px",
      '& .bgic':{
        background: appTheme?.palette?.editor?.tv_ico_bg,
        padding: '6px',
        borderRadius: '4px',
      },
      '& .MuiListItemIcon-root': {
        '& svg': {
          width: '14px',
          height: '14px',
          '& path': {
            stroke: appTheme?.palette?.editor?.closeButtonSvgStroke
          }
        },
      },
    },
    "& .opt-wrpr": {
      padding: "8px 0px 0px 0px",
    },
    "& .fe-dv-ap-title": {
      display: "flex",
      fontWeight: "bold",
      justifyContent: "space-between",
      fontFamily: 'Inter',
      fontSize: "14px",
      alignItems: "center",
      borderBottom: `1px solid ${appTheme?.palette?.editor?.tv_border}`,
      marginBottom: "0px",
      padding: "0px 0px 8px 0px",
      "& span": {
        display: "flex",
        alignItems: "center",
        fontFamily: 'Inter',
      },
    },
  },
});

export default useFilterSortStyles;
