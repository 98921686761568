const ColorPickerStyles = (theme) => ({
  colorButtons: {
    padding: "0px",
    "& .buttonsWrpr": {
      display: "flex",
      justifyCotnent: "center",
    },
    "& button": {
      width: "24px",
      height: "24px",
      marginRight: "8px",
      border: "unset",
    },
  },
  colorPopper: {
    marginTop: "20px",
    borderRadius: "7px !important",
    "& .MuiPaper-root": {
      backgroundColor: `${theme?.palette?.editor?.miniToolBarBackground} !important`,
      background: `${theme?.palette?.editor?.miniToolBarBackground}`,
      border: `1px solid ${theme?.palette?.editor?.miniToolBarBorder} !important`,
      borderRadius: "7px !important",
      padding: "0px 5px",
      '@media only screen and (max-width: 600px)': {
        marginTop: "-40px"
      }
    },
  },
  colorButtonSingle: {
    "&.active": {
      "&:before": {
        content: '" "',
        position: "absolute",
        top: "-4px",
        left: "-4px",
        width: "calc(100% + 8px)",
        height: "calc(100% + 8px)",
        border: "2px solid #2563EB",
        borderRadius: "50%",
      },
    },
  },
  colorButtonsInner: {
    width: "100%",
    padding: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& .buttonsWrpr": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    ".more-btn-cbs": {
      display: "none",
    },
    "& button": {
      width: "24px",
      height: "24px",
      margin: "0px 4px",
      border: "unset",
    },
  },
  colorPickerIcon: {
    "& img": {
      width: "24px",
      height: "24px",
    },
  },
});

export default ColorPickerStyles;
