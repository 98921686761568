import React from "react";
import { Node, Transforms } from "slate";
import { useSlateStatic, ReactEditor } from "slate-react";
import { Tooltip, IconButton, Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const CarouselItem = (props) => {
  const { element, attributes, children, customProps } = props;
  const { readOnly } = customProps;
  const editor = useSlateStatic();

  const onMenuClick = (val) => () => {
    switch (val) {
      case "delete":
        const path = ReactEditor.findPath(editor, element);
        const parentSlide = Node.get(editor, [path[0]]);
        const canDelete = parentSlide?.children?.length > 1;
        if (canDelete) {
          Transforms.removeNodes(editor, { at: [...path] });
        }
        return;
      default:
        return;
    }
  };

  const Toolbar = () => {
    return !readOnly ? (
      <div
        className="element-toolbar hr"
        style={{ top: "0px", left: "0px" }}
        contentEditable={false}
      >
        <Tooltip title="Delete Slide" arrow>
          <IconButton onClick={onMenuClick("delete")}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
    ) : null;
  };

  return (
    <div {...attributes} className="carousel-item">
      <Box
        className="carousel-item-inner"
        style={{
          minHeight: "50px",
          display: "block",
          justifyContent: "center",
          alignItems: "center",
        }}
        sx={{
          "& .ico-grp-ss": {
            position: "absolute",
            top: "0px",
            right: "0px",
            display: "flex",
          },
        }}
      >
        {children}
        <Toolbar />
      </Box>
    </div>
  );
};

export default CarouselItem;
