import { Select as Core } from "@mui/material";
import SelectStyles from "./styles";
import { useEditorContext } from "../../hooks/useMouseMove";

function Select(props) {
  const { children, ...rest } = props;
  const { theme } = useEditorContext();

  const classes = SelectStyles(theme);

  return (
    <Core MenuProps={classes.MenuProps} {...rest}>
      {children}
    </Core>
  );
}

export default Select;
