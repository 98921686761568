import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  IconButton,
  TextField,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ColorButtons from "../../../Color Picker/ColorButtons";
import ColorPickerStyles from "../../../Color Picker/Styles";
import { DEFAULT_OPTION_COLORS } from "./Constants";
import Icon from "../../../../common/Icon";
import { useEditorContext } from "../../../../hooks/useMouseMove";

const EditOption = (props) => {
  const { classes, onClose, mode, onEvent } = props;
  const [edit, setEdit] = useState({ ...(mode?.edit || {}) });
  const editData = useRef({ ...edit });
  const { theme } = useEditorContext();
  const { optionIndex } = edit;
  const selectedOption = edit?.options[optionIndex] || {};
  const pickerStyles = ColorPickerStyles(theme);

  useEffect(() => {
    return () => {
      // on un-mount update the option change
      if (editData?.current) {
        delete editData?.current?.edited;
        onEvent("updateProperty", { ...editData?.current });
      }
    };
  }, []);

  const onChange = (e) => {
    const updatedOptions = edit?.options.map((m, i) => {
      if (i === edit?.optionIndex) {
        return {
          ...m,
          [e.target.name]: e?.target?.value,
        };
      }
      return m;
    });

    // for delete
    if (edit?.optionIndex > -1 && e?.target?.delete) {
      updatedOptions.splice(edit?.optionIndex, 1);
    }

    const latest = {
      ...edit,
      options: [...updatedOptions],
    };
    setEdit({
      ...latest,
    });
    editData.current = { ...latest, edited: true };

    // if delete go back
    if (e?.target?.delete) {
      onEvent("editProperty", { edit: { ...latest } });
    }
  };

  const onBack = () => {
    onEvent("editProperty", { edit: { ...edit } });
  };

  const onDeleteOption = () => {
    onChange({
      target: {
        delete: true,
      },
    });
  };

  const onColorChange = (color) => {
    onChange({
      target: {
        name: "color",
        value: color,
      },
    });
  };

  return (
    <Box sx={classes.addProperty}>
      <Box className={"fe-dv-ap-title"}>
        <span>
          <IconButton className="tv-act-ico" size="small" onClick={onBack}>
            <Icon icon={'leftArrow'} />
          </IconButton>
          Edit Option
        </span>
        <IconButton className="tv-act-ico bg br1" size="small" sx={{ '& svg': { '& path': { strokeWidth: 0 } } }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className={"fe-dv-ap-opt-content mt-1 mb"}>
        <FormControlLabel
          className="ml-0"
          label={"Option Name"}
          control={
            <TextField
              size="small"
              name="value"
              value={selectedOption?.value}
              onChange={onChange}
              fullWidth
              placeholder="Option Name"
            />
          }
          labelPlacement="top"
        />
      </Box>
      <Box className="label-desc mt-1">
        <Box className="label-tp mb">Choose Color</Box>
        <Box className="ml">
          <ColorButtons
            classes={pickerStyles}
            onSelect={onColorChange}
            activeColor={selectedOption?.color}
            defaultColors={DEFAULT_OPTION_COLORS}
          />
        </Box>
      </Box>

      <List className="fe-dv-opt-list st">
        <ListItemButton onClick={onDeleteOption}>
          <ListItemIcon>
            <Icon icon={'trashIcon'} />
          </ListItemIcon>
          <ListItemText>Delete Option</ListItemText>
        </ListItemButton>
      </List>
    </Box>
  );
};

EditOption.defaultProps = {
  onEvent: () => {},
};

export default EditOption;
