import React, { useEffect, useRef, useState } from "react";
import { Editor, Transforms } from "slate";
import { ReactEditor, useSelected, useSlateStatic } from "slate-react";
import {
  Box,
  IconButton,
  Tooltip,
  Table as TableComp,
  TableBody,
  useTheme,
  Popper,
  ClickAwayListener,
} from "@mui/material";
import { TableUtil } from "../../utils/table";
import TablePopup from "./TablePopup";
import { useEditorContext, useEditorSelection } from "../../hooks/useMouseMove";
import TableStyles from "./Styles";
import "./table.css";
import { groupByBreakpoint } from "../../helper/theme";
import useTable, {
  TableProvider,
  getDefaultTableSelection,
} from "../../hooks/useTable";
import AddRowCol from "./AddRowCol";
import TableTool from "./TableTool";
import { MoreIcon, SettingsIcon } from "../../assets/svg/TableIcons";
import { getSelectedCls } from "../../utils/helper";
import SwipeableDrawerComponent from "../../common/SwipeableDrawer";

const hideRowDragBtns = (hide, dragRowBtnCls) => {
  const rowDragBtns = document.querySelectorAll(`.${dragRowBtnCls}`);

  if (rowDragBtns?.length) {
    rowDragBtns?.forEach((btn) => (btn.style.display = hide));
  }
};

const ToolTableComponent = (props) => {
  const { handleAction, editorTheme } = props;
  const { updateTableSelection } = useTable();

  return (
    <div>
      <TableTool
        theme={editorTheme}
        handleToolAction={(type, option) => {
          handleAction(type, option);

          if (type === "duplicate") {
            updateTableSelection(getDefaultTableSelection());
          }
        }}
      />
    </div>
  );
};

const MoreTableSettings = (props) => {
  const { exandTools, handleAction, editorTheme, setExpandTools, classes } =
    props;
  const isMobile = window.matchMedia("(max-width: 899px)")?.matches || false;

  return isMobile ? (
    <SwipeableDrawerComponent
      open={Boolean(exandTools)}
      onClose={() => {
        setExpandTools(false);
      }}
      swipeableDrawer={false}
    >
      <Box sx={classes.mobileToolDrawer}>
        <ToolTableComponent
          handleAction={handleAction}
          editorTheme={editorTheme}
        />
      </Box>
    </SwipeableDrawerComponent>
  ) : (
    <Popper
      open={Boolean(exandTools)}
      anchorEl={exandTools}
      contentEditable={false}
      sx={{ zIndex: 2000 }}
      placement="bottom-start"
    >
      <ClickAwayListener onClickAway={() => setExpandTools(false)}>
        <ToolTableComponent
          handleAction={handleAction}
          editorTheme={editorTheme}
        />
      </ClickAwayListener>
    </Popper>
  );
};

const ToolBar = (props) => {
  const {
    selected,
    showTool,
    classes,
    handleExpand,
    handleAction,
    exandTools,
    openSetttings,
  } = props;

  const { getSelectedCells } = useTable();

  const viewTool = selected && !showTool && getSelectedCells()?.length <= 1;

  return viewTool ? (
    <Box
      component={"div"}
      contentEditable={false}
      className={`tableToolBar ${exandTools ? "active" : ""}`}
      sx={classes.tableToolBar}
    >
      <Tooltip
        title={"Settings"}
        arrow
        onClick={() => handleAction("settings")}
      >
        <IconButton className={getSelectedCls("toolbtn toggle", openSetttings)}>
          <SettingsIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={"Show Tools"} arrow onClick={handleExpand}>
        <IconButton className={getSelectedCls("toolbtn toggle", exandTools)}>
          <MoreIcon />
        </IconButton>
      </Tooltip>
    </Box>
  ) : null;
};

const Table = (props) => {
  const theme = useTheme();
  const { theme: editorTheme } = useEditorContext();
  const { element, attributes, children, customProps } = props;
  const classes = TableStyles(editorTheme);
  const { readOnly, isMobile } = customProps;
  const [openSetttings, setOpenSettings] = useState(false);
  const [exandTools, setExpandTools] = useState(null);
  const {
    bgColor,
    borderColor,
    xsHidden,
    fontFamily,
    fontWeight,
    textSize,
    textColor,
  } = element;
  const editor = useSlateStatic();
  const selected = useSelected();
  const table = new TableUtil(editor);
  const tableProps = table.getTableProps();
  const [showTool] = useEditorSelection(editor);
  const tableRef = useRef(null);
  const containerRef = useRef(null);
  const path = ReactEditor.findPath(editor, element);

  const dragRowBtnCls = `table-${path
    ?.toString()
    ?.replaceAll(",", "-")}-row-drag-btn`;

  const handleAction = (type) => {
    Transforms.select(editor, editor.selection);
    switch (type) {
      case "delete":
        table.removeTable();
        break;
      case "duplicate":
        table.duplicateTable();
        setExpandTools(false);
        break;
      case "settings":
        if (tableProps) {
          onSettings(true);
        }
        break;
      default:
        return;
    }
  };

  const handleExpand = (e) => {
    setExpandTools((prev) => (prev ? false : e.currentTarget));
  };

  useEffect(() => {
    if (!selected) {
      setExpandTools(false);
    }
  }, [selected]);

  const onSettings = () => {
    setOpenSettings(!openSetttings);
  };

  const onSave = (data) => {
    const updateData = { ...data };
    delete updateData.children;
    delete updateData.type;
    table.updateTableStyle(updateData, {
      ...tableProps,
    });
    onClose();
  };

  const onClose = () => {
    setOpenSettings(false);
  };

  const tableSX = groupByBreakpoint(
    {
      display: {
        xs: xsHidden ? "none" : "inline-block",
        lg: "inline-block",
      },
    },
    theme
  );

  const addRow = () => {
    const lastRow = element?.rows - 1;
    const firstCol = 0;

    const lastRowPath = [...path, lastRow, firstCol];

    const position = Editor.start(editor, lastRowPath);

    const selection = {
      anchor: position,
      focus: position,
    };

    // select the last row first col to insert row below
    Transforms.select(editor, selection);

    table.insertRow("after");

    Transforms.deselect(editor);
  };

  const addCol = () => {
    const lastCol = element?.columns - 1;
    const firstRow = 0;

    const lastColumnPath = [...path, firstRow, lastCol];
    const position = Editor.start(editor, lastColumnPath);

    const selection = {
      anchor: position,
      focus: position,
    };

    // select the last row first col to insert row below
    Transforms.select(editor, selection);

    table.insertColumn("after");

    Transforms.deselect(editor);
  };

  const handleRowDragBtns = () => {
    if (containerRef?.current?.scrollLeft > 0) {
      hideRowDragBtns("none", dragRowBtnCls);
    } else {
      hideRowDragBtns("", dragRowBtnCls);
    }
  };

  const handleScroll = () => {
    handleRowDragBtns();
  };

  const onMouseOver = () => {
    if (!isMobile) {
      containerRef?.current?.classList.remove("hideScroll");
    }
  };

  const onMouseLeave = () => {
    if (!isMobile) {
      containerRef?.current?.classList.add("hideScroll");
    }
  };

  const commonAddBtnProps = {
    tableRef,
    containerRef,
    readOnly,
    tableNode: element,
  };

  return (
    <TableProvider
      editor={editor}
      otherProps={{ dragRowBtnCls, tablePath: path, openSetttings, exandTools }}
    >
      <div
        style={{
          minWidth: "100%",
          maxWidth: "100%",
          position: "relative",
          overflowX: "auto",
          display: "flex",
          paddingTop: "10px",
          lineHeight: 1.43,
        }}
        ref={containerRef}
        onScroll={handleScroll}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        className="custom-scroll"
      >
        <TableComp
          className={readOnly ? "readOnly" : ""}
          sx={{
            ...classes.table,
            ...tableSX,
          }}
          style={{
            background: bgColor,
            border: borderColor ? `1px solid ${borderColor}` : "",
            width: "auto",
            fontFamily,
            fontWeight,
            fontSize: textSize,
            color: textColor,
          }}
          ref={tableRef}
        >
          <TableBody {...attributes}>{children}</TableBody>
        </TableComp>

        <AddRowCol {...commonAddBtnProps} addType="col" onAdd={addCol} />
      </div>

      <AddRowCol {...commonAddBtnProps} addType="row" onAdd={addRow} />

      {!readOnly && (
        <ToolBar
          selected={selected}
          showTool={showTool}
          classes={classes}
          handleExpand={handleExpand}
          handleAction={handleAction}
          exandTools={exandTools}
          openSetttings={openSetttings}
        />
      )}

      <MoreTableSettings
        exandTools={exandTools}
        handleAction={handleAction}
        editorTheme={editorTheme}
        setExpandTools={setExpandTools}
        classes={classes}
      />

      {openSetttings ? (
        <TablePopup
          element={tableProps?.styleProps || {}}
          onSave={onSave}
          onClose={onClose}
          customProps={customProps}
        />
      ) : null}
    </TableProvider>
  );
};

export default Table;
