import React, { useEffect, useRef, useState } from "react";
import { Box, Checkbox, Popper, useTheme } from "@mui/material";
import DataTypes from "./DataTypes";
import useColumnStyles from "./colStyles";
import Icon from "../../../common/Icon";

const ColumnView = (props) => {
  const theme = useTheme();
  const { needAnchor, rowIndex, row, property, onSelect, selected, readOnly } =
    props;
  const DataType = DataTypes[property?.type] || DataTypes["text"];
  const anchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popperRefresh, setPopperRefresh] = useState(new Date().getTime());
  const open = Boolean(anchorEl);
  const classes = useColumnStyles(theme);
  const parentRef = useRef(null);

  useEffect(() => {
    if (!anchorRef?.current) return;

    setAnchorEl(anchorRef?.current);
    parentRef.current = anchorRef?.current.closest(".tv-d-wrapper");

    const resizeObserver = new ResizeObserver(() => {
      if (parentRef?.current) {
        setPopperRefresh(new Date().getTime());
      }
    });

    resizeObserver.observe(parentRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [anchorRef?.current]);

  const handleSelect = (id) => (e) => {
    onSelect(id, e.target.checked);
  };

  return (
    <Box component={"td"}>
      {needAnchor ? (
        <span ref={anchorRef} style={{ position: "absolute", width: "1px" }} />
      ) : null}
      <DataType
        options={property?.options}
        property={property?.key}
        value={row[property?.key] || ""}
        rowIndex={rowIndex}
        label={property?.label}
        readOnly={readOnly}
      />
      {needAnchor && !readOnly ? (
        <Popper
          sx={classes.root}
          open={open}
          anchorEl={anchorEl}
          placement={"left"}
          className={`tv-tr-pop ${selected ? "active" : ""}`}
          disablePortal
          key={popperRefresh}
        >
          <Checkbox
            onClick={handleSelect(row?.id)}
            size="small"
            className="tv-ck-box"
            sx={{ mr: 0 }}
            checked={selected}
            icon={<Icon icon="uncheckedIcon" />}
            checkedIcon={<Icon icon="checkedIcon" />}
          />
        </Popper>
      ) : null}
    </Box>
  );
};

export default ColumnView;
