import React from "react";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { PROPERTY_TYPES } from "./Constants";
import Icon from "../../../../common/Icon";

const PropertyList = (props) => {
  const { onSelect, selected } = props;
  return (
    <List>
      {PROPERTY_TYPES?.map(({ ...rest }, i) => {
        return (
          <ListItemButton
            className={selected?.type === rest?.type ? "active" : ""}
            key={i}
            onClick={onSelect(rest)}
          >
            <ListItemIcon className="needBg">
              <Icon icon={rest?.Icon}/>
            </ListItemIcon>
            <ListItemText primary={rest?.label} />
          </ListItemButton>
        );
      })}
    </List>
  );
};

PropertyList.defaultProps = {};

export default PropertyList;
