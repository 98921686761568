import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  IconButton,
} from "@mui/material";
import useCommonStyle from "../../../commonStyle";
import { useEditorContext } from "../../../hooks/useMouseMove";
import { CheckedBoxCheckIcon } from "../../iconListV2";

const SelectBox = (props) => {
  const { value, data, onChange, elementProps } = props;
  const { key, placeholder } = data;
  const [checkedValue, setCheckedValue] = useState(false);
  const isFirstRender = useRef(true);
  const { theme } = useEditorContext();
  const classes = useCommonStyle(theme)

  useEffect(() => {
    if (
      isFirstRender.current &&
      elementProps.element === "email" &&
      value === undefined
    ) {
      setCheckedValue(true);
      isFirstRender.current = false;
    } else {
      setCheckedValue(value);
    }
  }, [elementProps.element, value]);

  const handleChange = (e) => {
    onChange({ [key]: e.target.checked });
  };

  return (
    <Grid item xs={12} style={{ marginBottom: "12px" }}>
      <FormControlLabel
        className="ccheckbox-primary"
        sx={{...classes.customCheckBox}}
        control={
          <Checkbox
            value={value}
            checked={checkedValue}
            onChange={handleChange}
            className="checkBox"
            checkedIcon={
              <IconButton className="checkedIcon">
                <CheckedBoxCheckIcon />
              </IconButton>
            }
            icon={<IconButton className="unCheckedIcon"></IconButton>}
          />
        }
        label={placeholder}
      />
      <FormHelperText>{data?.helperText}</FormHelperText>
    </Grid>
  );
};

export default SelectBox;
