import { Editor, Element, Path, Transforms } from "slate";

const insertNewLine = (editor) => {
  Transforms.insertNodes(
    editor,
    { type: "paragraph", children: [{ text: "" }] },
    { at: [editor.children.length] }
  );
};

export const insertNewLineAfterCurrentPath = (editor) => {
  const currentPath = editor?.selection?.anchor?.path;
  const [tableNode] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
  });

  if (currentPath && tableNode) {
    const currentParentPath = Path.parent(currentPath);
    const nextPath = Path.next(currentParentPath);

    Transforms.insertNodes(
      editor,
      { type: "paragraph", children: [{ text: "" }] },
      { at: nextPath }
    );
  }
};

export default insertNewLine;
