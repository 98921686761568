import { Box, IconButton } from "@mui/material";
import Icon from "../../../common/Icon";
import { useEditorContext } from "../../../hooks/useMouseMove";
import { getSelectedText } from "../../../utils/helper";

function InfinityAITool(props) {
  const { editor } = props;
  const { setOpenAI } = useEditorContext();

  return (
    <Box component="div">
      <IconButton
        onClick={() => {
          const text = getSelectedText(editor);

          if (text && text.trim()) {
            setOpenAI("fromToolBar");
          }
        }}
      >
        <Icon icon="infinityIcon" />
      </IconButton>
    </Box>
  );
}

export default InfinityAITool;
