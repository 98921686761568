import { ClickAwayListener } from "@mui/material";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { clearCellText } from "../utils/table";
import { Editor, Element, Transforms } from "slate";
import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { encodeToBase64, isHavingSelection } from "../utils/helper";
import { serializeToText } from "../utils/serializeToText";
import {
  createCopiedTableStructure,
  getRectangleBounds,
  tableNodeToDom,
} from "../Elements/Table/tableHelper";

// const selectFirstCell = (tablePath, editor, updateTableSelection) => {
//   const firstCell = [...tablePath, 0, 0];

//   const end = Editor.end(editor, firstCell);

//   Transforms.select(editor, {
//     anchor: end,
//     focus: end,
//   });

//   updateTableSelection({
//     startCellPath: firstCell,
//     endCellPath: [],
//     isDragging: false,
//   });
// };

const handleDragEnd = (dragData, editor, resetAll) => {
  const { active, over } = dragData;

  if (over) {
    const { dragType, path: startCellPath } = active?.data?.current || {};
    const { path: overCellPath } = over?.data?.current || {};

    if (startCellPath?.length) {
      if (dragType === "row") {
        const startRowPath = startCellPath.slice(0, -1);
        const overRowPath = overCellPath.slice(0, -1);

        Transforms.moveNodes(editor, {
          at: startRowPath,
          to: overRowPath,
        });
      } else {
        const [startCol] = startCellPath.slice(-1);
        const [overCol] = overCellPath.slice(-1);

        const [tableData] = Editor.nodes(editor, {
          at: startCellPath,
          match: (n) =>
            !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
        });

        const [tableNode, tablePath] = tableData;

        const rows = tableNode?.rows || 0;

        for (let row = 0; row < rows; row++) {
          const startColPath = [...tablePath, row, startCol];
          const overColPath = [...tablePath, row, overCol];

          Transforms.moveNodes(editor, {
            at: startColPath,
            to: overColPath,
          });
        }
      }

      resetAll();
    }
  }
};

const TableContext = createContext();

export function getDefaultTableSelection() {
  return {
    startCellPath: [],
    endCellPath: [],
    isDragging: false,
  };
}

export const TableProvider = ({ editor, children, otherProps = {} }) => {
  const [hoverPath, setHoverPath] = useState(null);
  const [tableSelection, setTableSelection] = useState(
    getDefaultTableSelection()
  );
  const [tableResizing, setTableResizing] = useState(null);

  // for the button events (like onclick,...etc) inside draggable component to work correctly
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 250,
        distance: 0, // throws exceeded distance error
      },
    })
  );

  const getSelectedCells = () => {
    return getRectangleBounds(tableSelection);
  };

  const updateTableSelection = (update) => {
    setTableSelection((prev) => ({
      ...prev,
      ...update,
    }));
  };

  const resetAll = () => {
    setTableSelection(getDefaultTableSelection());
    Transforms.deselect(editor);

    // hide drag icons after drag completion
    setTimeout(() => setHoverPath(null), 200);
  };

  const values = useMemo(() => {
    return {
      hoverPath,
      setHoverPath,
      editor,
      getSelectedCells,
      updateTableSelection,
      tableSelection,
      tableResizing,
      setTableResizing,
      otherProps,
      resetAll,
    };
  }, [hoverPath, editor?.selection, tableSelection, tableResizing]);

  useEffect(() => {
    let isTextSelected;
    const handleKeyDown = (event) => {
      const isCutKey = (event.ctrlKey || event.metaKey) && event.key === "x";

      if (isCutKey) {
        isTextSelected = isHavingSelection(editor);
      } else if (event.key === "Backspace") {
        const selectedCells = getSelectedCells();

        if (selectedCells?.length > 1) {
          selectedCells.forEach((currentCellPath) => {
            clearCellText(editor, currentCellPath);
          });
        }
      }
    };

    const handleCopy = (event, isCut) => {
      try {
        const isTextSelected = isHavingSelection(editor);

        const { startCellPath } = tableSelection || {};

        const customCopy = startCellPath?.length;

        if (customCopy && !isTextSelected) {
          event.preventDefault(); // Prevent default copy behavior

          const { tablePath } = otherProps;

          const [node] = Editor.node(editor, tablePath);

          const copiedTableNode = createCopiedTableStructure(
            editor,
            tableSelection,
            node,
            tablePath
          );

          const tableNode = [copiedTableNode];

          const encodedTableNode = encodeToBase64(tableNode);
          event.clipboardData.setData(
            "application/x-slate-fragment",
            encodedTableNode
          );

          const textData = serializeToText(tableNode);
          event.clipboardData.setData("text/plain", textData);

          const tableDom = tableNodeToDom(copiedTableNode);
          event.clipboardData.setData("text/html", tableDom?.outerHTML);

          if (isCut) {
            clearCellText(editor, startCellPath);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    const handleCut = (e) => {
      // unable to find the text selected or not here, that why handling it on handleKeyDown

      if (!isTextSelected) {
        e.preventDefault();
        handleCopy(e, true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("copy", handleCopy);
    window.addEventListener("cut", handleCut);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("copy", handleCopy);
      window.removeEventListener("cut", handleCut);
    };
  }, [tableSelection, editor, tableSelection]);

  // useEffect(() => {
  //   selectFirstCell(tablePath, editor, updateTableSelection);
  // }, []);

  return (
    <TableContext.Provider value={values}>
      <DndContext
        sensors={sensors}
        onDragEnd={(data) => handleDragEnd(data, editor, resetAll)}
      >
        <ClickAwayListener
          onClickAway={() => setTableSelection(getDefaultTableSelection())}
        >
          <div>{children}</div>
        </ClickAwayListener>

        {/* putting empty overlay because of sometimes drag icon is not positioning correctly after duplicate/insert row/col in table */}
        <DragOverlay></DragOverlay>
      </DndContext>
    </TableContext.Provider>
  );
};

function useTable() {
  return useContext(TableContext);
}

export default useTable;
