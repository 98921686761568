const DataTableIcon = () => {
    return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="commonSvgStyle">
        <path d="M14.6668 7.33398V6.00065C14.6668 2.66732 13.3335 1.33398 10.0002 1.33398H6.00016C2.66683 1.33398 1.3335 2.66732 1.3335 6.00065V10.0007C1.3335 13.334 2.66683 14.6673 6.00016 14.6673H6.66683" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.35352 5.66797H14.6668" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.35352 10.334H8.00016" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.67334 14.6598V1.33984" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.3398 7.99316V1.33984" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.4868 9.7794L9.72013 12.546C9.61346 12.6527 9.51344 12.8594 9.48677 13.006L9.33345 14.066C9.28012 14.446 9.54679 14.7194 9.92679 14.6594L10.9868 14.506C11.1334 14.486 11.3401 14.3794 11.4468 14.2727L14.2135 11.506C14.6868 11.0327 14.9135 10.4727 14.2135 9.77273C13.5201 9.0794 12.9668 9.2994 12.4868 9.7794Z" stroke="#64748B" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.0933 10.1719C12.3266 11.0119 12.9866 11.6652 13.8266 11.9052" stroke="#64748B" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    )
}

export default DataTableIcon