import { jsx } from "slate-hyperscript";

const inlineStyles = [
  {
    key: "bold",
    getStyle: (styles) =>
      styles.fontWeight === "bold" || parseInt(styles.fontWeight, 10) >= 700,
  },
  {
    key: "italic",
    getStyle: (styles) => styles.fontStyle === "italic",
  },
  {
    key: "underline",
    getStyle: (styles) => styles.textDecoration.includes("underline"),
  },
];

function getInlineTextStyles(element) {
  if (!element || !element.style) return {};

  const styles = element.style;

  const elementStyles = inlineStyles.reduce((total, currVal) => {
    const style = currVal.getStyle(styles);
    if (style) {
      total[currVal.key] = style;
    }
    return total;
  }, {});

  return elementStyles;
}

const handleTableCell = (el, children) => {
  const wrapChild = children?.map((c) => {
    if (typeof c === "string") {
      return {
        type: "paragraph",
        children: [
          {
            text: c,
          },
        ],
        cellBgColor: "#FFFFFF",
      };
    }

    return c;
  });

  return {
    type: "table-cell",
    overwriteChild: wrapChild,
    size: { width: 120 },
  };
};

const INLINE_TAGS = [
  "A",
  "ABBR",
  "B",
  "BDO",
  "CITE",
  "CODE",
  "DATA",
  "DEL",
  "DFN",
  "IMG",
  "INS",
  "KBD",
  "LABEL",
  "MARK",
  "Q",
  "SAMP",
  "SMALL",
  "SPAN",
  "SUB",
  "SUP",
  "TIME",
  "VAR",
];

// to avoid nested paragraph to resolve performace issue
const paragraphType = (el) => {
  const { childNodes = [] } = el || {};

  // if anyone of the child node is text node or wrapped with inline tags, it is considered to be an paragraph node
  const isHavingText = childNodes?.length
    ? Array.from(childNodes)?.some((child) => {
        const isTextNode = child?.nodeType === 3;

        const isHavingInlineTags =
          TEXT_TAGS[child?.nodeName] || INLINE_TAGS.includes(child.nodeName);

        return isTextNode || isHavingInlineTags;
      })
    : null;

  return isHavingText ? { type: "paragraph" } : {};
};

const ELEMENT_TAGS = {
  A: (el) => ({ type: "link", url: el.getAttribute("href") }),
  BLOCKQUOTE: () => ({ type: "quote" }),
  H1: () => ({ type: "headingOne" }),
  H2: () => ({ type: "headingTwo" }),
  H3: () => ({ type: "headingThree" }),
  H4: () => ({ type: "headingFour" }),
  H5: () => ({ type: "headingFive" }),
  H6: () => ({ type: "headingSix" }),
  IMG: (el) => ({ type: "image", url: el.getAttribute("src") }),
  LI: (el) => {
    const checkListItem = el.querySelector(".check-list-item");
    if (checkListItem) {
      return {
        type: "check-list-item",
        checked: checkListItem?.dataset?.checked === "true",
      };
    }
    return { type: "list-item" };
  },
  UL: () => ({ type: "unorderedList" }),
  OL: () => ({ type: "orderedList" }),
  P: paragraphType,
  DIV: paragraphType,
  PRE: () => ({ type: "code" }),
  META: paragraphType,
  STYLE: paragraphType,
  "GOOGLE-SHEETS-HTML-ORIGIN": paragraphType,
  TABLE: (el, children = []) => {
    try {
      const bodyChild = (children || [])?.filter((f) => f !== null);
      const firstRowChildren = bodyChild[0]?.children || [];

      return {
        type: "table",
        children: bodyChild,
        rows: bodyChild?.length,
        columns: firstRowChildren?.length,
      };
    } catch (err) {
      console.log(err);
    }
  },
  // THEAD: () => ({ type: "table-head" }),
  // TBODY: () => ({ type: "table-body" }),
  TH: handleTableCell,
  TR: () => ({ type: "table-row" }),
  TD: handleTableCell,
  COLGROUP: paragraphType,
  COL: paragraphType,
  HR: () => ({ type: "divider", borderColor: "#CCC" }),
};

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
  // B: () => ({ bold: true }),
};

const deserialize = (el) => {
  if (el.nodeType === 3) {
    // if there is any line-breaks
    const match = /\r|\n/.exec(el.textContent);
    const text = el.textContent.replace(/\r|\n/g, "").trim();

    return match && !text
      ? null
      : { text, ...getInlineTextStyles(el.parentNode) };
  } else if (el.nodeType !== 1) {
    return null;
  } else if (el.nodeName === "BR") {
    return "\n";
  }

  const { nodeName } = el;
  let parent = el;

  if (
    nodeName === "PRE" &&
    el.childNodes[0] &&
    el.childNodes[0].nodeName === "CODE"
  ) {
    parent = el.childNodes[0];
  }
  let children = Array.from(parent.childNodes).map(deserialize).flat();

  if (children.length === 0) {
    children = [{ text: "" }];
  }

  if (el.nodeName === "BODY") {
    return jsx("fragment", {}, children);
  }

  if (ELEMENT_TAGS[nodeName]) {
    const { overwriteChild, ...attrs } = ELEMENT_TAGS[nodeName](el, children);
    if (attrs?.type) {
      return jsx("element", attrs, overwriteChild || children);
    }
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el);

    return children.map((child) => {
      if (child?.type) {
        // if any list elements (like ul, ol... tags) is wrapped inside the TEXT_TAGS, we will return child as it is, else return it as text node
        return child;
      } else {
        return jsx("text", attrs, child);
      }
    });
  }

  return children;
};

export default deserialize;
