import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Box,
} from "@mui/material";
import { insertGrid, insertPlainGrid } from "../../utils/grid";
import ToolbarIcon from "../../common/ToolbarIcon";
import GridStyles from "./Styles";
import { GridElement } from "../../common/iconListV2";

const GridButton = (props) => {
  const classes = GridStyles();
  const { editor, icoBtnType } = props;
  const [open, setOpen] = useState(false);

  const onButtonClick = async () => {
    insertGrid(editor, insertPlainGrid(2));
    handleClose();
  };

  const handleInsertGrid = (item) => () => {
    try {
      if (item) {
        let template_content = item ? JSON.parse(item.content) : null;
        template_content = Array.isArray(template_content)
          ? template_content
          : [template_content];
        insertGrid(editor, template_content);
      } else {
        insertGrid(editor);
      }
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleGridClick = (count) => () => {
    insertGrid(editor, insertPlainGrid(count));
    handleClose();
  };

  // const { list } = templates;

  return (
    <>
      <ToolbarIcon
        title="Column"
        onClick={onButtonClick}
        icon={<GridElement />}
        icoBtnType={icoBtnType}
      />
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Grid container>
            <Grid item xs={6}>
              Grid
            </Grid>
            <Grid item xs={6} textAlign={"right"}>
              <Button onClick={handleInsertGrid(null)}>
                Insert Default Template
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ display: "flex" }}>
            {Array.from(Array(12).keys()).map((m) => {
              m = m + 1;
              return (
                <Grid
                  key={`in_grid_co_${m}`}
                  item
                  xs={2}
                  style={{ display: "flex" }}
                >
                  <Box component={"div"} style={{ display: "flex" }}>
                    <Tooltip title={`Insert ${m} Column Grid`} arrow>
                      <Button
                        sx={classes.gridButton}
                        onClick={handleGridClick(m)}
                      >
                        {m}
                      </Button>
                    </Tooltip>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default GridButton;
