const TrashIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 3.98617C4.22 3.76617 6.45333 3.65283 8.68 3.65283C10 3.65283 11.32 3.7195 12.64 3.85283L14 3.98617" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.332 3.3135L10.1854 2.44016C10.0787 1.80683 9.9987 1.3335 8.87203 1.3335H7.12536C5.9987 1.3335 5.91203 1.8335 5.81203 2.44683L5.66537 3.3135" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.43307 6.09375L3.86641 12.8071C3.93974 13.8537 3.99974 14.6671 5.85974 14.6671H10.1397C11.9997 14.6671 12.0597 13.8537 12.1331 12.8071L12.5664 6.09375" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.11328 11H6.89328" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.66797 8.3335H6.33464" stroke="#64748B" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
export default TrashIcon