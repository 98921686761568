const useOptionsStyles = (theme, appTheme) => ({
  popover: {
    "& .MuiPaper-root": {
      boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.16)",
      border: `1px solid ${appTheme?.palette?.editor?.tv_border}`,
      borderRadius: "20px !important",
      background: appTheme?.palette?.editor?.tv_pop_bg,
      fontFamily: 'Inter !important',
      color: appTheme?.palette?.editor?.tv_text_primary,
      [theme?.breakpoints?.between("xs", "md")]: {
        borderRadius: "16px 16px 0px 0px",
        maxHeight: "50%",
      },
      "& .tv-act-ico": {
        color: appTheme?.palette?.editor?.tv_text,
        "&.br1": {
          borderRadius: "6px",
        },
        "&.bg": {
          background: appTheme?.palette?.editor?.tv_ico_bg,
          "&:hover": {
            background: "rgba(100, 116, 139, 0.12)",
          },
        },
        "& svg": {
          width: "16px",
          height: "16px",
          '& path': {
            stroke: appTheme?.palette?.editor?.closeButtonSvgStroke
          }
        },
      },
      "& .MuiInputBase-root": {
        color: appTheme?.palette?.editor?.tv_text_primary,
        fontSize: "14px",
        // background: appTheme?.palette?.editor?.tv_input_bg,
        background: 'transparent',
        borderRadius: "8px",
        boxShadow: '0px 4px 18px 0px #0000000D',
      },
      '& .MuiInputBase-input': {
        padding: '0px 12px',
        height: '34px'
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        border: `1px solid ${appTheme?.palette?.editor?.tv_border}`,
        background: 'transparent',
      },
      "& .MuiList-root": {
        padding: "4px 2px",
        fontFamily: 'Inter',
        "& .MuiListItemButton-root": {
          color: appTheme?.palette?.editor?.tv_text_primary,
          padding: "2px 4px",
          borderRadius: "8px",
          "& .MuiListItemIcon-root": {
            minWidth: "26px",
            "&.needBg": {
              minWidth: "20px",
              width: "20px",
              height: "20px",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "4px",
              marginRight: "12px",
              background: appTheme?.palette?.editor?.tv_ico_bg,
            },
            color: `${appTheme?.palette?.editor?.tv_text} !important`,
            "& svg": {
              // width: "16px",
              '& path': {
                stroke: appTheme?.palette?.editor?.closeButtonSvgStroke
              }
            },
          },
          "& .MuiTypography-root": {
            fontSize: "14px",
            fontFamily: 'Inter',
          },
          "&:hover": {
            background: appTheme?.palette?.editor?.tv_hover_bg,
            color: `${appTheme?.palette?.editor?.tv_hover_text} !important`,
            "& .MuiListItemIcon-root": {
              color: `${appTheme?.palette?.editor?.tv_hover_text} !important`,
              "& svg": {
                color: `${appTheme?.palette?.editor?.tv_hover_text} !important`,
                '& path': {
                  stroke: `${appTheme?.palette?.editor?.tv_hover_text} !important`,
                }
              },
            },
          },
        },
      },
      "& .st": {
        borderTop: `1px solid ${appTheme?.palette?.editor?.tv_border}`,
        marginTop: "8px",
        paddingTop: "8px",
      },
      "& .sb": {
        borderBottom: `1px solid ${appTheme?.palette?.editor?.tv_border}`,
        marginBottom: "8px",
        // paddingBottom: "8px",
        paddingBottom: "0px",
      },
      "& .mt": {
        marginTop: "8px",
      },
      "& .mt-1": {
        marginTop: "12px",
      },
      "& .mb": {
        marginBottom: "8px",
      },
      "& .mb-1": {
        marginBottom: "12px",
      },
      "& .ml": {
        marginLeft: "8px",
      },
      "& .mb-0": {
        marginBottom: "0px",
      },
      "& .ml-0": {
        marginLeft: "0px !important",
      },
      "& .pl-0": {
        paddingLeft: "0px",
      },
      "& .label-desc": {},
      "& .MuiFormControlLabel-root": {
        alignItems: "flex-start",
        margin: "0px 0px 0px 0px",
        width: "100%",
        "& .MuiFormControlLabel-label": {
          marginBottom: "8px",
          fontSize: "14px",
        },
      },
      "& .label-tp": {
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
        fontSize: "14px",
      },
      // "& .MuiInputBase-input": {
      //   height: "32px",
      //   padding: "4px 8px",
      // },
      "& .more-btn-cbs": {
        color: appTheme?.palette?.editor?.tv_text_primary,
      },
    },
  },
  addProperty: {
    padding: "12px",
    width: "253px",
    "& .divider_sty": {
      borderBottom: `1px solid ${appTheme?.palette?.editor?.tv_border}`,
    },
    "& .fe-dv-ap-title": {
      display: "flex",
      fontWeight: "600",
      fontFamily: 'Inter',
      padding: "0px 0px 12px 0px",
      justifyContent: "space-between",
      fontSize: "14px",
      alignItems: "center",
      borderBottom: `1px solid ${appTheme?.palette?.editor?.tv_border}`,
      marginBottom: "8px",
      "& span": {
        display: "flex",
        alignItems: "center",
        fontFamily: 'Inter',
      },
    },
    "& .fe-dv-ap-desc": {
      fontSize: "12px",
      color: "rgba(148, 163, 184, 1)",
    },
    "& .fe-dv-ap-opt-list": {
      margin: "12px  0px",
    },
    "& .fe-tv-addopt": {
      marginTop: "8px",
      paddingLeft: "4px",
      "& .MuiList-root": { maxHeight: "200px", overflowY: "auto" },
      "& .MuiFormHelperText-root": {
        color: "red",
        marginLeft: "2px",
        marginTop: "4px",
      },
      '& svg': {
        '& path': {
          stroke: appTheme?.palette?.editor?.closeButtonSvgStroke
        }
      }
    },
    "& .fe-tv-opt-sub-title": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "8px",
    },
    "& .more-btn-cbs": {
      color: `${appTheme?.palette?.editor?.closeButtonSvgStroke}`,
      border: `1.5px solid ${appTheme?.palette?.editor?.closeButtonSvgStroke}`,
      paddingTop: "10px",
    },
    [theme?.breakpoints?.between("xs", "md")]: {
      width: "100%",
    },
  },
});

export default useOptionsStyles;
