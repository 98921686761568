import React from "react";
import { useDataView } from "../../Providers/DataViewProvider";
import Select from "./Components/Select";

const MultiSelectType = (props) => {
  const {
    rowIndex,
    property,
    value: pValue,
    options,
    label = "",
    readOnly,
  } = props;
  const { onChange } = useDataView();
  const value = Array.isArray(pValue) ? pValue : [];
  const coloredValues = [...(value || [])]?.map((m) => {
    return {
      ...m,
      color: options?.find((f) => f.value === m.value)?.color || "#FFF",
    };
  });
  const handleChange = (data) => {
    onChange(rowIndex, {
      [property]: data?.filter((f) => f?.value),
    });
  };

  return (
    <Select
      value={coloredValues}
      onChange={handleChange}
      options={options}
      multiple={true}
      limitTags={2}
      placeholder={label}
      disabled={readOnly}
    />
  );
};

export default MultiSelectType;
