import { useCallback, useEffect, useRef, useState } from "react";
import { useEditorContext } from "../../hooks/useMouseMove";
import Styles from "./Styles";
import { Fade, Paper, Popper } from "@mui/material";
import AIInput from "./AIInput";
import { ReactEditor, useSlate } from "slate-react";
import { Node, Range, Transforms } from "slate";
import { MODES } from "./helper";
import { getSelectedText, getSlateDom } from "../../utils/helper";
import { VoiceToText } from "./VoiceToText";
import deserialize from "../../helper/deserialize";
import useEditorScroll from "../../hooks/useEditorScroll";

const getInputWidth = (selectedElement) => {
  const sectionElementWidth =
    selectedElement?.anchorEl || document.querySelector(".ed-section-inner");

  const MIN_WIDTH = 400;

  return sectionElementWidth?.offsetWidth || MIN_WIDTH;
};

const scrollToAIInput = (editor) => {
  setTimeout(() => {
    try {
      const slateWrapper = document.getElementById(
        "slate-wrapper-scroll-container"
      );

      let selectionRect;

      if (getSelectedText(editor)) {
        selectionRect = ReactEditor.toDOMRange(
          editor,
          editor.selection
        )?.getBoundingClientRect();
      } else {
        selectionRect = ReactEditor.toDOMRange(
          editor,
          getNextLine(editor).at
        ).getBoundingClientRect();
      }

      const wrapperTop = slateWrapper.getBoundingClientRect().top;

      const cursorViewPortPosition = selectionRect.bottom - wrapperTop; // here the slatewrapper is the viewport, not the whole screen. We are finding the position of the cursor/selection relative to the slate wrapper, that why we are subracting the slate wrapper top with selection bottom

      if (cursorViewPortPosition > 80) {
        // scroll to top of the slateWrapper
        slateWrapper.scrollBy(0, cursorViewPortPosition - 80);
      }
    } catch (err) {
      console.log(err);
    }
  }, 200);
};

const insertText = (editor, text, options) => {
  if (text?.length) {
    const parsed = new DOMParser().parseFromString(text, "text/html");
    const fragment = deserialize(parsed.body);

    Transforms.insertFragment(editor, fragment, options);
  }
};

const insertAtNextLine = (editor, text) => {
  const nextLine = getNextLine(editor);

  insertText(editor, text, { at: nextLine.at });

  Transforms.splitNodes(editor, { at: nextLine.at });
};

const getNextLine = (editor) => {
  try {
    const { selection } = editor;
    const { focus } = selection || {};
    if (focus?.path?.length > 0) {
      const { text = "" } = Node.get(editor, focus.path);

      let nextLineIndex = 0;
      let indexOfNextLine = 0;

      if (text?.length) {
        // split the text based on caret position
        const textBeforeCaret = text.substring(0, focus.offset);
        const textAfterCaret = text.substring(focus.offset);

        // getting the index of the next line after the caret position
        indexOfNextLine = textAfterCaret?.indexOf("\n");

        if (indexOfNextLine >= 0) {
          // index of next line
          nextLineIndex = textBeforeCaret?.length + indexOfNextLine;
        } else {
          nextLineIndex = text?.length;
        }
      }

      const data = {
        ...focus,
        offset: nextLineIndex,
      };

      const at = {
        anchor: data,
        focus: data,
      };

      return { at, indexOfNextLine };
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const getNextLineDom = (editor) => {
  let caret;

  const sel = getNextLine(editor);
  if (sel) {
    const domElement = ReactEditor.toDOMRange(editor, sel.at);

    const { textContent, parentElement } =
      domElement?.commonAncestorContainer || {};

    caret = textContent ? domElement : parentElement; // in mobile, if textContent in not available, it is pointing some <br> tag (getBoundingClientRect not working correctly for <br>), to avoid that, we are pointing the parent element as caret
  }

  return caret;
};

const updateAnchorEl = (setAnchorEl, editor, openAI, selectedElement) => {
  try {
    const { selection } = editor || {};

    const isHavingSelection =
      selection &&
      !Range.isCollapsed(selection) &&
      getSelectedText(editor).trim();

    const caret = isHavingSelection
      ? getSlateDom(editor, editor.selection)
      : getNextLineDom(editor);

    const caretPos = caret?.getBoundingClientRect() || {};

    const editorContainer = document
      .querySelector("#slate-wrapper-scroll-container")
      ?.getBoundingClientRect();

    const sectionEle =
      selectedElement?.anchorEl || document.querySelector(".ed-section-inner");
    const selectedSectionRect = sectionEle?.getBoundingClientRect() || {};

    const isAIInputReachTop = caretPos.height + caretPos.y <= editorContainer.y;

    const yValue = isAIInputReachTop ? "-500" : caretPos.y; // -500 is to hide the AI input if the toolbar reached the top

    const rect = {
      y: yValue,
      height: caretPos.height,
      top: yValue,
      right: caretPos.right,
      bottom: caretPos.bottom,

      x: selectedSectionRect.x,
      left: selectedSectionRect.left,
      width: selectedSectionRect.width,
    };

    setAnchorEl({
      getBoundingClientRect: () => rect,
    });
  } catch (err) {
    console.log(err);
  }
};

function PopoverAIInput({ otherProps, editorWrapper = { current: null } }) {
  const { services } = otherProps;
  const { openAI, setOpenAI, selectedElement } = useEditorContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [generatedText, setGeneratedText] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState();

  const selectedEleRef = useRef({});

  const classes = Styles();
  const editor = useSlate();

  const updateAnchor = useCallback(() => {
    updateAnchorEl(setAnchorEl, editor, openAI, selectedEleRef.current);
  }, [editor?.selection, openAI, selectedEleRef.current]);

  useEditorScroll(editorWrapper, updateAnchor);

  const onClickOutside = () => {
    setAnchorEl(null);
    setOpenAI("");
    setGeneratedText("");
    setLoading(false);
    setSelectedOption(null);
    setInputValue("");
    ReactEditor.focus(editor);
    Transforms.deselect(editor);
  };

  useEffect(updateAnchor, [openAI, editor.selection]);

  useEffect(() => {
    if (openAI) {
      scrollToAIInput(editor);
    }
  }, [openAI]);

  useEffect(() => {
    selectedEleRef.current = selectedElement;
  }, [selectedElement]);

  const framePayload = (type, option) => {
    let payload = {
      mode: option.mode || 0,
      query: option?.inputValue || inputValue,
    };

    if (option.mode === MODES.translate || option.mode === MODES.rephraseTone) {
      payload.textOptionInput = type;
    }

    const selectedText = getSelectedText(editor);

    const textData = generatedText || selectedText;

    if (option.mode) {
      payload.textData = textData;
    } else if (selectedText && Number(payload.mode) === 0) {
      payload.query = `${selectedText} \n ${payload.query}`;
    }

    const tryAgain = type === "try_again";

    if (tryAgain) {
      // resetting previous payload
      const prevPayload = selectedOption?.payload || {};
      payload = prevPayload;
    }

    return payload;
  };

  const onSend = async (type, option) => {
    try {
      if (type === "close") {
        onClickOutside();
        return;
      }

      if (type === "done") {
        // Get the current selection point
        const { anchor } = editor.selection;

        const { path } = anchor;
        const { text: selectText } = Node.get(editor, path);

        if (selectText?.length) {
          insertAtNextLine(editor, generatedText);
        } else {
          insertText(editor, generatedText);
        }

        onClickOutside();
        return;
      }

      if (type === "replace_selection") {
        // replace generated text
        insertText(editor, generatedText);
        onClickOutside();
        return;
      }

      setLoading(true);

      const payload = framePayload(type, option);

      setSelectedOption({ ...option, payload });

      const result = await services("infinityAI", payload);

      setLoading(false);
      setInputValue("");

      let { data: text } = result || {};

      if (!text) {
        onClickOutside();
        return;
      }

      // if (!option.replace) {
      if (type === "continue_writing") {
        setGeneratedText(generatedText + text);
      } else {
        setGeneratedText(text);
      }

      //   return;
      // }

      // ** we are not using this insertText right now, AI returned response will not insert into the editor immediately, so option.replace will be false always
      // insertText(editor, text);

      // scrollToAIInput();
    } catch (err) {
      console.error("Error on sending/inserting text", err);
    }
  };

  const onInputChange = (e) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (openAI && getSelectedText(editor).trim()) {
      const customSelection = document.querySelectorAll(".slate-highlight");
      const selectionBg = "rgba(35, 131, 226, 0.35)";

      if (customSelection?.length) {
        customSelection?.forEach((el) => (el.style.background = selectionBg));

        // if ai is opened, remove the window selection class and open then slate selection, To resolve: focussing on the ai input removes window selection automatically
        const selection = window.getSelection();
        if (selection) {
          selection.removeAllRanges(); // Clears the selection
        }
      }
    }
  }, [editor.selection, openAI]);

  return (
    <div>
      <Popper
        open={Boolean(openAI) && anchorEl}
        anchorEl={anchorEl}
        transition
        placement="bottom-start"
        sx={{
          ...classes.aiPopper,
          width: getInputWidth(selectedElement),
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={getSelectedText(editor) ? { marginTop: "6px" } : {}}>
              <VoiceToText otherProps={otherProps} onSend={onSend}>
                <AIInput
                  loading={loading}
                  onSend={onSend}
                  generatedText={generatedText}
                  anchorEl={anchorEl}
                  openAI={openAI}
                  inputValue={inputValue}
                  onInputChange={onInputChange}
                  onClickOutside={onClickOutside}
                  isMobile={otherProps?.isMobile}
                />
              </VoiceToText>
            </Paper>
          </Fade>
        )}
      </Popper>

      {/* virutal height for scrolling when ai input is opened */}
      {openAI ? (
        <div
          style={{
            height: "100vh",
            background: "transparent",
          }}
        ></div>
      ) : null}
    </div>
  );
}

export default PopoverAIInput;
