import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Avatar, Box, Chip, useTheme } from "@mui/material";
import { useEditorContext } from "../../../../../hooks/useMouseMove";
import useCompStyles from "./styles";
import { CloseIcon } from "../../../../../common/iconslist";

const AvatarIcon = (props) => {
  const { option, avatar } = props;
  const nameIndex = option.label || option.value;
  return avatar && nameIndex ? (
    <Avatar alt={option.label || option.value} src={option.avatar || null}>
      {nameIndex[0] || ""}
    </Avatar>
  ) : null;
};

const filter = (opt, params, selectedOpt) => {
  const selectedVals = selectedOpt?.map((m) => m?.value);
  const fv = opt?.filter((f) => !selectedVals.includes(f.value));
  if (params?.inputValue) {
    return fv.filter(
      (f) =>
        f?.value?.toLowerCase().indexOf(params?.inputValue?.toLowerCase()) >= 0
    );
  }
  return fv;
};

export default function Select(props) {
  const theme = useTheme();
  const { theme: appTheme } = useEditorContext();
  const classes = useCompStyles(theme, appTheme);
  const {
    value: pValue,
    onChange,
    options: selectOptions,
    multiple = false,
    limitTags = 2,
    placeholder = "",
    disabled = false,
    optionAvatar = false,
  } = props;
  const value = Array.isArray(pValue) ? pValue : [];

  const options = selectOptions?.length
    ? selectOptions.filter((s) => s.value)
    : [];

  return (
    <Autocomplete
      disabled={disabled}
      className="tv-ac-field"
      multiple={true}
      limitTags={limitTags}
      placeholder={placeholder}
      value={value || ""}
      onChange={(event, newValue) => {
        const fv = [];
        newValue?.forEach((m) => {
          if (multiple) {
            fv.push({
              value: m.inputValue || m.value,
            });
          } else {
            fv[0] = {
              value: m.inputValue || m.value,
            };
          }
        });
        onChange(fv);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params, value);

        // const { inputValue } = params;
        // Suggest the creation of a new value
        // const isExisting = options.some(
        //   (option) => inputValue?.toLowerCase() === option.value?.toLowerCase()
        // );
        // no need of new val now
        // if (inputValue !== "" && !isExisting) {
        //   filtered.push({
        //     inputValue,
        //     value: `Add "${inputValue}"`,
        //   });
        // }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options || []}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.value || "";
      }}
      renderTags={(value, getTagProps) => {
        return (
          <Box
            className="tv-ms-tag-wrpr"
            sx={{
              "& svg": { marginRight: "5px", "& path": { stroke: "#000" } },
            }}
          >
            {value?.map((option, index) => {
              const { key, ...tagProps } = getTagProps({ index }) || {};
              return option?.value ? (
                <Chip
                  variant="outlined"
                  label={option?.label || option?.value}
                  key={key}
                  {...tagProps}
                  sx={{
                    background:
                      option?.color || appTheme?.palette?.editor?.tv_border1,
                    border: "none",
                    "& .MuiChip-label": {
                      paddingLeft: "12px !important",
                      paddingRight: disabled ? "12px !important" : "",
                    },
                  }}
                  avatar={<AvatarIcon option={option} avatar={optionAvatar} />}
                  deleteIcon={
                    <CloseIcon
                      style={{
                        cursor: "pointer",
                        display: disabled ? "none" : "",
                      }}
                    />
                  }
                />
              ) : null;
            })}
          </Box>
        );
      }}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            <Chip
              label={option.label || option.value || ""}
              sx={{
                background:
                  option.color || appTheme?.palette?.editor?.tv_border1,
                "& .MuiChip-label": { paddingLeft: "12px !important" },
              }}
              avatar={<AvatarIcon option={option} avatar={optionAvatar} />}
              deleteIcon={<CloseIcon />}
            />
          </li>
        );
      }}
      freeSolo
      size="small"
      fullWidth
      renderInput={(params) => {
        return <TextField size="small" {...params} placeholder={placeholder} />;
      }}
      slotProps={{
        paper: {
          sx: classes.autocomplete,
        },
      }}
    />
  );
}
