import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useDataView } from "../../../Providers/DataViewProvider";
import ChooseSort from "./ChooseSort";
import ChooseField from "./ChooseField";
import ColumnsList from "../../Options/ColumnsList";
import Icon from "../../../../../common/Icon";

const SortOptions = (props) => {
  const { onClose } = props;
  const { properties, sort, onUpdateSort } = useDataView();

  const handleSortChange = (currentSortData, data = {}) => {
    onUpdateSort({
      ...currentSortData,
      ...data,
    });
  };

  const handleDelete = () => {
    onClose();
    onUpdateSort({}, false, true);
  };

  const onSelect = (s) => () => {
    onUpdateSort(
      {
        ...s,
        newKey: s.key,
        operator: "asc",
      },
      false,
      false
    );
    onClose();
  };

  return sort?.length > 0 ? (
    <List className="tv-opt-list" sx={{ padding: '0px !important' }}>
      {sort?.map((m, i) => {
        return (
          <ListItem key={i} sx={{ justifyContent: "space-between", paddingLeft: 0, paddingRight: 0 }}>
            <Box sx={{ display: "flex", padding: 0 }}>
              <ChooseField
                sort={m}
                properties={properties}
                onChange={handleSortChange}
              />
              <ChooseSort sort={m} onChange={handleSortChange} />
            </Box>
          </ListItem>
        );
      })}
      <ListItemButton onClick={handleDelete}>
        <ListItemIcon sx={{ minWidth: '25px !important', '& svg': { background: 'transparent !important', padding: '0 !important'} }}>
          <Icon icon={'trashIcon'} />
        </ListItemIcon>
        <ListItemText>Delete Sort</ListItemText>
      </ListItemButton>
    </List>
  ) : (
    <ColumnsList properties={properties} onSelect={onSelect} />
  );
};

export default SortOptions;
