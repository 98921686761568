import React from "react";
import { Popover, SwipeableDrawer } from "@mui/material";
import useOptionsStyles from "./styles";
import AddProperty from "./AddProperty";
import EditProperty from "./EditProperty";
import ChangeProperty from "./ChangeProperty";
import AllProperties from "./AllProperties";
import EditOption from "./EditOption";
import { useTheme } from "@emotion/react";
import { useEditorContext } from "../../../../hooks/useMouseMove";

const POSITIONS = {
  addProperty: {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right",
    },
  },
  allProperties: {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right",
    },
  },
};

const PropertySettings = (props) => {
  const { theme: appTheme } = useEditorContext();
  const theme = useTheme();
  const { open, anchorEl, mode, onClose, onEvent } = props;
  const classes = useOptionsStyles(theme, appTheme);
  const isMobile = window.matchMedia("(max-width: 899px)")?.matches || false;
  const PoperComponent = isMobile ? SwipeableDrawer : Popover;

  const renderMode = () => {
    switch (mode?.type) {
      case "addProperty":
        return (
          <AddProperty
            classes={classes}
            onClose={onClose}
            onEvent={onEvent}
            mode={mode}
          />
        );
      case "editProperty":
        return (
          <EditProperty
            classes={classes}
            onClose={onClose}
            onEvent={onEvent}
            mode={mode}
          />
        );
      case "changeProperty":
        return (
          <ChangeProperty
            classes={classes}
            onClose={onClose}
            onEvent={onEvent}
            mode={mode}
          />
        );
      case "allProperties":
        return (
          <AllProperties
            classes={classes}
            onClose={onClose}
            onEvent={onEvent}
            mode={mode}
          />
        );
      case "editOption":
        return (
          <EditOption
            classes={classes}
            onClose={onClose}
            onEvent={onEvent}
            mode={mode}
          />
        );
      default:
        return null;
    }
  };

  return (
    <PoperComponent
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={onClose}
      anchor="bottom"
      sx={classes.popover}
      // override position
      {...(POSITIONS[mode?.type] || {})}
    >
      {renderMode()}
    </PoperComponent>
  );
};

export default PropertySettings;
