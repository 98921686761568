const UploadStyles = (theme) => ({
  uploadContainer: {
    border: `1px solid ${theme?.palette?.editor?.uploadFileBorder}`,
    padding: "10px",
    borderRadius: "11px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.16)",
    background: theme?.palette?.editor?.uploadFileBg,
    height: '100%',
    minHeight: '150px'
  },
  uploadField: {
    width: "100%",
    backgroundSize: "100% auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "auto",
    position: "relative",
    backgroundColor: theme?.palette?.editor?.uploadFileInnerBg,
    borderRadius: "9px",
    border: "1px dashed #2563EB",
    minHeight: '150px'
  },
  uploadIcon: {
      display: "grid !important", 
    "& svg": {
      display: 'flex',
      width: '100%',
      "& path": {
        stroke: "#2563EB",
      },
    },
    '& span':{
      display: 'flex',
      width: '100%',
      marginTop: '5px'
    }
  },
});

export default UploadStyles;
