import { Editor, Transforms, Path, Element } from "slate";
import { ReactEditor } from "slate-react";
import html2canvas from "html2canvas";
import { rectIntersection, closestCenter } from "@dnd-kit/core";
import { getQueryStrings } from "../utils/SlateUtilityFunctions";
import { Node } from "slate";
const HIDE_PLACHOLDERS = ["grid", "grid-item", "table"];
const TEXT_NODES = ["paragraph", "headingOne", "headingTwo", "headingThree"];
const PREVIEW_IMAGE_HIDE_CLASS = [
  "grid-container-toolbar",
  "grid-item-toolbar",
  "element-toolbar",
  "mini-tool-wrpr-ei",
  "element-selector",
  "element-selector-ctrl",
];

const RND_ITEMS = ["freegridItem", "freegridBox"];

export const getThumbnailImage = async (dom, options = {}) => {
  try {
    const canvas = await html2canvas(dom, {
      windowWidth: 1440,
      windowHeight: 768,
      ...options,
      backgroundColor: null,
      allowTaint: true,
      useCORS: false,
      scale: 0.5,
      imageTimeout: 0,
      onclone: (document) => {
        // hide class
        const sw = document.getElementById("slate-wrapper-scroll-container");
        sw.style.minHeight = "2000px";

        const svgFrames = document.querySelectorAll(".image-frame svg");
        for (let i = 0; i < svgFrames.length; i++) {
          svgFrames[i].style.width = "100%";
        }

        const rectFills = document.getElementsByTagName("rect");
        for (let i = 0; i < rectFills.length; i++) {
          const hasURL =
            rectFills[i]?.getAttribute("fill")?.indexOf("url") > -1;
          if (hasURL) {
            rectFills[i].style.fill = "#CCC";
          }
        }

        for (let hidedeClass of PREVIEW_IMAGE_HIDE_CLASS) {
          for (let element of document.getElementsByClassName(hidedeClass)) {
            element.style.display = "none";
          }
        }
        return document;
      },
    });
    return canvas.toDataURL("image/jpeg", 0.5);
  } catch (err) {
    console.log(err);
    return null;
  }
};

export function customCollisionDetectionAlgorithm(args) {
  // First, let's see if there are any collisions with the pointer
  const pointerCollisions = closestCenter(args);

  // Collision detection algorithms return an array of collisions
  if (pointerCollisions.length >= 0 && rectIntersection(args)) {
    return rectIntersection(args);
  }

  // If there are no collisions with the pointer, return rectangle intersections
  return [];
}

function componentToHex(c) {
  var hex = Number(c).toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

function rgbToHex(rgb) {
  return "#" + rgb.match(/\d+/g).map(componentToHex).join("");
}

export function invertColor(hex) {
  try {
    if (hex?.indexOf("#") === -1) {
      hex = rgbToHex(hex);
    }

    if (hex?.indexOf("#") === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex?.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    if (hex?.length !== 6) {
      // throw new Error("Invalid HEX color.");
    }
    // invert color components
    var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
      g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
      b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b) + "80";
  } catch (err) {
    console.log(err);
    return "#FFF";
  }
}

function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
}

export function getEmbedURL(element, needType = false) {
  let refUrl = element.href ? element.href : element.url;

  refUrl = refUrl ? (refUrl.includes("http") ? refUrl : `//${refUrl}`) : "Link";

  let embedUrl = refUrl;
  let isEmbed = false;

  if (embedUrl.includes("youtube")) {
    isEmbed = true;
    embedUrl = getQueryStrings(embedUrl);
  }
  if (embedUrl.includes("youtu.be")) {
    isEmbed = true;
    embedUrl = getQueryStrings(embedUrl);
  }
  if (embedUrl.includes("loom")) {
    isEmbed = true;
    embedUrl = embedUrl.replace(/\/share\//, "/embed/");
  }

  if (embedUrl.includes("vimeo")) {
    isEmbed = true;
    embedUrl = embedUrl.replace(/\/vimeo.com\//, "/player.vimeo.com/video/");
  }
  if (embedUrl.includes("dailymotion") && embedUrl.includes("video")) {
    isEmbed = true;
    embedUrl = embedUrl.replace(
      /www.dailymotion.com\//,
      "www.dailymotion.com/embed/"
    );
  }

  if (embedUrl.includes("dai.ly")) {
    isEmbed = true;
    embedUrl = embedUrl.replace(/dai.ly\//, "www.dailymotion.com/embed/video/");
  }

  return needType ? { embedUrl, isEmbed } : embedUrl;
}

export const isEmptyTextNode = (element) => {
  try {
    if (element) {
      const showPlaceholder =
        element?.children?.filter((f) => HIDE_PLACHOLDERS.indexOf(f.type) > -1)
          .length === 0;
      return Node.string(element)?.length === 0 && showPlaceholder;
    }
    return false;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const clearSelection = (editor) => {
  try {
    ReactEditor.focus(editor);
    Transforms.collapse(editor, { edge: "start" });
    Transforms.deselect(editor);
  } catch (err) {
    console.log(err);
  }
};

export const isSelectionInNodeType = (editor, type) => {
  const { selection } = editor;

  if (selection) {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.type === type,
      mode: "lowest", // This ensures that we check the lowest node that matches the type
    });
    return { selected: !!match, match };
  }

  return { selected: false };
};

export const getCaretPosition = (editor) => {
  const { selection } = editor;

  if (selection) {
    const { anchor } = selection;
    const { path, offset } = anchor;

    return path && offset ? { path, offset } : null;
  }
  return null;
};

export const onPasteRnDNode = (editor, { path, children, slateNodes }) => {
  try {
    const cur_root_path = Path.parent(path);
    const parsed_node = slateNodes || JSON.parse(window.copiedNode);
    let new_path = [...path, children.length];
    if (RND_ITEMS.indexOf(parsed_node?.type) > -1) {
      Transforms.insertNodes(
        editor,
        [
          {
            ...parsed_node,
            // for differentiate the items
            left: parsed_node?.left + 20,
            marginTop: parsed_node?.marginTop + 20,
            // to maintain unique drop location in different section also
            gridArea: "1 / 1 / 2 / 2",
            xs_updatedOn: null,
          },
        ],
        {
          at: new_path,
        }
      );
    } else {
      // for pasting whole section
      new_path = Path.next(cur_root_path);
      Transforms.insertNodes(editor, [{ ...parsed_node }], { at: new_path });
    }
    return new_path;
  } catch (err) {
    console.log(err);
  }
};

export const setSelection = (editor, { path }) => {
  try {
    ReactEditor.focus(editor);
    const point = { path, offset: 0 };
    Transforms.select(editor, {
      anchor: point,
      focus: point,
    });
    ReactEditor.focus(editor);
  } catch (err) {
    console.log(err);
  }
};

export const focusSelection = (editor, { path }) => {
  try {
    const itemNode = Node.get(editor, path);
    if (itemNode?.childType === "text") {
      if (TEXT_NODES.includes(itemNode?.children[0]?.type)) {
        const textNodePath = [...path, 0, 0];
        const range = Editor.range(editor, textNodePath);
        range.anchor.offset = 0;
        range.focus.offset = 0;
        Transforms.select(editor, range);
        ReactEditor.focus(editor);
      }
    }
  } catch (err) {
    console.log(err);
  }
};

export const bringItemToFB = (editor, { path, moveFront }) => {
  try {
    const itemNode = Node.get(editor, path);
    const { zIndex } = itemNode;
    let arrangeIndex = zIndex === undefined ? path[path.length - 1] : zIndex;
    arrangeIndex = moveFront ? arrangeIndex + 1 : arrangeIndex - 1;
    Transforms.setNodes(
      editor,
      { zIndex: Math.min(arrangeIndex, editor.children.length) },
      { at: path }
    );
  } catch (err) {
    console.log(err);
  }
};

export const debounce = function (func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
      args = arguments,
      later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      },
      callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const getTextColor = (color = "") => {
  return color?.indexOf("gradient") >= 0
    ? {
        background: color?.concat("text"),
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent !important",
        color: "transparent !important",
        caretColor: "black",
        "& span": {
          color: "transparent !important",
          "& ::selection": { color: "#000 !important" }
        },
      "& ::selection": { color: "#000 !important" }
      }
    : { color };
};

export const getBorderColor = (borderColor = "") => {
  const borderStyle = borderColor
    ? {
        border: "1px solid",
      }
    : {};

  if (borderColor?.indexOf("gradient") >= 0) {
    borderStyle.borderImage = `${borderColor} 1`;
  } else {
    borderStyle.borderColor = borderColor;
  }

  return borderStyle;
};

export const isCarouselSelected = (editor) => {
  try {
    if (!editor.selection) {
      return false; 
    }

    const [nodeEntry] = Editor.nodes(editor, {
      match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'carousel',
    });

    if (!nodeEntry) {
      return false;
    }

    const [node] = nodeEntry;
    const carouselDom = ReactEditor.toDOMNode(editor, node);
    const isEdit = carouselDom.classList.contains('carousel_slider_edit');
    return !isEdit;
  } catch (err) {
    console.log(err)
    return false;
  }
};
export const clearBrainText = (editor) => {
  try {
    const { selection } = editor;
    if (selection) {
      const [currentNode, currentPath] = Editor.node(editor, selection);
      const cleanedText = Node.string(currentNode)
        .replace(/\/(?:b(?:rain|rai|ra|r)?)?/g, "")
        .trim();
      Transforms.insertText(editor, cleanedText, { at: currentPath });
    }
  } catch (err) {
    console.log(err);
  }
};

export const clearSelectionOnly = (editor) => {
  try {
    Transforms.deselect(editor);
  } catch (err) {
    console.log(err);
  }
};
